import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { filterOrders } from "../../helpers/orderFunctions";
import { currentTabOperations } from "../../state/features/currentTab";
import NoOrders from "../noOrders";
import Order from "../order";
import OrdersFilterSection from "../ordersFilterSection";
import { AnimateOrderTransitions } from "../animateOrderTransitions";
import { useEntryTransitionConfig } from "../../hooks/useEntryTransitionConfig";

interface IPickupCollectedProps {
  collectedOrders: any;
  selectedBranch: any;
  company: any;
  setCurrentTab: any;
  refreshRequired: any;
}

function PickupCollected(props: IPickupCollectedProps) {
  const [collectedOrders, setCollectedOrders] = useState([]);
  const [filteredCollectedOrders, setFilteredCollectedOrders] = useState(
    [] as any
  );
  const { enableEntryTransition } = useEntryTransitionConfig();

  useEffect(() => {
    props.setCurrentTab("pickup.collected");
  }, []);

  useEffect(() => {
    setCollectedOrders(props.collectedOrders);
  }, [JSON.stringify(props.collectedOrders)]);

  useEffect(() => {
    setFilteredCollectedOrders(collectedOrders);
  }, [JSON.stringify(collectedOrders)]);

  const onFilterChanges = (filterOption: any) => {
    setFilteredCollectedOrders(filterOrders(filterOption, collectedOrders));
  };

  return (
    <div className="new-orders">
      <OrdersFilterSection
        orders={collectedOrders}
        setOrders={setCollectedOrders}
        orderType="pickup"
        orderStatus="collected"
        onFilterChanges={(filterOption: any) => onFilterChanges(filterOption)}
      />
      {filteredCollectedOrders.length > 0 ? (
        <table className="orders-table" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th></th>
              <th style={{ width: "180px" }}><FormattedMessage id="order.no"/>.</th>
              <th><FormattedMessage id="order.cust_or_tel"/></th>
              <th><FormattedMessage id="order.agent"/></th>
              <th><FormattedMessage id="order.amount"/></th>
              <th className="text-center"><FormattedMessage id="order.status"/></th>
            </tr>
          </thead>
          <tbody>
            <AnimateOrderTransitions
              enableEntryTransition={enableEntryTransition}
              orders={filteredCollectedOrders}
              component={({ item }) => (
                <Order
                  orderType="pickup"
                  orderStatus="collected"
                  key={item.order_id}
                  handleOrderState={() => {}}
                  item={item}
                />
              )}
            />
          </tbody>
        </table>
      ) : (
        <NoOrders />
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let collectedOrders = state.order.pickup.collected_pickup_orders.orders || [];
  let selectedBranch = state.company?.selected_branch;
  let company = state.company.company_details;
  let refreshRequired = state.order.home_delivery.ready_orders.refresh_required;

  return { collectedOrders, selectedBranch, company, refreshRequired };
};

const mapDispatchToProps = {
  setCurrentTab: currentTabOperations.setCurrentTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(PickupCollected);
