import * as types from "./types";

const orderCreated = (data: any) => ({
  type: types.PUSHER_RECEIVED_ORDER_CREATED,
  payload: data,
});

const orderConfirmed = (data: any) => ({
  type: types.PUSHER_RECEIVED_ORDER_CONFIRMED,
  payload: data,
});

const orderRejected = (data: any) => ({
  type: types.PUSHER_RECEIVED_ORDER_REJECTED,
  payload: data,
});

const orderUpdated = (data: any) => ({
  type: types.PUSHER_RECEIVED_ORDER_UPDATED,
  payload: data,
});

const orderMarkedReady = (data: any) => ({
  type: types.PUSHER_RECEIVED_ORDER_MARKED_AS_READY,
  payload: data,
});

const orderPickUpMarkedReady = (data: any) => ({
  type: types.PUSHER_RECEIVED_PICKUP_ORDER_MARKED_AS_READY,
  payload: data,
});

const orderRiderAssigned = (data: any) => ({
  type: types.PUSHER_RECEIVED_RIDER_ASSIGNED,
  payload: data,
});

const orderMarkedDelivered = (data: any) => ({
  type: types.PUSHER_RECEIVED_ORDER_MARKED_AS_DELIVERED,
  payload: data,
});

const orderMarkedCollected = (data: any) => ({
  type: types.PUSHER_RECEIVED_ORDER_MARKED_AS_COLLECTED,
  payload: data,
});

const orderVoided = (data: any) => ({
  type: types.PUSHER_RECEIVED_ORDER_VOIDED,
  payload: data,
});

const orderMarkedReprinted = (data: any) => ({
  type: types.PUSHER_RECEIVED_ORDER_REPRINTED,
  payload: data,
});

const customerArrived = (data: any) => ({
  type: types.PUSHER_RECEIVED_CUSTOMER_ARRIVED,
  payload: data,
});

const orderMarkedPickedUp = (data: any) => ({
  type: types.PUSHER_RECEIVED_ORDER_MARKED_AS_PICKED_UP,
  payload: data,
});

const orderRiderUnassigned = (data: any) => ({
  type: types.PUSHER_RECEIVED_RIDER_UNASSIGNED,
  payload: data,
});

const orderTipCollected = (data: any) => ({
  type: types.PUSHER_RECEIVED_TIP_COLLECTED,
  payload: data,
});

const orderCompanyRiderAssigned = (data: any) => ({
  type: types.PUSHER_RECEIVED_COMPANY_RIDER_ASSIGNED,
  payload: data,
});

const orderPartnerRiderAssigned = (data: any) => ({
  type: types.PUSHER_RECEIVED_PARTNER_RIDER_ASSIGNED,
  payload: data,
});

export default {
  orderCreated,
  orderConfirmed,
  orderUpdated,
  orderRejected,
  orderVoided,
  orderMarkedReady,
  orderPickUpMarkedReady,
  orderRiderAssigned,
  orderMarkedDelivered,
  orderMarkedCollected,
  orderMarkedReprinted,
  customerArrived,
  orderMarkedPickedUp,
  orderRiderUnassigned,
  orderTipCollected,
  orderCompanyRiderAssigned,
  orderPartnerRiderAssigned,
};
