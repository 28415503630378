import * as types from "./types";

const fetchNewOrders = (location: any) => ({
  type: types.FETCH_NEW_ORDERS,
  payload: {
    request: {
      method: "get",
      url: location
        ? `/api/v1/orders/new?location_id=${location?.id}`
        : "/api/v1/orders/new",
    },
  },
});

const fetchUnconfirmedOrders = (location: any) => ({
  type: types.FETCH_UNCONFIRMED_ORDERS,
  payload: {
    request: {
      method: "get",
      url: location
        ? `/api/v1/orders/home_delivery/unconfirmed?location_id=${location?.id}`
        : "/api/v1/orders/home_delivery/unconfirmed",
    },
  },
});

const markOrderConfirm = (orderId: any, orderSource: any) => ({
  type: types.SET_UNCONFIRMED_ORDER_CONFIRM,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/home_delivery/unconfirmed/${orderId}/confirm`,
    },
  },
  param: { order_id: orderId, order_source: orderSource },
});

const markOrderConfirmWithEta = (orderId: any, eta: any, orderSource: any) => ({
  type: types.SET_UNCONFIRMED_ORDER_CONFIRM,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/home_delivery/unconfirmed/${orderId}/confirm`,
      data: { delivery_time: eta },
    },
  },
  param: { order_id: orderId, order_source: orderSource },
});

const markOrderReject = (orderId: any, isAutoCancelled: boolean) => ({
  type: types.SET_UNCONFIRMED_ORDER_REJECT,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/home_delivery/unconfirmed/${orderId}/reject`,
      data: { auto_cancelled: isAutoCancelled },
    },
  },
  param: { order_id: orderId },
});

const markOrderRejectWithReason = (
  orderId: any,
  isAutoCancelled: boolean,
  cancelReasonId: any,
  supervisorPassword: any
) => ({
  type: types.SET_UNCONFIRMED_ORDER_REJECT,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/home_delivery/unconfirmed/${orderId}/reject`,
      data: {
        auto_cancelled: isAutoCancelled,
        cancel_reason_id: cancelReasonId,
        supervisor_password: supervisorPassword,
      },
    },
  },
  param: { order_id: orderId },
});

const markOrderReady = (orderId: any) => ({
  type: types.SET_ORDER_MARK_AS_READY,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/new/${orderId}/mark-as-ready`,
    },
  },
  param: { order_id: orderId },
});

const assignDriver = (orderId: any, driverId: any) => ({
  type: types.SET_ORDER_ASSIGN_RIDER,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/ready/${orderId}/assign-rider?rider_id=${driverId}`,
    },
  },
  param: { order_id: orderId },
});

const fetchReadyOrders = (location: any) => ({
  type: types.FETCH_READY_ORDERS,
  payload: {
    request: {
      method: "get",
      url: location
        ? `/api/v1/orders/ready?location_id=${location.id}`
        : "/api/v1/orders/ready",
    },
  },
});

const fetchInProgressOrders = (location: any) => ({
  type: types.FETCH_IN_PROGRESS_ORDERS,
  payload: {
    request: {
      method: "get",
      url: location
        ? `/api/v1/orders/in-progress?location_id=${location?.id}`
        : "/api/v1/orders/in-progress",
    },
  },
});

const fetchDeliveredOrders = (location: any) => ({
  type: types.FETCH_RIDER_STATS,
  payload: {
    request: {
      method: "get",
      url: location
        ? `/api/v1/orders/delivered/rider-stats?location_id=${location?.id}`
        : "/api/v1/orders/delivered/rider-stats",
    },
  },
});

const markOrderDelivered = (orderId: any, orderPayments: any) => ({
  type: types.SET_ORDER_MARK_AS_DELIVERED,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/in-progress/${orderId}/mark-as-delivered`,
      data: orderPayments,
    },
  },
  param: { order_id: orderId },
});

const fetchDeliveredDriverOrderDetails = (riderId: any, location: any) => ({
  type: types.FETCH_RIDER_ORDERS,
  payload: {
    request: {
      method: "get",
      url: location
        ? `/api/v1/orders/delivered?rider_id=${riderId}&location_id=${location?.id}`
        : `/api/v1/orders/delivered?rider_id=${riderId}`,
    },
  },
});

const resetDeliveredDriverOrderDetails = () => ({
  type: types.RESET_DELIVERED_DRIVER_ORDER_DETAILS,
  payload: [],
});

const setDeliveredRefreshRequired = () => ({
  type: types.SET_DELIVERED_ORDERS_REFRESH_REQUIRED,
  payload: [],
});

const markOrderPickedUp = (orderId: any) => ({
  type: types.SET_ORDER_MARK_AS_PICKUP,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/ready/${orderId}/mark-as-picked-up`,
    },
  },
  param: { order_id: orderId },
});

export default {
  fetchNewOrders,
  markOrderReady,
  markOrderDelivered,
  fetchUnconfirmedOrders,
  fetchReadyOrders,
  assignDriver,
  fetchInProgressOrders,
  fetchDeliveredOrders,
  fetchDeliveredDriverOrderDetails,
  resetDeliveredDriverOrderDetails,
  setDeliveredRefreshRequired,
  markOrderConfirm,
  markOrderReject,
  markOrderConfirmWithEta,
  markOrderRejectWithReason,
  markOrderPickedUp,
};
