import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import NoOrders from "../../components/noOrders";
import { filterOrderLogisticsPartners, useSelectedBranch } from "../../helpers/orderFunctions";
import { scheduledOperations } from "../../state/features/scheduled";
import Order from "../../components/order";
import { FormattedMessage } from "react-intl";
import { logisticPartnersOperations } from "../../state/features/logisticPartners";
import { driverOperations } from "../../state/features/drivers";
import { useAppStateSelector } from "../../hooks/useAppStateSelector";
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";
import { AnimateOrderTransitions } from "../../components/animateOrderTransitions";
import { AnimateTableTransition } from "../../components/animateTableTransitions";
import { useEntryTransitionConfig } from "../../hooks/useEntryTransitionConfig";

interface iScheduledProps {
  fetchScheduledOrders: any;
  fetchDrivers: any;
  fetchLogisticPartners: any;
}

function Scheduled(props: iScheduledProps) {
  const selectedBranch = useAppStateSelector(
    (state) => state.company?.selected_branch
  );
  const scheduledOrders = useAppStateSelector(
    (state) => state.order.scheduled.orders
  );
  const logisticsPartners = useAppStateSelector(
    (state) => state.logisticPartners
  );
  const companyCountry = useAppStateSelector(
    (state) => state.company.company_details.country
  );
  const logisticsPartnersWithSchedulingSupport = logisticsPartners.filter(
    (item: any) => {
      let partnerEnabledCountries =
        item?.configuration?.partner_config?.enabled_regions || [];
      let isScheduledOrdersSupported =
        item?.configuration?.partner_config?.scheduled_order_assignment ||
        false;
      return (
        partnerEnabledCountries.includes(companyCountry) &&
        isScheduledOrdersSupported
      );
    }
  );

  const { enableEntryTransition } = useEntryTransitionConfig();

  const homeDeliveryScheduledOrders = useMemo(() => {
    return scheduledOrders.filter((s: any) => !s.order_extended_info.take_away);
  }, [JSON.stringify(scheduledOrders)]);

  const scheduledOrdersWithAssignablePartners = useMemo(() => {
    return homeDeliveryScheduledOrders.filter(
      (o: any) =>
        filterOrderLogisticsPartners(
          o.location_id,
          logisticsPartnersWithSchedulingSupport
        ).length > 0
    );
  }, [
    JSON.stringify(homeDeliveryScheduledOrders),
    JSON.stringify(logisticsPartnersWithSchedulingSupport),
  ]);

  const showLogisticsPartnersColumn =
    scheduledOrdersWithAssignablePartners.length > 0 &&
    homeDeliveryScheduledOrders.length > 0;

  useEffect(() => {
    if (Object.keys(selectedBranch).length > 0) {
      props
        .fetchScheduledOrders(useSelectedBranch(selectedBranch))
        .catch((err: AxiosErrorResponse) => console.log(err));
    }
  }, [selectedBranch]);

  return (
    <div className="scheduled-orders">
      <h1 className="scheduled-head">
        <FormattedMessage id="scheduled" />
      </h1>
      <AnimateTableTransition
        isVisited={enableEntryTransition}
        show={scheduledOrders.length > 0}
        component={({ ref }) => (
          <table
            className="orders-table"
            cellPadding="0"
            cellSpacing="0"
            ref={ref}>
            <thead>
              <tr>
                <th></th>
                <th style={{ width: "180px" }}>
                  <FormattedMessage id="order.no" />.
                </th>
                <th>
                  <FormattedMessage id="scheduled_for" />
                </th>
                <th>
                  <FormattedMessage id="order.cust_or_tel" />
                </th>
                <th>
                  <FormattedMessage id="order.delivery_area" />
                </th>
                <th>
                  <FormattedMessage id="order.amount" />
                </th>
                {showLogisticsPartnersColumn ? (
                  <th>
                    <FormattedMessage id="order.driver" />
                  </th>
                ) : null}
              </tr>
            </thead>
            {scheduledOrders.length > 0 ? (
              <tbody className="orders-list-table-body">
                <AnimateOrderTransitions
                  enableEntryTransition={enableEntryTransition}
                  orders={scheduledOrders}
                  component={({ item: order }) => (
                    <Order
                      key={order.order_id}
                      orderType="scheduled"
                      orderStatus="scheduled"
                      item={order}
                      showEmptyCell={showLogisticsPartnersColumn}
                    />
                  )}
                />
              </tbody>
            ) : null}
          </table>
        )}
      />
      {!scheduledOrders.length && (
        <table className="orders-table" cellPadding="0" cellSpacing="0">
          <tbody className="orders-list-table-body">
            <td colSpan={6}>
              <NoOrders />
            </td>
          </tbody>
        </table>
      )}
    </div>
  );
}

const mapDispatchToProps = {
  fetchScheduledOrders: scheduledOperations.fetchScheduledOrders,
  fetchDrivers: driverOperations.fetchDrivers,
  fetchLogisticPartners: logisticPartnersOperations.fetchLogisticPartners,
};

export default connect(null, mapDispatchToProps)(Scheduled);
