import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getOrderFilterOptions } from "../../helpers/orderFunctions";
import TableFilterTab from "../tableFilterTab";

interface IOrdersFilterSectionProps {
  orders: any;
  orderType: any;
  orderStatus: any;
  onSearch?: any;
  allowSearch?: boolean;
  filterOptions?: any;
  onFilterChanges?: any;
  currentTab?: any;
  riderAppEnabled?: boolean;
  selectedRider: any;
  setSelectedRider:any;
  newRiderSelection:boolean;
}

function OrdersFilterSection(props: IOrdersFilterSectionProps) {
  const [filterOptions, setFilterOptions] = useState(
    getOrderFilterOptions(props.orderType)
  );
  const [showFilter, setShowFilter] = useState(false);

  const onFilterClick = (selectedFilter: any) => {
    setFilterOptions(filterOptions);
    props.onFilterChanges(selectedFilter);
  };

  useCallback(() => setFilterOptions(filterOptions), [filterOptions]);

  const onSearchChange = (searchKeyword: any) => {
    let filteringOrders = props.orders;
    props.onSearch(searchKeyword);
  };

  useEffect(() => {
    if (
      props.currentTab === "pickup.new" ||
      props.currentTab === "pickup.collected"
    ) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
  }, [props.currentTab]);

  return (
    <React.Fragment>
      {showFilter ? (
        <TableFilterTab
          filterOptions={filterOptions}
          onFilterClick={(filter: any) => onFilterClick(filter)}
          allowSearch={props.allowSearch}
          onSearch={
            props.onSearch
              ? (searchKeyword: any) => onSearchChange(searchKeyword)
              : null
          }
          orderStatus={props.orderStatus}
          orderType={props.orderType}
          riderAppEnabled={props.riderAppEnabled}
          selectedRider={props.selectedRider}
          setSelectedRider={props.setSelectedRider}
          newRiderSelection={props.newRiderSelection}
        />
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => {
  let currentTab = state.currentTab;
  let riderAppEnabled = state.company.company_details.rider_app_enabled;

  return { currentTab, riderAppEnabled };
};

export default connect(mapStateToProps)(OrdersFilterSection);
