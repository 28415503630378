import React from "react";

interface IModalCloseProps {
  onClick?: () => void;
}

const ModalCloseIcon = (props: IModalCloseProps) => {
  return (
    <span className="modal-close" onClick={props.onClick}>
      <i className="ico-times" />
    </span>
  );
};
export default ModalCloseIcon;
