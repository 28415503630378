const logisticErrors = {
  oops: {
    en: "Oops",
    ar: "عذرا",
    ms: "Alamak",
    zh: "糟糕",
  },
  globalErr: {
    en: "Something went wrong. Please try again.",
    ar: "هناك خطأ ما، حاول مرة اخرى.",
    ms: "Sesuatu telah berlaku. Sila cuba lagi.",
    zh: "出现问题。请再试一次。",
  },
  thirdPartyErr: {
    en: "Your request for a third-party driver has been canceled for order",
    ar: "تم إلغاء طلبك لسائق جهة خارجية من أجل طلب",
    ms: "Permintaan anda untuk pemandu pihak ketiga telah dibatalkan untuk pesanan",
    zh: "您针对订单发出的第三方司机请求已取消",
  },
  unConfirmErr: {
    en: "Unable to confirm the order!",
    ar: "غير قادر على تأكيد الطلب!",
    ms: "Tidak dapat mengesahkan pesanan!",
    zh: "无法确认订单！",
  },
  zomatoErr: {
    en: "Zomato's systems are not accepting confirmation",
    ar: "أنظمة زوماتو لا تقبل التثبيت حاليا",
    ms: "Sistem Zomato tidak menerima pengesahan",
    zh: "Zomato的系统不接受确认",
  },
  talabatErr: {
    en: "Talabat's systems are not accepting confirmation",
    ar: "أنظمة طلبات لا تقبل التثبيت حاليا",
    ms: "Sistem Talabat tidak menerima pengesahan",
    zh: "Talabat的系统不接受确认",
  },
  deliverooErr: {
    en: "Deliveroo's systems are not accepting confirmation",
    ar: "أنظمة ديليفيرو لا تقبل التثبيت حاليا",
    ms: "Sistem Deliveroo tidak menerima pengesahan",
    zh: "Deliveroo的系统不接受确认",
  },
  deliverectErr: {
    en: "Deliverect's systems are not accepting confirmation",
    ar: "أنظمة ديليفيركتس لا تقبل التثبيت حاليا",
    ms: "Sistem Deliverrect tidak menerima pengesahan",
    zh: "Deliverect的系统不接受确认",
  },
  uberErr: {
    en: "Uber Eats's systems are not accepting confirmation",
    ar: "أنظمة أوبر إيتس لا تقبل التثبيت حاليا",
    ms: "Sistem Uber Eats tidak menerima pengesahan",
    zh: "Uber Eats的系统不接受确认",
  },
  foodPandaErr: {
    en: "Foodpanda's systems are not accepting confirmation",
    ar: "أنظمة فودباندا لا تقبل التثبيت حاليا",
    ms: "Sistem Foodpanda tidak menerima pengesahan",
    zh: "Foodpanda的系统不接受确认",
  },
  grabErr: {
    en: "Grab's systems are not accepting confirmation",
    ar: "أنظمة جراب لا تقبل التثبيت حاليا",
    ms: "Sistem Grab tidak menerima pengesahan",
    zh: "Grab的系统不接受确认",
  },
  conatctText: {
    en: "Please contact your",
    ar: "الرجاء الإتصال بـ ",
    ms: "Sila hubungi",
    zh: "请联络您的",
  },
  supportText: {
    en: "support agent or representative to resolve this issue",
    ar: "وكيل/ ممثل الدعم لحل هذه المشكلة",
    ms: "ejen sokongan atau wakil anda untuk menyelesaikan isu ini",
    zh: "支持代理或代表以解决此问题",
  },
};
export { logisticErrors };
