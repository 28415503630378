import * as types from "./types";

const logisticPartnersReducer = (state = [], action: any) => {
  switch (action.type) {
    case types.FETCH_LOGISTIC_PARTNERS_SUCCESS:
      return action.payload.data || [];
    case types.RESET_LOGISTIC_PARTNERS:
      return action.payload || [];
    default:
      return state;
  }
};

const reducer = logisticPartnersReducer;

export default reducer;
