import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./app.scss";
import { BrowserRouter } from "react-router-dom";
import App from "../components/app";
import { Provider } from "react-redux";
import { store } from "../state/store";
import Pusher from "pusher-js";
import { getEnvValueFor } from "../helpers/utils";
import BrowserCompatibility from "../components/browserCompatibility";
import LocaleWrapper from "../wrappers/localeWrapper";
import AuthProvider from "../components/authProvider";
import AudioProvider from "../components/audioProvider";
import PusherProvider from "../components/pusherProvider";

document.addEventListener("DOMContentLoaded", () => {
  // Initializing Pusher
  let element = document.getElementById("root") as any;
  let pusherId = element?.dataset.pusherId;
  let pusher;
  // Pusher.logToConsole = true
  if (pusherId !== undefined) {
    pusher = new Pusher(pusherId, {});
  } else {
    console.log("[PUSHER] ERROR: Pusher ID is undefined");
    pusher = undefined;
  }

  // Initializing Sentry
  Sentry.init({
    dsn: getEnvValueFor("SENTRY_APP_URL"),
    normalizeDepth: 15,
    integrations: [new Integrations.BrowserTracing()],
    environment: getEnvValueFor("SENTRY_ENVIRONMENT") || "development",
    tracesSampleRate: parseFloat(
      getEnvValueFor("SENTRY_TRACE_SAMPLE_RATE") || "0.5"
    ),
  });

  ReactDOM.render(
    <Provider store={store}>
      <PusherProvider pusher={pusher}>
        <LocaleWrapper>
          <AuthProvider>
            <AudioProvider>
              <BrowserCompatibility>
                <BrowserRouter>
                  <App pusher={pusher} />
                </BrowserRouter>
              </BrowserCompatibility>
            </AudioProvider>
          </AuthProvider>
        </LocaleWrapper>
      </PusherProvider>
    </Provider>,
    document.getElementById("root")
  );
});
