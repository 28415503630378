import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { homeDeliveryOperations } from "../../state/features/homeDelivery";
import { currentTabOperations } from "../../state/features/currentTab";
import NoOrders from "../noOrders";
import Order from "../order";
import { groupOrders } from "../../helpers/orderFunctions";
import Swal from "sweetalert2";
import { FormattedMessage, useIntl } from "react-intl";
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";
import { AnimateOrderTransitions } from "../animateOrderTransitions";
import { groupOrdersByDeliveryArea } from "../../helpers/utils";
import { AnimateDeliveryAreaTransition } from "../animateDeliveryAreaTransitions";
import { AnimateTableTransition } from "../animateTableTransitions";
import { useAppStateSelector } from "../../hooks/useAppStateSelector";
import { useEntryTransitionConfig } from "../../hooks/useEntryTransitionConfig";

interface IUnconfirmedOrdersProps {
  unconfirmedOrders: any;
  setCurrentTab: any;
  markOrderConfirm?: any;
  markOrderReject?: any;
  markOrderConfirmWithEta?: any;
  markOrderRejectWithReason?: any;
}

function UnconfirmedOrders(props: IUnconfirmedOrdersProps) {
  const [filteredUnconfirmedOrders, setFilteredUnconfirmedOrders] = useState(
    {} as any
  );
  const [selectedOrderId, setSelectedOrderId] = useState(0);
  const [disableButton, setDisable] = useState(false);
  const [hideDetails, setHideDetails] = useState(false);
  const { enableEntryTransition } = useEntryTransitionConfig();

  const intl = useIntl();
  const formattedMessage = (formatId: any) => {
    const translatedMessage = intl.formatMessage({ id: formatId });
    return translatedMessage;
  };

  const { remove_grouping_by_delivery_area } = useAppStateSelector(
    (state) => state.company.company_details
  );

  useEffect(() => {
    props.setCurrentTab("homeDelivery.unconfirmed");
  }, []);

  useEffect(() => {
    setFilteredUnconfirmedOrders(groupOrders(props.unconfirmedOrders));
  }, [JSON.stringify(props.unconfirmedOrders)]);

  const handleApprove = (orderId: any, eta: any, orderSource: any) => {
    setDisable(true);
    setSelectedOrderId(orderId);
    setHideDetails(false);
    if (eta) {
      props
        .markOrderConfirmWithEta(orderId, eta, orderSource)
        .then(() => {
          setDisable(false);
          setHideDetails(true);
        })
        .catch((err: AxiosErrorResponse) => {
          console.log(err);
          setDisable(false);
        });
    } else {
      props
        .markOrderConfirm(orderId, orderSource)
        .then(() => {
          setDisable(false);
          setHideDetails(true);
        })
        .catch((err: AxiosErrorResponse) => {
          console.log(err);
          setDisable(false);
        });
    }
  };
  const handleReject = (
    orderId: any,
    cancelReasonId: any,
    supervisorPassword: any
  ) => {
    setDisable(true);
    setSelectedOrderId(orderId);
    setHideDetails(false);
    props
      .markOrderRejectWithReason(
        orderId,
        false,
        cancelReasonId,
        supervisorPassword
      )
      .then((res: any) => {
        setDisable(false);
        setHideDetails(true);
      })
      .catch((err: any) => {
        if (
          err.error.response !== undefined &&
          err.error.response.status === 422
        ) {
          setDisable(false);
          let text: any = formattedMessage("global.password_invalid");
          Swal.fire({
            icon: "warning",
            text: text,
          });
        }
      });
  };

  const handleAutoReject = (orderId: any) => {
    props.markOrderReject(orderId, true);
  };

  const hasOrders = useMemo(
    () => Object.keys(filteredUnconfirmedOrders).length > 0,
    [JSON.stringify(filteredUnconfirmedOrders)]
  );

  const ordersByDeliveryArea = groupOrdersByDeliveryArea(
    filteredUnconfirmedOrders
  );

  return (
    <div className="unconfirmed-orders">
      <AnimateTableTransition
        isVisited={enableEntryTransition}
        show={hasOrders}
        component={({ ref }) => (
          <table
            className="orders-table"
            cellPadding="0"
            cellSpacing="0"
            ref={ref}>
            <thead>
              <tr>
                <th></th>
                <th style={{ width: "180px" }}>
                  <FormattedMessage id="order.no" />.
                </th>
                <th className="orderTakenLabel">
                  <FormattedMessage id="order.taken_at" />
                </th>
                <th>
                  <FormattedMessage id="order.cust_or_tel" />
                </th>
                <th>
                  <FormattedMessage id="order.amount" />
                </th>
                <th>
                  <FormattedMessage id="order.auto_rejects" />
                </th>
                <th className="text-right"></th>
              </tr>
            </thead>
            {remove_grouping_by_delivery_area ? (
              <tbody className="orders-list-table-body">
                <AnimateOrderTransitions
                  enableEntryTransition={enableEntryTransition}
                  orders={props.unconfirmedOrders}
                  component={({ item: order }) => (
                    <Order
                      handleApprove={handleApprove}
                      handleReject={(
                        orderId: any,
                        cancelReasonId: any,
                        supervisorPassword: any
                      ) =>
                        handleReject(
                          orderId,
                          cancelReasonId,
                          supervisorPassword
                        )
                      }
                      handleAutoReject={handleAutoReject}
                      orderType="unconfirmed"
                      orderStatus="unconfirmed"
                      key={order.order_id}
                      disableButton={
                        order.order_id === selectedOrderId && disableButton
                      }
                      item={order}
                      hideDetails={hideDetails}
                    />
                  )}
                />
              </tbody>
            ) : (
              <>
                {ordersByDeliveryArea.map((item: any) => (
                  <AnimateDeliveryAreaTransition
                    key={item.deliveryArea}
                    show={Boolean(item.orders.length)}
                    component={({ areaRef }) => (
                      <tbody
                        key={item.deliveryArea}
                        className="orders-list-table-body"
                        ref={areaRef}>
                        <tr className="location-header">
                          <td colSpan={7}>
                            {item.deliveryArea == "null"
                              ? "N/A"
                              : item.deliveryArea}
                          </td>
                        </tr>
                        <AnimateOrderTransitions
                          enableEntryTransition={enableEntryTransition}
                          orders={item.orders}
                          component={({ item: order }) => (
                            <Order
                              handleApprove={handleApprove}
                              handleReject={(
                                orderId: any,
                                cancelReasonId: any,
                                supervisorPassword: any
                              ) =>
                                handleReject(
                                  orderId,
                                  cancelReasonId,
                                  supervisorPassword
                                )
                              }
                              handleAutoReject={handleAutoReject}
                              orderType="unconfirmed"
                              orderStatus="unconfirmed"
                              key={order.order_id}
                              disableButton={
                                order.order_id === selectedOrderId &&
                                disableButton
                              }
                              item={order}
                              hideDetails={hideDetails}
                            />
                          )}
                        />
                      </tbody>
                    )}
                  />
                ))}
              </>
            )}
          </table>
        )}
      />
      {!hasOrders ? <NoOrders /> : null}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let unconfirmedOrders =
    state.order.home_delivery.unconfirmed_orders.orders || [];
  return { unconfirmedOrders };
};

const mapDispatchToProps = {
  setCurrentTab: currentTabOperations.setCurrentTab,
  markOrderConfirm: homeDeliveryOperations.markOrderConfirm,
  markOrderConfirmWithEta: homeDeliveryOperations.markOrderConfirmWithEta,
  markOrderReject: homeDeliveryOperations.markOrderReject,
  markOrderRejectWithReason: homeDeliveryOperations.markOrderRejectWithReason,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnconfirmedOrders);
