import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Modal, Tabs, Tab } from "react-bootstrap";
import ModalCloseIcon from "../modalCloseIcon";
import SapaadLogo from "../../images/sapaad-square.png";
import {
  getStatusTime,
  updateOrderTotalAmount,
} from "../../helpers/orderFunctions";
import { connect } from "react-redux";
import { reprintsOperations } from "../../state/features/reprints";
import {
  faCheckCircle,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import { getEnvValueFor, isAggregator } from "../../helpers/utils";
import Swal from "sweetalert2";
import CancellationPopup from "../cancellationReasonPopup";
import { cancelReasonsOperations } from "../../state/features/cancelReasons";
import Eta_Bg from "../../images/eta-bg.png";
import orderImages from "../../helpers/orderImage";
import OrderItemsTable from "../orderDetailsPopupComponents/orderItemsTable";
import CustomerDetails from "../orderDetailsPopupComponents/customerDetails";
import ThirdPartyDetails from "../orderDetailsPopupComponents/thirdPartyDetails";
import PaymentDetails from "../orderDetailsPopupComponents/paymentDetails";
import StatusHistory from "../orderDetailsPopupComponents/statusHistory";
import ModificationHistory from "../orderDetailsPopupComponents/modificationHistory";
import * as Sentry from "@sentry/react";
import { FormattedMessage, useIntl } from "react-intl";
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";
interface OrderPopupProps {
  onClose?: any;
  order: any;
  orderType?: any;
  fetchReprints?: any;
  reprints?: any;
  resetReprints?: any;
  markOrderReprinted?: any;
  currentTab?: any;
  orderStatus?: any;
  user_permissions?: any;
  handleOrderApprove?: any;
  handleOrderReject?: any;
  dmCanRecordPayment?: any;
  defaultDeliveryEta?: any;
  fetchCancelReasons?: any;
  handleWalkinOrderApprove?: any;
  limboStateOrders?: any;
  loyaltyDetails: any;
  isAdmin?: boolean;
}

function OrderPopup(props: OrderPopupProps) {
  const [show, setShow] = useState(true);
  const [showStatusHistory, setShowStatusHistory] = useState(false);
  const [showReprints, setShowReprints] = useState(false);
  const [reprintsList, setReprintsList] = useState([] as any);
  const [showModify, handleModifyShow] = useState(true);
  const [showApproveRejectCta, setShowApproveRejectCta] = useState(false);
  const [showRepay, handleRepayShow] = useState(false);
  const [disableModify, handleModifyDisable] = useState(false);
  const [order, setOrder] = useState({} as any);
  const [eta, setEta] = React.useState(0 as number);
  const [disabledConfirm, setConfirmDisabled] = useState(false);
  const [etaInputFocus, setEtaInputFocus] = useState(true);
  const [showDeliveryEta, setShowDeliveryEta] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [aggregatorsDetailsShow, setAggregatorsDetailsShow] = useState(false);
  const [isLimboState, setIsLimboState] = useState(false);
  const [disableOrderApprove, setDisableOrderApprove] = useState(false);

  const etaInput: any = React.useRef();

  const showVoidOrderCta = useMemo(() => {
    if (!(props.orderStatus === "unconfirmed")) {
      if (
        (props.order.order_type === "online" ||
          props.order.order_type === "walk_in") &&
        !props.order.order_status.includes("delivered")
      ) {
        return true;
      }
    }
    return false;
  }, [props.orderStatus, props.order?.order_type, props.order?.order_status]);

  const intl = useIntl();
  const formattedMessage = (formatId: any) => {
    const translatedMessage = intl.formatMessage({ id: formatId });
    return translatedMessage;
  };

  useEffect(() => {
    props.resetReprints();
    Sentry.addBreadcrumb({
      category: "props",
      type: "Debug",
      message: `order item ${props.order.order_id}`,
      data: {
        ...props.order,
        order_extended_info: {
          ...props.order.order_extended_info,
          items: props.order.order_extended_info.items.length,
        },
      },
      level: "debug",
    });
  }, []);

  useEffect(() => {
    setOrder(props.order);
    setShowStatusHistory(true);
    setEta(props.order.order_extended_info.delivery_eta);
  }, [props.order]);

  useEffect(() => {
    if (props.limboStateOrders.length) {
      if (props.limboStateOrders.includes(props.order.order_id)) {
        setIsLimboState(true);
      }
    }
  }, [props.limboStateOrders.length]);

  useEffect(() => {
    if (etaInput.current) {
      etaInput.current.focus();
    }
  }, [showApproveRejectCta, etaInputFocus]);

  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      props.onClose();
    }, 500);
  };

  const toggleReprints = (orderId: any) => {
    props
      .fetchReprints(orderId)
      .catch((err: AxiosErrorResponse) => console.log(err));
    setShowReprints(true);
  };

  useEffect(() => {
    setReprintsList(props.reprints);
  }, [props.reprints]);

  const closeReprints = () => {
    setShowReprints(false);
  };

  useEffect(() => {
    if (!props.user_permissions.includes("all.manage")) {
      if (
        props.orderStatus === "collected" ||
        props.orderStatus === "delivered"
      ) {
        if (!props.user_permissions.includes("order.modify")) {
          handleModifyShow(false);
        }
        if (props.order.order_type === "online") {
          handleModifyShow(false);
        }
      } else {
        if (!props.user_permissions.includes("order.create")) {
          handleModifyShow(false);
        }
      }
    } else {
      if (
        props.order.order_type === "online" &&
        props.order.order_status.includes("delivered")
      ) {
        handleModifyShow(false);
      }
    }
    if (
      props.order.order_type === "online" &&
      props.order.order_source !== "sapaad_online"
    ) {
      handleModifyShow(false);
    }
    if (!props.user_permissions.includes("all.manage")) {
      if (!props.user_permissions.includes("weborders.confirm")) {
        setDisableOrderApprove(true);
      }
    }
  }, [props.user_permissions]);

  useEffect(() => {
    if (
      (props.order.order_type === "online" ||
        props.order.order_type === "walk_in") &&
      props.order.order_status.includes("delivered") &&
      props.order.is_cod
    ) {
      if (props.order.order_extended_info.take_away) {
        handleRepayShow(true);
      } else {
        if (props.dmCanRecordPayment) {
          handleRepayShow(true);
        }
      }
    }

    if (
      !props.order.is_cod &&
      props.order.order_type === "online" &&
      props.order.order_source === "sapaad_online"
    ) {
      handleModifyDisable(true);
    }
    if (
      props.order.order_type === "online" &&
      props.order.placed_at === props.order.scheduled_for &&
      !props.order.order_extended_info.take_away &&
      props.order.order_status.includes("unconfirmed")
    ) {
      setShowDeliveryEta(true);
    }

    // Modify disabled for walk_in orders
    if (props.order.order_type === "walk_in") {
      handleModifyShow(false);
    }

    // Aggregators details show handle method
    if (
      props.order.order_type === "online" &&
      props.order.order_source != "sapaad_online"
    ) {
      setAggregatorsDetailsShow(true);
    }
  }, [props.order]);

  useEffect(() => {
    if (props.orderStatus === "unconfirmed") {
      setShowApproveRejectCta(true);
      handleModifyShow(false);
    }
  }, [props.orderStatus]);

  const openReprintWindow = (reprintUrl: any, orderId: any) => {
    // check which tab the current user is.
    if (
      props.currentTab === "homeDelivery.delivered" ||
      props.currentTab === "pickup.collected"
    ) {
      props.markOrderReprinted(orderId).then((response: any) => {
        if (response.payload.status === 200) {
          setTimeout(() => {
            window.open(
              `${reprintUrl}`,
              "REPRINT",
              `resizable=yes,width=400,height=500,toolbar=0,menubar=0,location=0,status=1,scrollbars=1,resizable=1,left=0,top=0`
            );
          }, 300);
        }
      })
      .catch((err: AxiosErrorResponse) => console.log(err));;
    } else {
      setTimeout(() => {
        window.open(
          `${reprintUrl}`,
          "REPRINT",
          `resizable=yes,width=400,height=500,toolbar=0,menubar=0,location=0,status=1,scrollbars=1,resizable=1,left=0,top=0`
        );
      }, 300);
    }
    closeReprints();
  };

  const handleDisabledModifyClick = () => {
    let text: any = formattedMessage("modify_err1");
    let title: any = formattedMessage("modify_err");
    Swal.fire({
      title: title,
      text: text,
      icon: "info",
    });
  };

  const handleDisabledApproveClick = () => {
    let text: any = formattedMessage("approve_err");
    let title: any = formattedMessage("oops");
    Swal.fire({
      title: title,
      text: text,
      icon: "info",
    });
  };

  const handleInputBlur = (e: any) => {
    let regExp = /^([0-9]|[0-9][0-9]|[0-9][0-9][0-9])$/;
    setEtaInputFocus(!regExp.test(e.target.value));
  };

  const setEtaInput = (e: any) => {
    let regExp = /^([0-9]|[0-9][0-9]|[0-9][0-9][0-9])$/;
    setConfirmDisabled(!regExp.test(e.target.value));
    setEta(e.target.value);
  };

  const handleApproveOrder = () => {
    if (showDeliveryEta) {
      props.handleOrderApprove(
        props.order.order_id,
        eta,
        props.order.order_source
      );
      setConfirmDisabled(true)
    } else {
      if (
        props.order.order_type === "walk_in" &&
        props.order.order_extended_info.take_away
      ) {
        if (props.loyaltyDetails && props.loyaltyDetails.walkin) {
          // redirect to pos for payment
          let form = document.querySelector(
            `#walkinOrderPay-${props.order.order_id}`
          ) as HTMLFormElement;
          window.open('',`walkinOrderPay-${props.order.order_id}`);
          form.submit();
        } else {
          props.handleWalkinOrderApprove(true);
        }
      } else {
        props.handleOrderApprove(
          props.order.order_id,
          props.order.order_source
        );
        setConfirmDisabled(true)
      }
    }
  };

  const handleOrderRejectClick = () => {
    props
      .fetchCancelReasons()
      .catch((err: AxiosErrorResponse) => console.log(err));
    setShowCancelPopup(true);
  };

  const handleRejectOrder = (cancelReasonId: any, supervisorPassword: any) => {
    props.handleOrderReject(
      props.order.order_id,
      parseInt(cancelReasonId),
      supervisorPassword
    );
  };

  const handleAggregatorVoidClick = () => {
    let text: any = formattedMessage("void_err");
    Swal.fire({
      icon: "info",
      text: text,
    });
  };

  useEffect(() => {
    if (order.logistics_job) {
      if (
        order.logistics_job.status === 5 ||
        order.logistics_job.status === 6
      ) {
        handleModifyShow(false);
      }
    }
  }, [order]);

  return (
    <Modal
      show={show}
      dialogClassName="custom curved order-popup-wrapper"
      onHide={handleClose}
      size="xl"
      centered
    >
      <ModalCloseIcon onClick={handleClose} />
      <div className="order-popup">
        <div className="popup-header">
          <img
            src={
              props.order.order_source
                ? orderImages[props.order.order_source]?.image
                : SapaadLogo
            }
            className="source-image"
          />
          <div className="order-number">
            <label>
              <FormattedMessage id="order.no" />:
            </label>
            <p className="value">
              {props.order.order_extended_info.order_number}
            </p>
          </div>
          {!props.order.is_cod ? (
            <span className="order-payment-status">
              <FontAwesomeIcon className="tick" icon={faCheckCircle} />
              <FormattedMessage id="order.paid" />
            </span>
          ) : null}
        </div>
        <div className="order-details-container">
          <OrderItemsTable order={props.order} />
          <div className="right-container-wrapper">
            <div className="other-details">
              {props.order.scheduled_for > props.order.placed_at ? (
                <div className="schedule-info">
                  <div className="info">
                    <p className="label">
                      <FormattedMessage id="order_popup_scheduled_for" />:
                    </p>
                    <p className="value">
                      {getStatusTime(props.order.scheduled_for)}
                    </p>
                  </div>
                </div>
              ) : null}
              {showDeliveryEta ? (
                <div
                  className="delivery-eta"
                  style={{ backgroundImage: `url(${Eta_Bg})` }}
                >
                  <p>
                    <FormattedMessage id="order_popup_eta" />
                  </p>
                  <div className="input-section">
                    {etaInputFocus ? (
                      <input
                        ref={etaInput}
                        min={0}
                        type="number"
                        value={eta}
                        onBlur={handleInputBlur}
                        onFocus={() => setEtaInputFocus(true)}
                        onChange={(e: any) => setEtaInput(e)}
                      />
                    ) : (
                      <span onClick={() => setEtaInputFocus(true)}>
                        {eta}&nbsp;{" "}
                      </span>
                    )}
                    <span onClick={() => setEtaInputFocus(true)}>
                      <FormattedMessage id="time.minutes" />
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="container-details">
              <Tabs
                defaultActiveKey="tab1"
                id="order-details-tabs"
                className=""
              >
                <Tab eventKey="tab1" title={<FormattedMessage id="details" />} className="pt-3">
                  <div
                    className="right-container-content"
                    style={{ flex: 1, overflow: "scroll" }}
                  >
                    <CustomerDetails order={props.order} />
                    {aggregatorsDetailsShow &&
                    props.order.order_extended_info.meta_note ? (
                      <ThirdPartyDetails
                        details={
                          props.order.order_extended_info.meta_note.system_note
                            ? props.order.order_extended_info.meta_note
                                .system_note
                            : ""
                        }
                      />
                    ) : null}
                    <PaymentDetails
                      dmCanRecordPayment={props.dmCanRecordPayment}
                      totalAmount={updateOrderTotalAmount(props.order)}
                      isOrderDelivered={props.order.order_status.includes(
                        "delivered"
                      )}
                      payments={props.order.order_extended_info.payments}
                    />
                  </div>
                </Tab>
                <Tab eventKey="tab2" title={<FormattedMessage id="order.history" />}>
                  {showStatusHistory ? (
                    <div
                      className="right-container-content pt-3"
                      style={{
                        flex: 1,
                        overflow: "scroll",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <StatusHistory order={order} />
                      <ModificationHistory order={props.order} />
                    </div>
                  ) : (
                    <></>
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="ctas-container pb-0">
          {showModify ? (
            disableModify ? (
              <div style={{ position: "relative", marginRight: "10px" }}>
                <div
                  style={{
                    position: "absolute",
                    width: "178px",
                    height: "60px",
                    zIndex: 1,
                  }}
                  onClick={() => handleDisabledModifyClick()}
                />
                <button
                  disabled
                  className="btn btn-success px-5 disabled-button allowed"
                >
                  <FormattedMessage id="cta_modify" />
                </button>
              </div>
            ) : (
              <a
                href={
                  props.order.order_status.includes("delivered")
                    ? `${getEnvValueFor(
                        "SAPAAD_CORE_BASE_URL"
                      )}/callcenter/edit_order/${
                        props.order.order_id
                      }?crm_modify=true`
                    : `${getEnvValueFor(
                        "SAPAAD_CORE_BASE_URL"
                      )}/callcenter/edit_order/${props.order.order_id}`
                }
                target="_blank"
                className="btn btn-success px-5"
              >
                <FormattedMessage id="cta_modify" />
              </a>
            )
          ) : null}
          {showApproveRejectCta ? (
            <>
              {disableOrderApprove ? (
                <div style={{ position: "relative", marginRight: "10px" }}>
                  <div
                    style={{
                      position: "absolute",
                      width: "164px",
                      height: "60px",
                      zIndex: 1,
                    }}
                    onClick={() => handleDisabledApproveClick()}
                  />
                  <button
                    onClick={() => handleApproveOrder()}
                    className="btn btn-success px-5 disabled-button allowed"
                    disabled
                  >
                    <FormattedMessage id="cta_approve" />
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => handleApproveOrder()}
                  className={
                    disabledConfirm
                      ? "btn btn-success px-5 disabled-button"
                      : "btn btn-success px-5"
                  }
                  disabled={disabledConfirm}
                >
                  {isLimboState ? (
                    <FormattedMessage id="cta_retry" />
                  ) : (
                    <FormattedMessage id="cta_approve" />
                  )}
                </button>
              )}
              {disableOrderApprove ? (
                <div style={{ position: "relative", marginRight: "10px" }}>
                  <div
                    style={{
                      position: "absolute",
                      width: "147px",
                      height: "60px",
                      zIndex: 1,
                    }}
                    onClick={() => handleDisabledApproveClick()}
                  />
                  <button
                    disabled
                    className="btn btn-success px-5 disabled-button allowed"
                  >
                    <FormattedMessage id="cta_reject" />
                  </button>
                </div>
              ) : (
                <a
                  onClick={handleOrderRejectClick}
                  className="btn btn-outline-secondary px-5"
                >
                  {isLimboState ? (
                    <FormattedMessage id="cta_move_rejected" />
                  ) : (
                    <FormattedMessage id="cta_reject" />
                  )}
                </a>
              )}
            </>
          ) : null}
          {showRepay ? (
            <a
              href={`${getEnvValueFor(
                "SAPAAD_CORE_BASE_URL"
              )}/callcenter/edit_order/${
                props.order.order_id
              }?repay=true&crm_modify=true`}
              target="_blank"
              className="btn btn-outline-secondary px-4"
            >
              <FormattedMessage id="cta_repay" />
            </a>
          ) : null}

          {props.orderStatus !== "unconfirmed" ? (
            <>
              <Dropdown
                show={showReprints}
                drop="up"
                onToggle={
                  showReprints
                    ? () => closeReprints()
                    : () => toggleReprints(props.order.order_id)
                }
              >
                <Dropdown.Toggle
                  as="button"
                  className="btn btn-outline-secondary px-4 reprint-button"
                >
                  <FormattedMessage id="cta_reprint" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="reprints-dropdown">
                  {reprintsList.length ? (
                    reprintsList.map((reprint: any, index: any) => {
                      return (
                        <span
                          key={index}
                          className="reprint"
                          onClick={() =>
                            openReprintWindow(
                              reprint.print_url,
                              props.order.order_id
                            )
                          }
                        >
                          {reprint.name}
                        </span>
                      );
                    })
                  ) : (
                    <div className="text-center py-2">
                      <FontAwesomeIcon
                        className="fa-spin"
                        size="lg"
                        icon={faCircleNotch}
                      />
                    </div>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : null}
          {showVoidOrderCta ? (
            isAggregator(props.order.order_source) ? (
              <div className="pos-right">
                <div
                  style={{
                    position: "absolute",
                    width: "132px",
                    height: "60px",
                    zIndex: 1,
                  }}
                  onClick={handleAggregatorVoidClick}
                />
                <button
                  disabled
                  className="btn btn-success font-weight-bold px-5 disabled-button allowed"
                >
                  <FormattedMessage id="cta_void" />
                </button>
              </div>
            ) : (
              <a
                href={`${getEnvValueFor(
                  "SAPAAD_CORE_BASE_URL"
                )}/callcenter/edit_order/${props.order.order_id}`}
                target="_blank"
                className="btn btn-danger px-5 pos-right font-weight-bold"
              >
                <FormattedMessage id="cta_void" />
              </a>
            )
          ) : null}
        </div>
      </div>
      {showCancelPopup ? (
        <CancellationPopup
          onRejectConfirm={(cancelReasonId: any, supervisorPassword: any) =>
            handleRejectOrder(cancelReasonId, supervisorPassword)
          }
          onCancel={() => setShowCancelPopup(false)}
        />
      ) : null}
    </Modal>
  );
}

const mapStateToProps = (state: any) => {
  let reprints = state.reprints;
  let currentTab = state.currentTab;
  let dmCanRecordPayment = state.company.company_details.dm_can_record_payment;
  let user_permissions = state.company.company_details.user_permissions;
  let defaultDeliveryEta =
    state.company.company_details.sapaad_online_details.default_delivery_eta;
  let limboStateOrders = state.limboStateOrders;
  let loyaltyDetails = state.company.company_details.loyalty_details;
  let isAdmin = state.company?.company_details.admin;

  return {
    reprints,
    user_permissions,
    currentTab,
    dmCanRecordPayment,
    defaultDeliveryEta,
    limboStateOrders,
    loyaltyDetails,
    isAdmin,
  };
};

const mapDispatchToProps = {
  fetchReprints: reprintsOperations.fetchReprints,
  resetReprints: reprintsOperations.resetReprints,
  markOrderReprinted: reprintsOperations.markOrderReprinted,
  fetchCancelReasons: cancelReasonsOperations.fetchCancelReasons,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPopup);
