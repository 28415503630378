import { dispatchAction } from "../../store";
import * as actions from "./actions";

const authorizeUser = (params: any) =>
  dispatchAction(actions.authorizeUser(params));

const setInitiateTicker = (params: any) =>
  dispatchAction(actions.setInitiateTicker(params));

const setJwtData = (params: any) => dispatchAction(actions.setJwtData(params));

const setSessionExpired = () => dispatchAction(actions.setSessionExpired());

export default {
  authorizeUser,
  setInitiateTicker,
  setJwtData,
  setSessionExpired,
};
