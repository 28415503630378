import React, { useRef, ReactNode } from "react";
import { usePlayAudioOnLoop } from "../../hooks/usePlayAudioOnLoop";
import { AudioContext } from "../../context/audioContext";

interface IAudioProviderProps {
  children: ReactNode;
}

const AudioProvider = ({ children }: IAudioProviderProps) => {
  const newOrderAudioRef = useRef<HTMLAudioElement>(null);
  const unconfirmedAudioRef = useRef<HTMLAudioElement>(null);

  const handleUnconfirmedAudio = () => {
    if (unconfirmedAudioRef.current) {
      unconfirmedAudioRef.current.play().catch((e) => console.log(e));
    }
  };

  const { playAudioOnLoop, clearActivePlayAudio } = usePlayAudioOnLoop({
    playAudio: handleUnconfirmedAudio,
  });

  const handlePlayNewNotifyAudio = () => {
    if (newOrderAudioRef.current) {
      newOrderAudioRef.current.play().catch((e) => console.log(e));
    }
  };

  const handlePlayUnconfirmedNotifyAudio = () => {
    playAudioOnLoop();
  };

  const handleCancelUnconfirmedAudio = () => {
    clearActivePlayAudio();
  };

  return (
    <AudioContext.Provider
      value={{
        playNewOrderAudio: handlePlayNewNotifyAudio,
        playUnconfirmedOrderAudio: handlePlayUnconfirmedNotifyAudio,
        cancelUnconfirmedAudio: handleCancelUnconfirmedAudio,
      }}
    >
      <audio id="newOrderNotifyAudio" ref={newOrderAudioRef}>
        <source src="/new_order_notify.mp3" type="audio/mp3" />
      </audio>
      <audio id="unconfirmedOrderNotifyAudio" ref={unconfirmedAudioRef}>
        <source src="/unconfirmed_order_notify.wav" type="audio/wav" />
      </audio>
      {children}
    </AudioContext.Provider>
  );
};

export default AudioProvider;
