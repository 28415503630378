import React, { useRef } from "react";
import { Button } from "react-bootstrap";

interface OrderButtonGroupProps {
  title: any;
  setOrderAction: any;
  disable: any;
}

export default function OrderButtonGroup(props: OrderButtonGroupProps) {
  const debouncedClick = useRef(false);

  const handleClick = () => {
    if (!debouncedClick.current) {
      props.setOrderAction();
      debouncedClick.current = true;
      setTimeout(() => {
        debouncedClick.current = false;
      }, 3000);
    }
  };

  return (
    <div>
      <Button
        onClick={handleClick}
        disabled={props.disable}
        className="dropdown-group-btn light-green"
        variant=""
      >
        {props.title}
      </Button>
    </div>
  );
}
