import { removeLoyaltyPayment } from "../../../helpers/orderFunctions";
import * as types from "./types";

const getRiderProximity = (state = {}, action: any) => {
  switch (action.type) {
    case types.FETCH_RIDER_PROXIMITY:
      return action.payload.data || {};

    default:
      return state;
  }
};

const reducer = getRiderProximity;

export default reducer;
