import React, { useState, useEffect, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";

interface IFilterDropdownProps {
  drivers: any;
  selectedBranch: any;
  company: any;
  selectedRider: any;
  setSelectedRider: any;
  theme: any;
}

function FilterDropdown(props: IFilterDropdownProps) {
  const [show, setShow] = useState(false);
  const [drivers, setDrivers] = useState([]);

  const onDropdownToggle = (e: any) => {
    setShow(!show);
  };

  useEffect(() => {
    props.setSelectedRider(null);
    if (
      props.drivers.length > 0 &&
      Object.keys(props.selectedBranch).length > 0
    ) {
      if (props.selectedBranch.id) {
        let locationDrivers = [] as any;
        props.drivers.map((driver: any) => {
          if (driver.assigned_locations.includes(props.selectedBranch.id)) {
            locationDrivers.push(driver);
          }
        });
        setDrivers(locationDrivers);
      } else {
        if (
          props.company.admin &&
          props.company.authorized_location_ids.length < 1
        ) {
          setDrivers(props.drivers);
        } else {
          // staff user - Multiple branches
          let locationDrivers = [] as any;
          props.company.authorized_location_ids.map(
            (locationId: any, index: number) => {
              props.drivers.map((driver: any) => {
                if (driver.assigned_locations.includes(locationId)) {
                  if (
                    locationDrivers.filter(
                      (locDriver: any) => locDriver.id === driver.id
                    ).length === 0
                  ) {
                    // Check if driver already exists - TODO: revisit
                    locationDrivers.push(driver);
                  }
                }
              });
            }
          );
          setDrivers(locationDrivers);
        }
      }
    }
  }, [props.drivers, props.selectedBranch]);

  return (
    <div className="driver-filter">
      <label>
        <FormattedMessage id="logistic.assign_rider" />:
      </label>
      <Dropdown
        className="drivers-dropdown"
        show={show}
        onToggle={(e: any) => onDropdownToggle(e)}
      >
        <Dropdown.Toggle variant="" id="" className="dropdown-icon">
          <span>
            {props.selectedRider?.name || <FormattedMessage id="driver.select_driver" />}
          </span>
          <FontAwesomeIcon icon={faChevronDown} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="drivers-list">
          {drivers.map((driver: any, index: number) => {
            return (
              <div key={index}>
                <Dropdown.Item
                  className={props.theme == "dark" ? "driver-name driver-name-dark" : "driver-name"}
                  onClick={() => props.setSelectedRider(driver)}
                >
                  {driver.name}
                </Dropdown.Item>
              </div>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let drivers = state.drivers.company_drivers || [];
  let selectedBranch = state.company?.selected_branch;
  let company = state.company.company_details;
  let theme = state.theme

  return { drivers, selectedBranch, company, theme };
};

export default connect(mapStateToProps)(FilterDropdown);