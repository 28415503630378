import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { driverOperations } from "../../state/features/drivers";
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";
import RiderMapContainer from "../riderMapContainer";
import MarkerPinSlashedIcon from "../../images/marker-pin-slash.svg";
import MapErrorBoundary from "../mapErrorBoundary";
import { useAppStateSelector } from "../../hooks/useAppStateSelector";

export type CoordinateLiteral = { lat: number; lng: number };

interface iRiderTrackingProps {
  order: any;
  riderDetails?: any;
  locationId?: any;
  locations?: any;
  fetchRiderCoordinates?: any;
  companyRiders: any;
}

const RiderTracking = (props: iRiderTrackingProps) => {
  const [riderDetails, setRiderDetails] = useState({});
  const [riderCoords, setRiderCoords] = useState<any>(null);
  const [locationCoords, setLocationCoords] = useState<any>(null);
  const [locationName, setLocationName] = useState<string>("");
  const [riderLastGeoUpdate, setRiderLastGeoUpdate] = useState(Date.now());

  const { theme } = useAppStateSelector((state) => state);

  const rider = useMemo(() => {
    let isAssignedToOrder = Boolean(props.order.rider_id);
    let isRiderMissingFromCompany = !Boolean(
      props.companyRiders.find((r) => r.id === props.order.rider_id)
    );
    return { isAssignedToOrder, isRiderMissingFromCompany };
  }, [props.order, props.companyRiders]);

  const fetchRiderLocation = () => {
    props
      .fetchRiderCoordinates(props.riderDetails.id)
      .then((res: any) => {
        let data = res?.payload?.data;
        let isRiderCoordsPresent =
          data &&
          Object.entries(data).filter(
            (entry) => ["lat", "long"].includes(entry[0]) && Boolean(entry[1])
          ).length === 2;
        if (!isRiderCoordsPresent) {
          console.log("Could not retrieve rider coordinates");
        }
        if (isRiderCoordsPresent) {
          setRiderCoords({
            lat: parseFloat(data.lat),
            lng: parseFloat(data.long),
          });
          setRiderLastGeoUpdate(data.last_geo_updated_at);
        }
      })
      .catch((err: AxiosErrorResponse) => {
        console.log("Could not fetch rider coordinates", err);
      });
  };

  useEffect(() => {
    if (props.riderDetails?.id) {
      setRiderDetails(props.riderDetails);
      fetchRiderLocation();
    }
    if (props.locationId && props.locations.length) {
      let locationData = props.locations.find(
        (loc: any) => loc.id === props.locationId
      );
      setLocationName(locationData.name);
      let isLocationCoordsPresent =
        Object.entries(locationData).filter(
          (entry) =>
            ["latitude", "longitude"].includes(entry[0]) && Boolean(entry[1])
        ).length === 2;
      if (!isLocationCoordsPresent) {
        console.log("Could not retrieve location coordinates");
      }
      if (isLocationCoordsPresent) {
        setLocationCoords({
          lat: locationData.latitude,
          lng: locationData.longitude,
        });
      }
    }
  }, []);

  return (
    <div>
      <div className="rider-tracking-container">
        {locationCoords ? (
          rider.isAssignedToOrder && rider.isRiderMissingFromCompany ? (
            <div className={`google-maps-container coordinates-error ${theme}`}>
              <div className="error-help-section">
                <p className="error-help-title">
                  <FormattedMessage
                    id="rider.store_location_coordinates_missing.title"
                    defaultMessage="Rider Tracking Not Available"
                  />
                </p>
                <p className="error-help-description">
                  <FormattedMessage
                    id="rider.missing_rider_tracking_error"
                    defaultMessage="The rider assigned to this order was deleted by your account’s administrator. {break} Please contact your account administrator."
                    values={{
                      break: <br />,
                    }}
                  />
                </p>
              </div>
            </div>
          ) : (
            <MapErrorBoundary>
              <RiderMapContainer
                order={props.order}
                userCoords={locationCoords}
                riderCoords={riderCoords}
                riderDetails={riderDetails}
                riderLastGeoUpdate={riderLastGeoUpdate}
                fetchRiderLocation={fetchRiderLocation}
              />
            </MapErrorBoundary>
          )
        ) : (
          <div className="google-maps-container coordinates-error">
            <div className="error-help-section">
              <div className="icon-wrapper">
                <img
                  src={MarkerPinSlashedIcon}
                  alt="marker-slashed"
                  className="icon"
                />
              </div>
              <p className="error-help-title">
                <FormattedMessage
                  id="rider.store_location_coordinates_missing.title"
                  defaultMessage="Rider Tracking Not Available"
                />
              </p>
              <p className="error-help-description">
                <FormattedMessage
                  id="rider.store_location_coordinates_missing.sub_heading"
                  defaultMessage="Sorry, Rider Tracking is not available for {locationName}.
                  To enable, please follow these steps:"
                  values={{ locationName: <b>{locationName}</b> }}
                />
              </p>
              <ol className="error-help-description list">
                <FormattedMessage
                  id="rider.store_location_coordinates_missing.instruction_step_1"
                  defaultMessage="Go to {posSettingBreadCrumb}"
                  tagName="li"
                  values={{
                    posSettingBreadCrumb: (
                      <FormattedMessage
                        id="rider.store_location_coordinates_missing.instruction_step_1.setting_path"
                        defaultMessage="Setup >  Locations > {locationName}"
                        tagName="code"
                        values={{ locationName }}
                      />
                    ),
                  }}
                />
                <FormattedMessage
                  id="rider.store_location_coordinates_missing.instruction_step_2"
                  defaultMessage="Select {posSettingBreadCrumb}"
                  tagName="li"
                  values={{
                    posSettingBreadCrumb: (
                      <FormattedMessage
                        id="rider.store_location_coordinates_missing.instruction_step_2.setting_path"
                        defaultMessage="General Details"
                        tagName="code"
                      />
                    ),
                  }}
                />
                <FormattedMessage
                  id="rider.store_location_coordinates_missing.instruction_step_3"
                  defaultMessage="Use the search box or drop a pin to mark your exact branch location on the map."
                  tagName="li"
                />
              </ol>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  let locations = state.branches;
  let companyRiders = state.drivers.company_drivers;

  return { locations, companyRiders };
};

const mapDispatchToProps = {
  fetchRiderCoordinates: driverOperations.fetchRiderCoordinates,
};

export default connect(mapStateToProps, mapDispatchToProps)(RiderTracking);
