import * as types from "./types";

const cancelReasonsReducer = (state = [], action: any) => {
  switch (action.type) {
    case types.FETCH_CANCEL_REASONS_SUCCESS:
      return action.payload.data || [];

    default:
      return state;
  }
};

const reducer = cancelReasonsReducer;

export default reducer;
