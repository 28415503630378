import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { formatDecimal } from "../../../helpers/itemCalculations";

interface iPaymentDetailsProps {
  payments: any;
  dmCanRecordPayment: boolean;
  isOrderDelivered: boolean;
  totalAmount: any;
}

function PaymentDetails(props: iPaymentDetailsProps) {
  const [payments, setPayments] = useState([] as any);

  const getReturnedAmount = (amount: any, amountCollected: any) => {
    let balance = amountCollected - amount;
    if (balance > 0) {
      return formatDecimal(balance);
    } else {
      return formatDecimal(0);
    }
  };

  useEffect(() => {
    setPayments(props.payments);
  }, [props.payments]);

  useEffect(() => {
    // When Record payment is off and Order is delivered showing Cash as default payment
    if (props.isOrderDelivered && !props.dmCanRecordPayment) {
      let new_payment: any = {
        id: 1,
        name: "Cash",
        amount: props.totalAmount,
      };
      if (props.payments.length === 0) {
        setPayments([new_payment]);
      } else {
        if (
          props.payments.length === 1 &&
          props.payments[0].name === "Loyalty" &&
          props.totalAmount != 0
        ) {
          setPayments([
            ...props.payments,
            {
              ...new_payment,
              amount: props.totalAmount,
            },
          ]);
        }
      }
    }
  }, [props.dmCanRecordPayment, props.payments, props.totalAmount]);

  return (
    <div className="order-status-history mt-3">
      <div className="order-history">
        <p>
          <FormattedMessage id="payment" />
        </p>
        <div className="order-status-history-table">
          <div className="header">
            <div className="tableRow">
              <div className="header-item">
                <FormattedMessage id="payment_by" />
              </div>
              <div className="header-item text-right">
                <FormattedMessage id="order.amount" />
              </div>
              <div className="header-item text-right">
                <FormattedMessage id="tab.collected" />
              </div>
              <div className="header-item text-right">
                <FormattedMessage id="payment_returned" />
              </div>
              <div className="header-item text-right">
                <FormattedMessage id="payment_tips" />
              </div>
            </div>
          </div>
          {payments.length > 0 ? (
            <div className="content-wrapper">
              {payments.map((payment: any, index: any) => {
                return (
                  <div key={index} className="content-row">
                    <div className="content-item">{payment.name}</div>
                    <div className="content-item text-right">
                      {formatDecimal(payment.amount)}
                    </div>
                    <div className="content-item text-right">
                      {payment.amount_collected
                        ? formatDecimal(payment.amount_collected)
                        : formatDecimal(payment.amount)}
                    </div>
                    <div className="content-item text-right">
                      {payment.amount_collected
                        ? getReturnedAmount(
                            payment.amount,
                            payment.amount_collected
                          )
                        : formatDecimal(0)}
                    </div>
                    <div className="content-item text-right">
                      {payment.tip
                        ? formatDecimal(payment.tip)
                        : formatDecimal(0)}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="no-modifications">
              <FormattedMessage id="payment_no_payments" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PaymentDetails;
