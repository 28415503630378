import * as types from "./types";
const fetchCancelReasons = () => ({
  type: types.FETCH_CANCEL_REASONS,
  payload: {
    request: {
      method: "get",
      url: "/api/v1/cancellation-reasons/list",
    },
  },
});

export default {
  fetchCancelReasons,
};
