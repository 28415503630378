import * as types from "./types";

const fetchReprints = (orderId: any) => ({
  type: types.FETCH_REPRINTS,
  payload: {
    request: {
      method: "get",
      url: `/api/v1/orders/${orderId}/print-templates`,
    },
  },
});

const resetReprints = () => ({
  type: types.RESET_REPRINTS,
  payload: [],
});

const markOrderReprinted = (orderId: any) => ({
  type: types.SET_ORDER_MARK_AS_REPRINTED,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/${orderId}/mark-as-reprinted`,
    },
  },
  param: { order_id: orderId },
});

export default {
  fetchReprints,
  resetReprints,
  markOrderReprinted,
};
