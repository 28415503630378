import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CoordinateLiteral } from "../riderTracking";
import { FormattedMessage } from "react-intl";
import { faMapPin } from "@fortawesome/free-solid-svg-icons";
import motorCycleIcon from "./icons/motorcycle-icon-pin.svg";
import Loader from "./icons/loader";
import Refresh from "./icons/refresh";
import { getEnvValueFor } from "../../helpers/utils";

interface IRiderMapContainerProps {
  order: any;
  userCoords: CoordinateLiteral;
  riderCoords: CoordinateLiteral;
  riderDetails: any;
  riderLastGeoUpdate: any;
  fetchRiderLocation: any;
}

const RiderMapContainer = (props: IRiderMapContainerProps) => {
  const [directions, setDirections] = useState<google.maps.DirectionsResult>();
  const [riderEta, setRiderEta] = useState<string | undefined>(undefined);
  const [showInactiveBanner, setShowInactiveBanner] = useState(false);
  const [refeshLoading, setRefreshLoading] = useState(false);

  const { isLoaded: isMapLoaded, loadError: mapLoadError } = useJsApiLoader({
    googleMapsApiKey: getEnvValueFor("GOOGLE_MAPS_API_KEY") as string,
  });

  const debouncedClick = useRef(false);

  const mapRef = useRef<GoogleMap>();
  const onMapLoad = useCallback((map) => (mapRef.current = map), []);
  const mapOptions = useMemo<google.maps.MapOptions>(
    () => ({
      disableDefaultUI: true,
      clickableIcons: false,
    }),
    []
  );
  const directionsOptions = useMemo<google.maps.DirectionsRendererOptions>(
    () => ({
      markerOptions: { clickable: false },
      suppressMarkers: true,
    }),
    []
  );

  useEffect(() => {
    if (elapsedTime() > 5) {
      setShowInactiveBanner(true);
    } else {
      setShowInactiveBanner(false);
    }
  }, [props.riderLastGeoUpdate]);

  function elapsedTime() {
    let currentTime = Date.now();

    return (currentTime - props.riderLastGeoUpdate) / (1000 * 60);
  }

  useEffect(() => {
    if (mapLoadError) {
      throw new Error("Map Load error");
    }
  }, [mapLoadError]);

  useEffect(() => {
    if (isMapLoaded && props.riderCoords) {
      calculateRoute(props.riderCoords, props.userCoords);
    }
  }, [props.riderCoords, isMapLoaded]);

  const calculateRoute = (
    origin: CoordinateLiteral,
    destination: CoordinateLiteral
  ) => {
    const service = new google.maps.DirectionsService();
    service.route(
      {
        origin,
        destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status == "OK" && result) {
          setDirections(result);
          setRiderEta(result.routes[0].legs[0].duration?.text);
        }
      }
    );
  };

  const refreshMap = () => {
    if (!debouncedClick.current) {
      debouncedClick.current = true;
      setRefreshLoading(true);
      setTimeout(() => {
        props.fetchRiderLocation();
        if (elapsedTime() > 5) {
          setShowInactiveBanner(true);
        }
        setRefreshLoading(false);
      }, 1000);
      setTimeout(() => {
        debouncedClick.current = false;
      }, 3000);
    }
  };

  return isMapLoaded ? (
    <GoogleMap
      center={props.userCoords}
      zoom={15}
      mapContainerClassName={`google-maps-container ${
        refeshLoading ? "google_map_loading" : ""
      }`}
      options={mapOptions}
      onLoad={onMapLoad}>
      {showInactiveBanner ? (
        <div className="inactive-banner">
          <span>
            <FormattedMessage id="rider.currently_inactive" />
          </span>
          <span className="rider-inactive-time">
            {Math.round(elapsedTime())} mins ago
          </span>
        </div>
      ) : null}
      <Marker
        position={props.userCoords}
        icon={{
          path: faMapPin.icon[4] as string,
          fillColor: "#D11934",
          fillOpacity: 1,
          anchor: isMapLoaded
            ? new google.maps.Point(faMapPin.icon[0] / 2, faMapPin.icon[0])
            : null,
          strokeWeight: 2,
          strokeColor: "#ffffff",
          scale: 0.05,
        }}
      />
      {props.riderCoords ? (
        <Marker
          position={props.riderCoords}
          icon={{
            url: motorCycleIcon,
            scaledSize: isMapLoaded ? new google.maps.Size(30, 30) : null,
          }}
        />
      ) : null}
      {props.order.order_status?.includes("in_progress") ? (
        <div className="rider-details-section">
          {isMapLoaded ? (
            <span className="rider-map-refresh" onClick={() => refreshMap()}>
              {refeshLoading ? <Loader /> : <Refresh />}
            </span>
          ) : null}
          <p className="rider-eta-info rider-eta-header">
            <FormattedMessage id="logistic.del_inprogress" />
          </p>
          <p className="rider-details">
            <FormattedMessage id="logistics.popup_in_progress_tab_message" />{" "}
            {riderEta}
          </p>
        </div>
      ) : (
        <div className="rider-details-section">
          {isMapLoaded ? (
            <span className="rider-map-refresh" onClick={() => refreshMap()}>
              {refeshLoading ? <Loader /> : <Refresh />}
            </span>
          ) : null}
          <p className="rider-eta-info">
            <FormattedMessage id="rider.text" /> {riderEta}
          </p>
          <p className="rider-details rider-details-capitalize">
            <FormattedMessage id="rider.name" /> : {props.riderDetails.name}
          </p>
        </div>
      )}
      {directions && (
        <DirectionsRenderer
          directions={directions}
          options={directionsOptions}
        />
      )}
    </GoogleMap>
  ) : null;
};

export default RiderMapContainer;
