import React from "react";
import { FormattedMessage } from "react-intl";

const getWhatModifiedString = (item: any, modification: any) => {
  let whatModifiedString: any = "";
  if (
    modification.modification_type === "quantity_increase" ||
    modification.modification_type === "quantity_decrease"
  ) {
    whatModifiedString = (
      <FormattedMessage
        id="modify.string"
        values={{
          oldValue: Math.trunc(modification.old_value),
          newValue: Math.trunc(modification.new_value),
        }}
      />
    );
  }

  if (modification.modification_type === "delete") {
    whatModifiedString = (
      <FormattedMessage
        id="delete.string"
        values={{
          itemName: modification.item_name,
        }}
      />
    );
  }
  return whatModifiedString;
};

export { getWhatModifiedString };
