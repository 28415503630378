const calculateSubtotal = (items: any) => {
  let subTotal = 0;
  items.map((item: any) => {
    if (item.modifiers.length) {
      subTotal = subTotal + modifierItemSubtotal(item);
    } else {
      subTotal = subTotal + item.amount;
    }
  });
  return formatDecimal(subTotal);
};

const calculateTax = (items:any,surcharges:any) =>{
  let tax = 0 ;
  items.map((item:any) => {
    tax = tax + item.tax
  })
  if(surcharges){
    surcharges.map((surcharge:any) =>{
      tax = tax + surcharge.tax_amount
    })
  }
  return tax
}

const modifierItemSubtotal = (item: any) => {
  let amount = item.amount;
  item.modifiers.map((modifier: any) => {
    amount += modifier.amount;
  });
  return amount;
};

const formatDecimal = (
  number: any,
  precision: any = parseInt(localStorage.company_currency_precision)
) => {

  if (!precision) {
    precision = 2;
  }

  number = parseFloat(parseFloat(number).toFixed(7));

  let rounder = Math.pow(10, precision);

  return (Math.round(number * rounder) / rounder).toFixed(precision) as any;
};

export { calculateSubtotal, formatDecimal, modifierItemSubtotal, calculateTax };
