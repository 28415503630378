import * as branchTypes from "../branches/types"
import * as types from './types'
import { combineReducers } from "redux";

const selectedBranchReducer = (state = {}, action: any) => {
  switch (action.type) {
    case branchTypes.SELECT_BRANCH:
      return action.payload

    default:
      return state;
  }
};

const companyDetailsReducer = (state = { refresh_required: false }, action: any) => {
  switch (action.type) {
  case types.FETCH_COMPANY_SUCCESS:
    localStorage.company_currency_precision = action.payload.data?.currency_precision;
    return { ...action.payload?.data, refresh_required: false };
    default:
      return state;
    }
}

const reducer = combineReducers({
  selected_branch: selectedBranchReducer,
  company_details: companyDetailsReducer,
});

export default reducer;
