import * as orderTypes from "../homeDelivery/types";
import * as types from "./types"
import * as companyTypes from "../company/types";
import * as pickupOrderTypes from '../pickup/types'

const loaderReducer = (state = {}, action: any) => {
  switch(action.type){

    // case orderTypes.FETCH_UNCONFIRMED_ORDERS:
    // case orderTypes.FETCH_NEW_ORDERS:
    // case orderTypes.FETCH_IN_PROGRESS_ORDERS:
    // case orderTypes.FETCH_READY_ORDERS:
    // case orderTypes.FETCH_RIDER_STATS:
    // case companyTypes.FETCH_COMPANY:
    //   return { loading: true };
    case orderTypes.FETCH_UNCONFIRMED_ORDERS_SUCCESS:
    case orderTypes.FETCH_UNCONFIRMED_ORDERS_FAIL:
    case orderTypes.FETCH_NEW_ORDERS_SUCCESS:
    case orderTypes.FETCH_NEW_ORDERS_FAIL:
    case orderTypes.FETCH_READY_ORDERS_SUCCESS:
    case orderTypes.FETCH_READY_ORDERS_FAIL:
    case orderTypes.FETCH_IN_PROGRESS_ORDERS_SUCCESS:
    case orderTypes.FETCH_IN_PROGRESS_ORDERS_FAIL:
    case orderTypes.FETCH_RIDER_STATS_SUCCESS:
    case orderTypes.FETCH_RIDER_STATS_FAIL:
    case companyTypes.FETCH_COMPANY_SUCCESS:
    case companyTypes.FETCH_COMPANY_FAIL:
      return { loading: false };
    case types.UPDATE_LOADER:
      return action.payload;
    case types.RESET_LOADER:
      return null;
    default:
      return state;
  }
}

const reducer = loaderReducer;

export default reducer;
