import React from "react";
import { useRef } from "react";

interface Params {
  /**how long to delay further calls (in milliseconds) */
  interval?: number;
}

function useDebouncer({ interval = 3000 }: Params) {
  const isDebounced = useRef(false);
  const debounceFn = (callback: any) => {
    if (!isDebounced.current) {
      callback();
      isDebounced.current = true;
      setTimeout(() => {
        isDebounced.current = false;
      }, interval);
    }
  };
  return { debounceFn };
}

export { useDebouncer };
