import actions from "./actions";

const fetchReprints = actions.fetchReprints;
const resetReprints = actions.resetReprints;
const markOrderReprinted = actions.markOrderReprinted;

export default {
  fetchReprints,
  resetReprints,
  markOrderReprinted,
};
