import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { themeOperations } from "../../state/features/theme";

function DarkMode() {
  const body = document.body;
  const lightTheme = "light";
  const darkTheme = "dark";
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const dispatch = useDispatch();

  useEffect(() => {
    let savedTheme: any = localStorage.getItem("theme");
    if ([lightTheme, darkTheme].includes(savedTheme)) {
      dispatch(themeOperations.setAppTheme(savedTheme));
    }
  }, []);

  if (theme === lightTheme || theme === darkTheme) {
    body.classList.add(theme);
  } else {
    body.classList.add(lightTheme);
  }

  const switchTheme = (e: any) => {
    if (theme === darkTheme) {
      body.classList.replace(darkTheme, lightTheme);
      localStorage.setItem("theme", "light");
      dispatch(themeOperations.setAppTheme(lightTheme));
      setTheme(lightTheme);
    } else {
      body.classList.replace(lightTheme, darkTheme);
      localStorage.setItem("theme", "dark");
      dispatch(themeOperations.setAppTheme(darkTheme));
      setTheme(darkTheme);
    }
  };

  return (
    <div className="theme-toggler" onClick={(e: any) => switchTheme(e)}>
      <div
        className={theme === lightTheme ? "light icon active" : "light icon"}
      >
        <span>☀</span>
      </div>
      <div className={theme === darkTheme ? "dark icon active" : "dark icon"}>
        <span>☾</span>
      </div>
    </div>
  );
}

export default DarkMode;
