import React from "react";
import {
  faBiking,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import logisticOrderStatus from "../../helpers/logisticOrderStatus";

interface IOrderLogisticsControlProps {
  order: any;
  isPartnerAssignmentPending: boolean;
  logisticsConfigs: any;
  onClickAction(): any;
  isRiderAppEnabled: boolean | undefined;
  riderName: string;
  orderType: string;
  orderStatus: string;
}

const OrderLogisticsControl = ({
  order,
  isPartnerAssignmentPending,
  logisticsConfigs,
  onClickAction: handleOnClickAction,
  isRiderAppEnabled,
  riderName,
  orderType,
  orderStatus,
}: IOrderLogisticsControlProps) => {
  if (order?.order_extended_info?.take_away) {
    return null;
  }
  // If rider app is enabled and rider is assigned to order
  const ownRiderAssignedState =
    isRiderAppEnabled &&
    Boolean(order.rider_id) &&
    ((order.logistics_job && order.logistics_job?.status === 3) ||
      !order.logistics_job);
  const partnerRiderAssignedState =
    isRiderAppEnabled &&
    Boolean(order.rider_id) &&
    order.logistics_job &&
    order.logistics_job?.status !== 3;

  if (partnerRiderAssignedState) {
    let isRiderAssignedState = Boolean(order.rider_id);
    let isItemsPurchasedState =
      order.logistics_job && order.logistics_job?.status !== 3;
    return (
      <div>
        <button
          className={`px-2 py-3 delivery-partner-button ${
            isItemsPurchasedState
              ? logisticOrderStatus[order.logistics_job.status].class
              : isRiderAssignedState
              ? "rider_assigned"
              : ""
          } ${!riderName ? "rider_not_found" : ""}`}
          onClick={handleOnClickAction}
        >
          <p>
            {logisticOrderStatus[order.logistics_job.status].btn_icon ? (
              <FontAwesomeIcon
                icon={logisticOrderStatus[order.logistics_job.status].btn_icon}
              />
            ) : null}
            {logisticOrderStatus[order.logistics_job.status].btn_text}
          </p>
        </button>
      </div>
    );
  }

  if(ownRiderAssignedState){
    let isRiderAssignedState = Boolean(order.rider_id);
    let isItemsPurchasedState =
      order.logistics_job && order.logistics_job?.status !== 3;
    return (
      <div>
        <button
          className={`px-2 py-3 delivery-partner-button ${
            isItemsPurchasedState
              ? logisticOrderStatus[order.logistics_job.status].class
              : isRiderAssignedState
              ? "rider_assigned"
              : ""
          } ${!riderName ? "rider_not_found" : ""}`}
          onClick={handleOnClickAction}
        >
          {isRiderAssignedState ? (
            <p>
              {riderName ? (
                <FormattedMessage
                  id="rider.assignedTo"
                  values={{
                    ridername: (
                      <b style={{ textTransform: "capitalize" }}>{riderName}</b>
                    ),
                  }}
                />
              ) : (
                <>
                  <FontAwesomeIcon
                    className="warning-icon"
                    icon={faExclamationTriangle}
                  />
                  <FormattedMessage
                    id="rider.riderNotFound"
                    defaultMessage="Rider Not Found"
                  />
                </>
              )}
            </p>
          ) : null}
        </button>
      </div>
    );
  }

  if (isPartnerAssignmentPending) {
    return (
      <div>
        <button
          className={`px-2 py-3 delivery-partner-button please-wait`}
          onClick={handleOnClickAction}
        >
          <FormattedMessage id="logistic.please_wait" />
        </button>
      </div>
    );
  }

  if (logisticsConfigs.logisticsPartnerAssigned) {
    return (
      <div>
        <button
          className={`px-2 py-3 delivery-partner-button ${
            logisticOrderStatus[order.logistics_job.status].class
          }`}
          onClick={handleOnClickAction}
        >
          {logisticOrderStatus[order.logistics_job.status].btn_icon ? (
            <FontAwesomeIcon
              icon={logisticOrderStatus[order.logistics_job.status].btn_icon}
            />
          ) : null}
          {logisticOrderStatus[order.logistics_job.status].btn_text}
        </button>
      </div>
    );
  }

  if (
    (logisticsConfigs.orderLogisticsPartners.length > 0 &&
      logisticsConfigs.skipInactiveLogisticsJobs) ||
    (isRiderAppEnabled && orderType !== "scheduled")
  ) {
    return (
      <div>
        <button
          className={`px-2 py-3 delivery-partner-button ${
            orderStatus == "ready" && isRiderAppEnabled
              ? "waiting-for-rider-logistics"
              : ""
          }`}
          onClick={handleOnClickAction}
        >
          <FontAwesomeIcon icon={faBiking} />
          {orderType !== "scheduled" ? (
            isRiderAppEnabled ? (
              <FormattedMessage id="logistic.assign_rider" />
            ) : (
              <FormattedMessage id="logistic.assign_del_partner" />
            )
          ) : (
            <FormattedMessage id="logistic.assign_del_partner" />
          )}
        </button>
      </div>
    );
  }
  return null;
};

export default OrderLogisticsControl;
