import React from "react";
import { useRef } from "react";
import { CSSTransition } from "react-transition-group";

const AnimateDeliveryAreaTransition = ({ show, component }) => {
  const nodeRef = useRef();
  return (
    <CSSTransition
      in={show}
      nodeRef={nodeRef}
      timeout={500}
      classNames="order-delivery-area-animate"
      unmountOnExit>
      {component({ areaRef: nodeRef })}
    </CSSTransition>
  );
};

export { AnimateDeliveryAreaTransition };
