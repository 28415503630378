import * as types from "./types";

const fetchPrintSettings = () => ({
  type: types.FETCH_PRINT_SETTINGS,
  payload: {
    request: {
      method: "get",
      url: "/api/v1/print-settings/list",
    },
  },
});

export default {
  fetchPrintSettings,
};
