import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

interface IBrowserCompatibilityProps {
  children?: any;
}

const cookieDisabledScreenStyles: any = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "40vh",
  },
  cookieIcon: {
    width: "100%",
    textAlign: "center",
  },
  cookieDisabledText: {
    paddingLeft: "30vw",
    paddingRight: "30vw",
    textAlign: "center",
    marginTop: "1rem",
  },
};

function BrowserCompatibility(props: IBrowserCompatibilityProps) {
  const [cookieEnabled, setCookieEnabled] = useState(false);

  useEffect(() => {
    setCookieEnabled(() => window.navigator.cookieEnabled);
  }, []);

  return cookieEnabled ? (
    props.children
  ) : (
    <div style={cookieDisabledScreenStyles.container}>
      <div style={cookieDisabledScreenStyles.cookieIcon}>
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          width="90px"
          height="90px"
          viewBox="0 0 200 200"
        >
          <g id="iQwClr.tif">
            <g>
              <g>
                <path
                  d="M107.2,197.4c-3.9-1-7.4-2.7-10.1-6c-0.3-0.4-1.1-0.5-1.7-0.5c-21.6-1.2-41-8.5-57.7-22.3C20.2,153.9,9,135.4,4.6,112.9
				C-1,83.9,5.4,57.5,23.2,34c10.6-14,24.4-24,40.7-30.6c7-2.8,13.6,1.7,13.7,9.2c0.2,11.4,7.2,20.4,18.2,22.9
				c3.2,0.7,6.4,0.7,9.6,0c3-0.7,4.4,0.2,5.1,3.2c3.4,13.8,12.2,22.1,26,25c6,1.3,12,0.5,17.7-1.8c0.8-0.3,1.5-0.7,2.3-0.9
				c2-0.5,3.4,0.5,4.3,3c3.4,9.4,9.9,15.5,19.5,18.3c1.7,0.5,3.6,1,5.4,1c5.8-0.2,9.7,2.2,11.7,7.7c0,3,0,6.1,0,9.1
				c-0.3,1.9-0.7,3.8-0.7,5.8c0,1.5,0.5,3,0.7,4.5c0,24.6,0,49.2,0,73.8c-0.1,0.3-0.3,0.6-0.4,1c-1.2,4.2-3.5,7.6-7.2,9.8
				c-1.8,1-3.8,1.7-5.7,2.5C158.5,197.4,132.9,197.4,107.2,197.4z M155.5,68.7c-3.3,0.7-6.4,1.5-9.5,1.9
				c-17.7,1.9-34.8-8.9-40.5-25.8c-0.7-2-1.5-2.4-3.4-2.3c-12.1,0.4-21.2-4.8-27.3-15.3c-2.5-4.3-3.6-9.1-3.6-14.2
				c0-3.2-2-4.6-5-3.4c-1.6,0.7-3.2,1.4-4.8,2.1c2.2,2.9,3.1,6,3.4,9.3c0.7,6.4,0,12.8-0.9,19.1c-0.8,5.7-3.5,10.4-9.6,11.8
				c-2.3,0.5-5,0.5-7.3,0.1c-8-1.6-16.3-2.3-24-6.1C9.2,69.2,5.5,93.6,13,119.3c6.8,23.3,21,40.8,42,53.1
				c2.4-8.5,7.3-15.3,12.5-21.9c2.1-2.6,4.9-4,8.3-3.8c3.8,0.3,7.5,0.8,11.3,1.4c2.1,0.3,4.2,0.9,6.5,1.4c0-1,0-1.7,0-2.4
				c0-11.2-0.1-22.3,0.1-33.5c0-2.5,0.4-5.4,1.6-7.5c2-3.4,2.7-7,3.7-10.6c0.5-2,1-3.9,1.6-5.9c1.1-3.6,3.4-6.3,7-7.2
				c7.4-1.7,14.8-3.1,22.3-4.6c4.3-0.8,7.7,0.7,10,4.5c2.5,4.2,5.1,8.3,7.5,12.5c0.6,1.1,1.2,1.6,2.6,1.6c8.7-0.1,17.5,0,26.2,0
				c5,0,10-0.2,14.5,3.1c0.1-1.5,0.3-2.8,0.3-4c0.2-5-0.4-5.6-5.3-5.9c-4.5-0.2-8.7-1.4-12.8-3.3C164.8,82.7,159.1,76.6,155.5,68.7z
				 M145.5,103.3c-11.7,0-23.3,0.1-35-0.1c-6-0.1-10.6,4.3-10.5,10.6c0.1,22.1,0,44.2,0,66.3c0,6.6,4.1,10.6,10.7,10.6
				c23.1,0,46.2,0,69.4,0c6.7,0,10.8-4,10.8-10.7c0-22,0-44.1,0-66.1c0-6.5-4.6-10.8-10.7-10.7C168.7,103.5,157.1,103.3,145.5,103.3
				z M93.5,184.3c0-9,0-17.5,0-26c0-1.5-0.6-2.2-1.9-2.4c-5.1-0.9-10.2-1.8-15.3-2.7c-1.8-0.3-3.2,0.4-4.3,1.9
				c-2.2,3.2-4.6,6.4-6.6,9.7c-1.4,2.4-2.6,4.9-3.6,7.5c-0.9,2.4-0.4,3.6,2,4.4c5.4,1.9,10.7,3.8,16.2,5.2
				C84.3,183.2,88.9,183.6,93.5,184.3z M26.6,40.5c0.7,0.3,1.2,0.7,1.8,0.8c7,1.6,14,3.4,21.1,4.6c4.5,0.7,7-1.2,7.5-5.7
				c0.7-6.1,1-12.2,1.1-18.3c0-1.5-0.7-3.2-1.4-4.7c-1-1.9-1.8-2-3.6-0.9c-0.8,0.5-1.5,0.9-2.3,1.4C41.4,23.7,33.3,31.3,26.6,40.5z
				 M140.2,96.9c0.2-0.3,0.3-0.5,0.5-0.8c-2.3-3.4-4.6-6.7-6.8-10.2c-0.9-1.3-1.9-1.7-3.4-1.4c-6.8,1.4-13.6,2.7-20.4,4.1
				c-1.1,0.2-2.4,1-2.8,2c-1,2.1-1.4,4.3-2,6.3C117.1,96.9,128.7,96.9,140.2,96.9z"
                />
                <path
                  d="M94.4,80.5c0.2,2.7-0.6,6-2.5,8.8c-2.5,3.7-5.1,7.4-7.9,10.9c-3.1,4-7.2,5.7-12.2,4.6c-5.2-1.2-10.4-2.4-15.6-3.9
				c-1.3-0.4-2.5-1.6-3.3-2.7c-4.2-5.7-6.8-12.1-8.6-18.9c-0.6-2.3,0.5-4.1,2-5.7c3.2-3.3,6.4-6.5,9.7-9.6c1.4-1.4,2.9-2.6,4.4-3.9
				c2.7-2.3,5.9-3.1,9.2-1.8c5.9,2.3,11.8,4.6,17.4,7.5C92.4,68.8,94.4,73.8,94.4,80.5z M88.2,79.9c-0.3-4.4-1.8-7.1-5.7-8.9
				c-4.8-2.2-9.6-4.2-14.5-6.1c-1-0.4-2.7-0.2-3.4,0.5c-4.4,4-8.7,8.1-12.9,12.3c-0.5,0.5-0.8,1.7-0.5,2.3c2,4.6,4.2,9.2,6.4,13.7
				c0.3,0.7,1.2,1.3,2,1.5c4.3,1.1,8.5,2.2,12.8,3.2c2.5,0.6,4.8,0.1,6.4-2.1c2.5-3.5,5.2-6.9,7.4-10.5
				C87.3,84.1,87.6,81.9,88.2,79.9z"
                />
                <path
                  d="M41,109.9c6.5,0.9,13.5,2.2,19,7.6c3.3,3.2,4.6,6.8,2.4,11.1c-2.3,4.3-4.7,8.5-7.3,12.6c-2.3,3.5-6,3.4-9.4,2.5
				c-4.4-1.2-8.8-2.7-13-4.5c-3.8-1.6-4.7-4-3.9-8c1.1-5.1,2.2-10.1,3.4-15.1C33.3,111.6,35.7,109.9,41,109.9z M38.8,116.5
				c-1.3,5.6-2.6,11.1-3.9,16.5c4.5,2,8.7,3.8,13.4,4.3c1.2,0.1,2-0.2,2.5-1.2c2-3.6,3.9-7.2,5.8-10.8c0.5-1,0.2-1.7-0.5-2.5
				C51.4,117.9,45.3,117,38.8,116.5z"
                />
                <path
                  d="M116.3,155.2c-5.5,0-9.8-4.3-9.8-9.7c0-5.4,4.3-9.7,9.7-9.8c5.5,0,9.8,4.2,9.8,9.7C126,150.9,121.7,155.2,116.3,155.2z
				 M116.2,148.7c1.6,0,3.1-1.5,3.2-3.1c0.1-1.7-1.4-3.3-3.2-3.3c-1.7,0-3.1,1.4-3.2,3.1C113,147,114.5,148.7,116.2,148.7z"
                />
                <path
                  d="M126,119.5c0,5.4-4.4,9.8-9.8,9.8c-5.4,0-9.7-4.4-9.7-9.8c0-5.4,4.4-9.8,9.8-9.8C121.7,109.7,126,114.1,126,119.5z
				 M116.3,116.3c-1.8,0-3.3,1.4-3.3,3.2c0,1.7,1.4,3.1,3.1,3.1c1.8,0,3.3-1.4,3.3-3.2C119.4,117.7,118.1,116.4,116.3,116.3z"
                />
                <path
                  d="M116.2,161.6c5.4,0,9.8,4.3,9.8,9.7c0,5.4-4.3,9.8-9.7,9.8c-5.4,0-9.8-4.3-9.8-9.7C106.5,166,110.8,161.6,116.2,161.6z
				 M119.4,171.4c0-1.7-1.4-3.1-3.1-3.1c-1.7,0-3.3,1.5-3.2,3.2c0,1.7,1.5,3.1,3.2,3.1C118,174.6,119.5,173.1,119.4,171.4z"
                />
                <path
                  d="M169.7,122.8c-3.5,0-7.1,0-10.6,0c-2.4,0-3.8-1.3-3.9-3.2c0-2,1.3-3.3,3.7-3.3c7.3,0,14.5,0,21.8,0
				c2.4,0,3.8,1.4,3.7,3.4c-0.1,1.9-1.5,3.2-3.8,3.2C177,122.8,173.3,122.8,169.7,122.8z"
                />
                <path
                  d="M169.8,148.7c-3.7,0-7.3,0-11,0c-2.2,0-3.6-1.3-3.6-3.2c0-2,1.4-3.2,3.6-3.2c7.3,0,14.7,0,22,0c2.2,0,3.6,1.3,3.6,3.2
				c0,1.9-1.4,3.2-3.6,3.2C177.1,148.7,173.4,148.7,169.8,148.7z"
                />
                <path
                  d="M169.8,168.1c3.6,0,7.2,0,10.8,0c2.3,0,3.8,1.3,3.8,3.3c0,1.9-1.4,3.2-3.6,3.3c-7.3,0-14.7,0-22,0
				c-2.3,0-3.6-1.3-3.6-3.3c0-2,1.5-3.3,3.8-3.3C162.6,168.1,166.2,168.1,169.8,168.1z"
                />
                <path
                  d="M140.5,148.7c-1.6,0-3.2,0-4.7,0c-1.9-0.1-3.3-1.5-3.3-3.2c0-1.7,1.4-3.2,3.3-3.2c3.3-0.1,6.6-0.1,9.9,0
				c1.9,0,3.2,1.4,3.2,3.3c0,1.8-1.3,3.1-3.3,3.2C143.8,148.7,142.1,148.7,140.5,148.7z"
                />
                <path
                  d="M140.6,122.8c-1.6,0-3.2,0-4.7,0c-2-0.1-3.5-1.5-3.5-3.3c0-1.7,1.3-3.2,3.3-3.2c3.2-0.1,6.5-0.1,9.7,0
				c2.1,0.1,3.4,1.5,3.3,3.4c-0.1,1.8-1.4,3.1-3.5,3.2c-0.1,0-0.1,0-0.2,0C143.5,122.8,142.1,122.8,140.6,122.8z"
                />
                <path
                  d="M140.7,168.1c1.5,0,3,0,4.6,0c2.1,0.1,3.4,1.4,3.4,3.3c0,1.8-1.2,3.2-3.2,3.2c-3.3,0.1-6.6,0.1-9.9,0
				c-1.9-0.1-3.3-1.6-3.2-3.3c0.1-1.7,1.5-3.1,3.4-3.2C137.4,168.1,139.1,168.1,140.7,168.1z"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div style={cookieDisabledScreenStyles.cookieDisabledText}>
        <FormattedMessage id="browser_cookie" />
      </div>
    </div>
  );
}

export default BrowserCompatibility;
