import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import LoginError from "../../containers/loginError";
import { JWT_REFRESH_TIMER_THRESHOLD_SECONDS } from "../../helpers/constants";
import { getEnvValueFor } from "../../helpers/utils";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { authOperations } from "../../state/features/auth";
import axiosClient from "../../state/middlewares";
import { useTicker } from "./useTicker";

const AuthProvider = ({ children }: any) => {
  const [jwt, setJwt] = useLocalStorage("jwt", "");
  const [refreshToken] = useLocalStorage("rt", "");

  const { authorize, tokenExpiry, initiateTicker, isTokenExpired } =
    useSelector((state: any) => state.auth);
  const { authorizeUser, setJwtData } = authOperations;

  const decodeAuthDataFromJwt = (token: string) => {
    try {
      if (token) {
        const authData = JSON.parse(atob(token.split(".")[1]));
        console.debug(`[AUTH] Token parse success. expiry: ${authData.exp} seconds`);
        console.debug(
          `[AUTH] New token will expire on ${new Date(authData.exp * 1000)}`
        );
        setJwtData(authData);
      }
    } catch (error) {
      console.debug("[AUTH] Invalid Token");
      console.log(error);
      authorizeUser(false);
    }
  };

  const handleTokenRefresh = () => {
    console.debug("[AUTH] Refreshing JWT token");
    axiosClient.authmsApi
      .post(
        `/api/dmms/v1/tokens/refresh`,
        {
          jwt: jwt,
          refresh_token: refreshToken,
        },
        {
          baseURL: `${getEnvValueFor("AUTH_MS_BASE_URL")}`,
        }
      )
      .then((response) => {
        console.debug("[AUTH] JWT token refresh success");
        setJwt(response.data.jwt);
      })
      .catch((error) => {
        console.debug("[AUTH] JWT token refresh failed");
        console.log(error);
        authorizeUser(false);
      });
  };

  const [tickerStatus, startTicker, setTokenExpiry] = useTicker({
    thresholdValue: JWT_REFRESH_TIMER_THRESHOLD_SECONDS,
    onThresholdTrigger: handleTokenRefresh,
  });

  useEffect(() => {
    if (isTokenExpired) {
      handleTokenRefresh();
    }
  }, [isTokenExpired]);

  useEffect(() => {
    if (jwt) {
      decodeAuthDataFromJwt(jwt);
    } else {
      // If jwt is missing in localStorage
      authorizeUser(false);
    }
  }, [jwt]);

  useEffect(() => {
    if (initiateTicker && tokenExpiry) {
      setTokenExpiry(tokenExpiry); // Pass expiry value to ticker
      startTicker(true);
    }
  }, [initiateTicker, tokenExpiry]);

  return authorize ? <>{children}</> : <LoginError />;
};

export default AuthProvider;
