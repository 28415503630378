import * as types from "./types";

const fetchCompany = () => ({
  type: types.FETCH_COMPANY,
  payload: {
    request: {
      method: "get",
      url: "/api/v1/me",
    },
  },
});


export default {
  fetchCompany,
};
