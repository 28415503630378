import React from "react";
import { FormattedMessage } from "react-intl";

interface iThirdPartyDetailsProps {
  details: any;
}

function ThirdPartyDetails(props: iThirdPartyDetailsProps) {
  return (
    <div className="order-status-history mt-3 third-party-details">
      <div className="order-history">
        <p>
          <FormattedMessage id="logistic_rider_details" />
        </p>
        <div className="third-party-details-content">{props.details}</div>
      </div>
    </div>
  );
}

export default ThirdPartyDetails;
