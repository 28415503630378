import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { formatDecimal } from "../../helpers/itemCalculations";
import {
  formatTime,
  getOrderTime,
  getTime,
  updateOrderTotalAmount,
} from "../../helpers/orderFunctions";
import { homeDeliveryOperations } from "../../state/features/homeDelivery";
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";
import OrderPopup from "../orderPopup";

interface DriverOrderDetailsProps {
  deliveredDriverOrdersDetails?: any;
  fetchDeliveredDriverOrderDetails?: any;
  driverId: any;
  resetDeliveredDriverOrderDetails?: any;
  currency?: any;
  selectedBranch: any;
  driverDetails?: any;
  dmCanRecordPayment?: any;
}

function DriverOrderDetails(props: DriverOrderDetailsProps) {
  const [showOrderPopup, setShowOrderPopup] = useState(false);
  const [orderDetails, setOrderDetails] = useState([] as any);
  const [selectedOrderId, setSelectedOrderId] = useState(0 as any);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    props.resetDeliveredDriverOrderDetails();
  }, []);

  useEffect(() => {
    if (props.selectedBranch.id) {
      props
        .fetchDeliveredDriverOrderDetails(props.driverId, props.selectedBranch)
        .then((res: any) => {
          setShowLoading(false);
        })
        .catch((err: AxiosErrorResponse) => console.log(err));
    } else {
      props
        .fetchDeliveredDriverOrderDetails(props.driverId)
        .then((res: any) => {
          setShowLoading(false);
        })
        .catch((err: AxiosErrorResponse) => console.log(err));
    }
  }, [props.selectedBranch]);

  useEffect(() => {
    setOrderDetails(props.deliveredDriverOrdersDetails);
  }, [props.deliveredDriverOrdersDetails]);

  const getPerformance = (order: any) => {
    let reverse_status_updates = [].concat(order.status_updates).reverse();
    let delivered_status: any = reverse_status_updates.find((status: any) => {
      return status.event === "marked_as_delivered";
    });
    let rider_assigned_status: any = reverse_status_updates.find(
      (status: any) => {
        return status.event === "rider_assigned";
      }
    );
    let ready_status: any = reverse_status_updates.find((status: any) => {
      return status.event === "marked_as_ready";
    });
    let created_status: any = reverse_status_updates.find((status: any) => {
      return status.event === "created" || status.event === "unconfirmed";
    });
    let prep_time_sec: any = (ready_status.time - created_status.time) / 1000;
    let pickup_time_sec: any =
      (rider_assigned_status.time - ready_status.time) / 1000;
    let delivery_time_sec: any =
      (delivered_status.time - rider_assigned_status.time) / 1000;
    prep_time_sec = Math.trunc(prep_time_sec);
    pickup_time_sec = Math.trunc(pickup_time_sec);
    delivery_time_sec = Math.trunc(delivery_time_sec);
    let total_time = prep_time_sec + pickup_time_sec + delivery_time_sec;
    let prep_perc = (prep_time_sec / total_time) * 100;
    let pick_perc = (pickup_time_sec / total_time) * 100;
    let del_perc = (delivery_time_sec / total_time) * 100;
    return (
      <div className="indicator">
        <div className="color prep" style={{ width: `${prep_perc}%` }}>
          {getTime(prep_time_sec)}
        </div>
        <div className="color pick" style={{ width: `${pick_perc}%` }}>
          {getTime(pickup_time_sec)}
        </div>
        <div className="color delivery" style={{ width: `${del_perc}%` }}>
          {getTime(delivery_time_sec)}
        </div>
      </div>
    );
  };

  const getTakenTime = (order: any) => {
    let deliveredStatus = order.status_updates.find((status: any) => {
      return status.event === "marked_as_delivered";
    });
    let timeTaken = (deliveredStatus.time - order.scheduled_for) / 1000;
    return getTime(timeTaken);
  };

  const handleOrderPopup = (orderId: any) => {
    setShowOrderPopup(true);
    setSelectedOrderId(orderId);
  };

  return (
    <div className="delivered-details">
      {showLoading ? (
        <div className="spinner text-center py-5">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          {orderDetails.length ? (
            <table className="delivered-order-details-table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="order.number" />
                  </th>
                  <th>
                    <FormattedMessage id="order.time" />
                  </th>
                  <th>
                    <FormattedMessage id="order.time_taken" />
                  </th>
                  <th>
                    <FormattedMessage id="order.amount" />
                  </th>
                  <th className="performance">
                    <div className="average">
                      <div className="color prep" />
                      <FormattedMessage id="order.preparation" />
                    </div>
                    <div className="average">
                      <div className="color pick" />
                      <FormattedMessage id="cta.pickup" />
                    </div>
                    <div className="average">
                      <div className="color deli" />
                      <FormattedMessage id="order.delivery" />
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="content-wrapper">
                {orderDetails.map((order: any, index: any) => {
                  return (
                    <tr className="content-row" key={index}>
                      <td
                        className="content-item order-number"
                        onClick={() => handleOrderPopup(order.order_id)}
                      >
                        {order.order_extended_info.order_number}
                      </td>
                      <td className="content-item">
                        {getOrderTime(order.scheduled_for)}
                      </td>
                      <td className="content-item">{getTakenTime(order)}</td>
                      <td className="content-item">
                        {formatDecimal(updateOrderTotalAmount(order)) +
                          " " +
                          props.currency}
                      </td>
                      <td className="content-item" style={{ width: "350px" }}>
                        {getPerformance(order)}
                      </td>
                      {selectedOrderId === order.order_id && showOrderPopup ? (
                        <OrderPopup
                          orderStatus="delivered"
                          orderType="homeDelivery"
                          order={order}
                          onClose={() => setShowOrderPopup(false)}
                        />
                      ) : (
                        false
                      )}
                    </tr>
                  );
                })}
                <tr>
                  <td
                    colSpan={5}
                    className="rider-additional-details-container"
                  >
                    <div className="rider-additional-details">
                      <div className="details">
                        <p className="label">
                          <FormattedMessage id="order.total_deliveries" />:
                        </p>
                        <p className="value">
                          {props.driverDetails.total_orders}
                        </p>
                      </div>
                      <div className="details">
                        <p className="label">
                          <FormattedMessage id="order_popup_total" />:
                        </p>
                        <p className="value">
                          {formatDecimal(props.driverDetails.total_amount) +
                            " " +
                            props.currency}
                        </p>
                        <p className="sub-info">
                          <FormattedMessage id="order.cash" />:{" "}
                          {formatDecimal(
                            props.driverDetails.amount_breakdown.cash
                          )}{" "}
                          /<FormattedMessage id="order.others" />:{" "}
                          {formatDecimal(
                            props.driverDetails.amount_breakdown.others
                          )}
                        </p>
                      </div>
                      <div className="details">
                        <p className="label">
                          <FormattedMessage id="order.average_delivery_time" />:
                        </p>
                        <p className="value">
                          {formatTime(
                            props.driverDetails.average_delivery_time
                          )}
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null}
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let deliveredDriverOrdersDetails =
    state.order.home_delivery.delivered_driver_order_details || [];
  let currency = state.company.company_details.currency;
  let selectedBranch = state.company.selected_branch;
  let dmCanRecordPayment = state.company.company_details.dm_can_record_payment;

  return {
    deliveredDriverOrdersDetails,
    currency,
    selectedBranch,
    dmCanRecordPayment,
  };
};
const mapDispatchToProps = {
  fetchDeliveredDriverOrderDetails:
    homeDeliveryOperations.fetchDeliveredDriverOrderDetails,
  resetDeliveredDriverOrderDetails:
    homeDeliveryOperations.resetDeliveredDriverOrderDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(DriverOrderDetails);
