import { isAggregator } from "../../../helpers/utils";
import * as homeDeliveryTypes from "../homeDelivery/types";
import * as pickupTypes from "../pickup/types";

const limboStateOrdersReducer = (state: any = [], action: any) => {
  switch (action.type) {
    case homeDeliveryTypes.SET_UNCONFIRMED_ORDER_CONFIRM_FAIL:
    case pickupTypes.SET_UNCONFIRMED_PICKUP_ORDER_CONFIRM_FAIL:
      if (action.error.response.status !== undefined) {
        if (
          action.error.response.status === 500 &&
          isAggregator(action.meta.previousAction.param.order_source)
        ) {
          let limboStateOrders = state;
          let orderId = action.meta.previousAction.param.order_id;
          if (limboStateOrders.length) {
            if (!limboStateOrders.includes(orderId)) {
              limboStateOrders.push(orderId);
            }
          } else {
            limboStateOrders.push(orderId);
          }
          return limboStateOrders
        }
        return state
      }
      return state;
    default:
      return state;
  }
};

const reducer = limboStateOrdersReducer;

export default reducer;
