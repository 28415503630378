enum ORDER_STATUS {
  DELIVERY_UNCONFIRMED = "homeDelivery.unconfirmed",
  DELIVERY_NEW = "homeDelivery.new",
  DELIVERY_IN_PROGRESS = "homeDelivery.inProgress",
  DELIVERY_READY = "homeDelivery.ready",
  DELIVERY_DELIVERED = "homeDelivery.delivered",
  PICKUP_UNCONFIRMED = "pickup.unconfirmed",
  PICKUP_NEW = "pickup.new",
  PICKUP_READY = "pickup.ready",
  PICKUP_COLLECTED = "pickup.collected",
}

export { ORDER_STATUS };
