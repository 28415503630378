import * as types from "./types";
const fetchBranches = () => ({
  type: types.FETCH_BRANCHES,
  payload: {
    request: {
      method: "get",
      url: "/api/v1/locations/list",
    },
  },
});

const selectBranch = (locationId: number) => ({
  type: types.SELECT_BRANCH,
  payload: locationId
})

export default {
  fetchBranches,
  selectBranch,
};
