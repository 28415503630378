import React from "react";
import { NavLink } from "react-router-dom";
import TabBadge from "../tabBadge";

interface ITabNavigationProps {
  tabInfo: any;
}

function TabNavigation(props: ITabNavigationProps) {
  return (
    <ul className="tabrow">
      {props.tabInfo.map((tab: any, index: any) => {
        return !tab.is_hidden ? (
          <NavLink key={index} activeClassName="selected" to={tab.path}>
            {tab.name}{" "}
            <TabBadge count={tab.orders_count} is_loading={tab.is_loading} />
          </NavLink>
        ) : null;
      })}
    </ul>
  );
}

export default TabNavigation;
