import DriverSearchImage from "../images/third-party-delivery/driver-search.svg";
import WaitingToPickImage from "../images/third-party-delivery/waiting-to-pick.svg";
import DriverWaitingImage from "../images/third-party-delivery/driver-waiting.svg";
import RequestCancelledImage from "../images/third-party-delivery/request-cancelled.svg";
import DeliveringImage from "../images/third-party-delivery/delivering.svg";
import {
  faBiking,
  faBox,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";
import React from "react";

const logisticOrderStatus = {
  1: {
    class: "pending_assignment",
    btn_text: <FormattedMessage id="logistic.assign_delivery_partner" />,
    btn_icon: "",
    class_name: "search-for-driver",
    popup_title: <FormattedMessage id="logistic.assigning_driver" />,
    concatString:"...",
    popup_text: <FormattedMessage id="logistic.popup_assign_message" />,
    popup_image: DriverSearchImage,
  },
  2: {
    class: "assigned",
    btn_text: <FormattedMessage id="logistics.delivery_partner_on_way" />,
    btn_icon: faBiking,
    class_name: "waiting-to-pick",
    popup_title: <FormattedMessage id="logistic.waiting_for_pickup" />,
    concatString:"...",
    popup_text: <FormattedMessage id="logistic.popup_on_the_way_message" />,
    popup_image: WaitingToPickImage,
  },
  3: {
    class: "cancelled",
    btn_text: <FormattedMessage id="logistic.request_cancelled" />,
    btn_icon: "",
    class_name: "cancelled",
    popup_title: <FormattedMessage id="logistic.request_cancelled" />,
    concatString:"",
    popup_text: <FormattedMessage id="logistic.popup_cancelled_message" />,
    popup_image: RequestCancelledImage,
  },
  4: {
    class: "assigned",
    btn_text: <FormattedMessage id="logistic.driver_arrived" />,
    btn_icon: faBox,
    class_name: "driver-waiting",
    popup_title: (
      <FormattedMessage id="logistic.popup_driver_arrived_message" />
    ),
    concatString:"",
    popup_text: <FormattedMessage id="logistic.popup_del_inprogress_message" />,
    popup_image: DriverWaitingImage,
  },
  5: {
    class: "items_purchased",
    btn_text: <FormattedMessage id="logistic.del_inprogress" />,
    btn_icon: faBiking,
    popup_title: <FormattedMessage id="logistic.del_inprogress" />,
    concatString:"...",
    popup_text: <FormattedMessage id="logistic.del_details" />,
    popup_image: DeliveringImage,
  },
  6: {
    class: "complete",
    btn_text: <FormattedMessage id="logistic.item_delivered" />,
    btn_icon: faUserCheck,
    popup_title: <FormattedMessage id="tab.delivered" />,
    concatString:"",
    popup_text: <FormattedMessage id="logistic.popup_item_delivered_message" />,
    popup_image: "",
  },
  7: {
    class: "pending_assignment",
    btn_text: <FormattedMessage id="logistic.assign_delivery_partner" />,
    btn_icon: "",
    class_name: "search-for-driver",
    popup_title: <FormattedMessage id="logistic.assigning_driver" />,
    concatString:"...",
    popup_text: <FormattedMessage id="logistic.popup_assign_message" />,
    popup_image: DriverSearchImage,
  },
} as any;

export default logisticOrderStatus;
