import { useState, useEffect } from "react";

interface IPlayAudioOnLoopHookProps {
  playAudio: () => void;
}

const usePlayAudioOnLoop = ({ playAudio }: IPlayAudioOnLoopHookProps) => {
  const [play, setPlay] = useState(false);
  useEffect(() => {
    let interval: any;
    if (play) {
      playAudio();
      interval = setInterval(() => {
        playAudio();
      }, 3000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [play]);

  const handlePlayAudioOnLoop = () => {
    setPlay(true);
  };

  const clearActivePlayAudio = () => {
    setPlay(false);
  };

  return { playAudioOnLoop: handlePlayAudioOnLoop, clearActivePlayAudio };
};

export { usePlayAudioOnLoop };
