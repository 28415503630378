import { combineReducers } from "redux";
import * as types from "./types";
import {
  getOrdersStats,
  isOrderPayloadPresentIn,
  removeOrderFromList,
} from "../../../helpers/orderFunctions";
import * as orderEventTypes from "../orderEvents/types";
import * as orderTypes from "../order/types";

type OrderState = {
  refresh_required: boolean;
  is_loading: boolean;
  count: number;
  orders: any[];
};

const initialOrdersState: OrderState = {
  refresh_required: false,
  is_loading: true,
  count: 0,
  orders: [],
};

const unconfirmedPickupOrdersReducer = (
  state: OrderState = initialOrdersState,
  action: any
) => {
  switch (action.type) {
    case types.FETCH_UNCONFIRMED_PICKUP_ORDERS_SUCCESS: {
      return Object.assign(
        { ...state },
        { is_loading: false, refresh_required: false },
        getOrdersStats(action.payload.data || [])
      );
    }
    case types.SET_UNCONFIRMED_PICKUP_ORDER_CONFIRM_SUCCESS: {
      let orderId = action.meta.previousAction.param.order_id;
      return Object.assign(
        { ...state },
        removeOrderFromList(orderId, state.orders)
      );
    }
    case types.SET_UNCONFIRMED_PICKUP_ORDER_REJECT_FAIL: {
      if (action.error !== undefined && action.error.status === 404) {
        let orderId = action.meta.previousAction.param.order_id;
        return Object.assign(
          { ...state },
          removeOrderFromList(orderId, state.orders)
        );
      }
      return state;
    }
    case types.SET_UNCONFIRMED_PICKUP_ORDER_REJECT_SUCCESS: {
      let orderId = action.meta.previousAction.param.order_id;
      return Object.assign(
        { ...state },
        removeOrderFromList(orderId, state.orders)
      );
    }
    case orderTypes.FETCH_ORDER_SUCCESS: {
      let index = state.orders.findIndex(
        (order: any) => order.order_id === action.payload.data.order_id
      );
      if (index == -1) {
        return state;
      }
      let updatedOrders = state.orders;
      updatedOrders[index] = action.payload.data;
      return Object.assign({ ...state }, getOrdersStats(updatedOrders));
    }
    case orderTypes.REMOVE_EXPIRED_ORDER: {
      return Object.assign(
        { ...state },
        removeOrderFromList(action.payload, state.orders)
      );
    }
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CREATED: {
      let pusherData = action.payload;
      let pusherDataHasOrderPayload = isOrderPayloadPresentIn(pusherData);
      if (
        (pusherData.order_type === "online" &&
          pusherData.order_status === "unconfirmed" &&
          pusherData.takeaway) ||
        (pusherData.order_type === "walk_in" &&
          pusherData.order_status === "unconfirmed")
      ) {
        // Push order into the unconfirmed orders list if it doesn't exist
        let index = state.orders.findIndex(
          (order: any) => order.order_id === pusherData.order_id
        );
        if (index !== -1) {
          return state;
        }
        if (!pusherDataHasOrderPayload) {
          return Object.assign(
            { ...state },
            { count: state.count + 1, refresh_required: true }
          );
        }
        return Object.assign(
          { ...state },
          getOrdersStats([...state.orders, pusherData.order_payload])
        );
      }
      return state;
    }
    case orderEventTypes.PUSHER_RECEIVED_ORDER_REJECTED:
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CONFIRMED: {
      // Remove confirmed/rejected order from the list
      let orderId = action.payload.order_id;
      return Object.assign(
        { ...state },
        removeOrderFromList(orderId, state.orders)
      );
    }
    default:
      return state;
  }
};

const newPickupOrdersReducer = (
  state: OrderState = initialOrdersState,
  action: any
) => {
  switch (action.type) {
    case types.FETCH_NEW_PICKUP_ORDERS_SUCCESS: {
      return Object.assign(
        { ...state },
        { is_loading: false, refresh_required: false },
        getOrdersStats(action.payload.data || [])
      );
    }
    case types.SET_PICKUP_ORDER_MARK_AS_READY_SUCCESS: {
      let orderId = action.meta.previousAction.param.order_id;
      return Object.assign(
        { ...state },
        removeOrderFromList(orderId, state.orders)
      );
    }
    case orderTypes.FETCH_ORDER_SUCCESS: {
      let index = state.orders.findIndex(
        (order: any) => order.order_id === action.payload.data.order_id
      );
      if (index === -1) {
        return state;
      }
      let updatedOrders = state.orders;
      updatedOrders[index] = action.payload.data;
      return Object.assign({ ...state }, getOrdersStats(updatedOrders));
    }
    case orderTypes.REMOVE_EXPIRED_ORDER: {
      // Remove the order from list once it completes 24 hours.
      return Object.assign(
        { ...state },
        removeOrderFromList(action.payload, state.orders)
      );
    }
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CONFIRMED:
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CREATED: {
      let pusherData = action.payload;
      let pusherDataHasOrderPayload = isOrderPayloadPresentIn(pusherData);
      let index = state.orders.findIndex(
        (order: any) => order.order_id === pusherData.order_id
      );
      if (
        !["walk_in", "online"].includes(pusherData.order_type) ||
        pusherData.order_status !== "new" ||
        pusherData.scheduled ||
        !pusherData.takeaway
      ) {
        return state;
      }
      if (index !== -1) {
        return state;
      }
      if (!pusherDataHasOrderPayload) {
        return Object.assign(
          { ...state },
          { count: state.count + 1, refresh_required: true }
        );
      }
      return Object.assign(
        { ...state },
        getOrdersStats([...state.orders, pusherData.order_payload])
      );
    }
    case orderEventTypes.PUSHER_RECEIVED_ORDER_VOIDED:
    case orderEventTypes.PUSHER_RECEIVED_PICKUP_ORDER_MARKED_AS_READY: {
      let orderId = action.payload.order_id;
      return Object.assign(
        { ...state },
        removeOrderFromList(orderId, state.orders)
      );
    }
    default:
      return state;
  }
};

const readyPickupOrdersReducer = (
  state: OrderState = initialOrdersState,
  action: any
) => {
  let index;
  switch (action.type) {
    case types.FETCH_READY_PICKUP_ORDERS_SUCCESS: {
      return Object.assign(
        { ...state },
        { is_loading: false, refresh_required: false },
        getOrdersStats(action.payload.data || [])
      );
    }
    case types.SET_ORDER_COLLECTED_SUCCESS: {
      let orderId = action.meta.previousAction.param.order_id;
      return Object.assign(
        { ...state },
        removeOrderFromList(orderId, state.orders)
      );
    }
    case orderTypes.FETCH_ORDER_SUCCESS: {
      let index = state.orders.findIndex(
        (order: any) => order.order_id === action.payload.data.order_id
      );
      if (index === -1) {
        return state;
      }
      let updatedOrders = state.orders;
      updatedOrders[index] = action.payload.data;
      return Object.assign({ ...state }, getOrdersStats(updatedOrders));
    }
    case orderTypes.REMOVE_EXPIRED_ORDER: {
      // Remove the order from list once it completes 24 hours.
      return Object.assign(
        { ...state },
        removeOrderFromList(action.payload, state.orders)
      );
    }
    case orderEventTypes.PUSHER_RECEIVED_PICKUP_ORDER_MARKED_AS_READY: {
      let pusherData = action.payload;
      let pusherDataHasOrderPayload = isOrderPayloadPresentIn(pusherData);
      let index = state.orders.findIndex(
        (order: any) => order.order_id === pusherData.order_id
      );
      if (index !== -1) {
        return state;
      }
      if (!pusherDataHasOrderPayload) {
        return Object.assign(
          { ...state },
          { count: state.count + 1, refresh_required: true }
        );
      }
      return Object.assign(
        { ...state },
        getOrdersStats([...state.orders, pusherData.order_payload])
      );
    }
    case orderEventTypes.PUSHER_RECEIVED_ORDER_MARKED_AS_COLLECTED: {
      return Object.assign(
        { ...state },
        removeOrderFromList(action.payload.order_id, state.orders)
      );
    }
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CONFIRMED:
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CREATED: {
      let pusherData = action.payload;
      let pusherDataHasOrderPayload = isOrderPayloadPresentIn(pusherData);
      let index = state.orders.findIndex(
        (order: any) => order.order_id === pusherData.order_id
      );
      if (
        pusherData.order_status !== "ready" ||
        pusherData.scheduled ||
        !pusherData.takeaway
      ) {
        return state;
      }
      if (index !== -1) {
        return state;
      }
      if (!pusherDataHasOrderPayload) {
        return Object.assign(
          { ...state },
          { count: state.count + 1, refresh_required: true }
        );
      }
      return Object.assign(
        { ...state },
        getOrdersStats([...state.orders, pusherData.order_payload])
      );
    }
    default:
      return state;
  }
};

const collectedPickupOrdersReducer = (
  state: OrderState = initialOrdersState,
  action: any
) => {
  switch (action.type) {
    case types.FETCH_COLLECTED_PICKUP_ORDERS_SUCCESS: {
      return Object.assign(
        { ...state },
        { is_loading: false, refresh_required: false },
        getOrdersStats(action.payload.data || [])
      );
    }
    case types.SET_ORDER_COLLECTED_SUCCESS:
      return state;
    case orderTypes.FETCH_ORDER_SUCCESS: {
      let index = state.orders.findIndex(
        (order: any) => order.order_id === action.payload.data.order_id
      );
      if (index === -1) {
        return state;
      }
      let updatedOrders = state.orders;
      updatedOrders[index] = action.payload.data;
      return Object.assign({ ...state }, getOrdersStats(updatedOrders));
    }
    case orderEventTypes.PUSHER_RECEIVED_ORDER_REPRINTED: {
      let pusherData = action.payload;
      let pusherDataHasOrderPayload = isOrderPayloadPresentIn(pusherData);
      let index = state.orders.findIndex(
        (order: any) => order.order_id === pusherData.order_id
      );
      if (index === -1) {
        return state;
      }
      if (pusherDataHasOrderPayload) {
        let updatedOrders = state.orders;
        updatedOrders[index] = pusherData.order_payload;
        return Object.assign({ ...state }, getOrdersStats(updatedOrders));
      }
      return state;
    }
    case orderEventTypes.PUSHER_RECEIVED_ORDER_MARKED_AS_COLLECTED: {
      let pusherData = action.payload;
      let pusherDataHasOrderPayload = isOrderPayloadPresentIn(pusherData);
      let index = state.orders.findIndex(
        (order: any) => order.order_id === action.payload.order_id
      );
      if (index !== -1) {
        return state;
      }
      if (!pusherDataHasOrderPayload) {
        return Object.assign(
          { ...state },
          { count: state.count + 1, refresh_required: true }
        );
      }
      return Object.assign(
        { ...state },
        getOrdersStats([...state.orders, pusherData.order_payload])
      );
    }
    case types.SET_COLLECTED_ORDERS_REFRESH_REQUIRED:
      return { ...state, refresh_required: true };
    default:
      return state;
  }
};

const reducer = combineReducers({
  unconfirmed_pickup_orders: unconfirmedPickupOrdersReducer,
  new_pickup_orders: newPickupOrdersReducer,
  ready_pickup_orders: readyPickupOrdersReducer,
  collected_pickup_orders: collectedPickupOrdersReducer,
});

export default reducer;
