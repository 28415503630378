import React, { useEffect, useState } from "react";
import {
  faBell,
  faMotorcycle,
  faShoppingBag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { notificationOperations } from "../../state/features/notifications";
import { FormattedMessage } from "react-intl";
interface IOrderNotificationProps {
  notificaions: any;
  currentTab: string;
  clearHomeDeliveryNotifications?: any;
  clearPickupNotifications?: any;
}

function OrderNotification(props: IOrderNotificationProps) {
  let history = useHistory();
  const [currentTab, setCurrentTab] = useState("");

  const getTimeNow = () => {
    let time = new Date()
      .toLocaleTimeString(undefined, {
        hour12: true,
        timeStyle: "short",
      } as any)
      .toLocaleUpperCase();
    if (time.split(":")[0] == "00") {
      let fixedTime: any = time.split(":");
      fixedTime[0] = "12";
      time = fixedTime.join(":");
    }
    return time;
  };

  useEffect(() => {
    if (
      props.notificaions.home_delivery.count > 0 &&
      props.currentTab == "homeDelivery.unconfirmed"
    ) {
      props.clearHomeDeliveryNotifications();
    } else if (
      props.notificaions.pickup.count > 0 &&
      props.currentTab == "pickup.unconfirmed"
    ) {
      props.clearPickupNotifications();
    }
  }, [props.notificaions, props.currentTab]);

  const onViewClick = (path: any) => {
    history.push(path);
  };

  return (
    <div className="order-notification-wrapper">
      {props.notificaions.home_delivery.count > 0 &&
      currentTab !== "home-delivery/unconfirmed" ? (
        <div
          className={`order-notification-item ${
            props.notificaions.home_delivery.count > 0 && "show"
          }`}
        >
          <div>
            <div className="bellContainer">
              <FontAwesomeIcon icon={faMotorcycle} />
            </div>
            <div className="detailsContainer">
              <small>{getTimeNow()}</small>
              <p>
                <strong>
                  {props.notificaions.home_delivery.count}{" "}
                  <FormattedMessage id="global.home_delivery" />{" "}
                  {props.notificaions.home_delivery.count > 1 ? (
                    <FormattedMessage id="order.notification2" />
                  ) : (
                    <FormattedMessage id="order.notification3" />
                  )}
                </strong>{" "}
                <FormattedMessage id="order.notification1" />
              </p>
              <button onClick={() => onViewClick("/home-delivery/unconfirmed")}>
                <FormattedMessage id="global.view" />
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {props.notificaions.pickup.count > 0 &&
      currentTab !== "pickup/unconfirmed" ? (
        <div
          className={`order-notification-item ${
            props.notificaions.pickup.count > 0 && "show"
          }`}
        >
          <div>
            <div className="bellContainer">
              <FontAwesomeIcon icon={faShoppingBag} />
            </div>
            <div className="detailsContainer">
              <small>{getTimeNow()}</small>
              <p>
                <strong>
                  {props.notificaions.pickup.count}{" "}
                  <FormattedMessage id="cta.pickup" />{" "}
                  {props.notificaions.pickup.count > 1
                    ? `Orders are`
                    : "Order is"}
                </strong>{" "}
                <FormattedMessage id="order.notification1" />
              </p>
              <button onClick={() => onViewClick("/pickup/unconfirmed")}>
                <FormattedMessage id="global.view" />
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let notificaions = state.notifications;
  let currentTab = state.currentTab;

  return { notificaions, currentTab };
};

const mapDispatchToProps = {
  clearHomeDeliveryNotifications:
    notificationOperations.clearHomeDeliveryUnconfirmedNotification,
  clearPickupNotifications:
    notificationOperations.clearPickupUnconfirmedNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderNotification);
