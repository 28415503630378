import React, { useState, useEffect, useMemo } from "react";
import { Button } from "react-bootstrap";
import { Button as ThrottleButton } from "../throttleButton";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faBiking } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

interface IRiderSelectionProps {
  orderType: any;
  orderStatus: any;
  isRiderAppEnabled: any;
  order: any;
  assignRiderToOrder: any;
  company: any;
  drivers: any;
}

function RiderSelctionPopup(props: IRiderSelectionProps) {
  const [showRiderPopup, setShowRiderPopup] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [riders, setRiders] = useState([] as any);
  const [selectedRider, setSelectedRider] = useState(null);

  const intl = useIntl();

  useEffect(() => {
    if (
      Object.keys(props.company).length > 0 &&
      props.drivers.length > 0
    ) {
        let locationDrivers = [] as any;
        props.drivers.map((driver: any) => {
          if (driver.assigned_locations.includes(props.order.location_id)) {
            locationDrivers.push(driver);
          }
        });
        setRiders(locationDrivers);
    }
  }, [props.drivers, props.order.location_id]);

  const assignDriverToOrder = (riderId?: any) => {
    setShowRiderPopup(false);
    props.assignRiderToOrder(props.order, riderId)
    setSelectedRider(null);
  };

  const companyRiderFilter = useMemo(() => {
    let requiredRiders = riders.filter((e: any) => {
      return e.name.toLowerCase().includes(searchValue.toLowerCase());
    });
    if (requiredRiders.length == 0 && searchValue) {
      requiredRiders.push("No Drivers");
    }
    return requiredRiders;
  }, [searchValue, riders]);

  return (
    <>
      <Button
        className="coustom-rider-button"
        onClick={() => setShowRiderPopup(true)}
        disabled={props.order.logistics_job && props.order.logistics_job?.status !== 3}
      >
        <FontAwesomeIcon icon={faBiking} />
        <FormattedMessage id="logistic.assign_rider" />
      </Button>
      <Modal
        show={showRiderPopup}
        dialogClassName="custom curved order-popup-wrapper"
        onHide={() => {
          setSelectedRider(null);
          setShowRiderPopup(false);
        }}
        centered
      >
        <div className="rider-selection">
          <h2>
            <FormattedMessage id="rider.select_for_order" />{" "}
            <span className="rider-selection-order-number">{props.order.order_extended_info.order_number}</span>
          </h2>
          <div className="rider-search-wrapper">
            <div className="input-group">
              <div className="input-group-prepend">
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
              </div>
              <input
                type="text"
                className="rider-search"
                placeholder={intl.formatMessage({id: "search"})}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>
          <ul>
            {riders.length > 0 && companyRiderFilter[0] != "No Drivers" ? (
              (companyRiderFilter.length > 0 ? companyRiderFilter : riders).map(
                (rider: any, index: any) => {
                  return (
                    <li
                      key={index}
                      className={
                        selectedRider && selectedRider.id == rider.id
                          ? "selected rider-list"
                          : "rider-list"
                      }
                      onClick={() => {
                        setSelectedRider(rider);
                      }}
                    >
                      <p className="driver-name">{rider.name}</p>
                    </li>
                  );
                }
              )
            ) : (
              <li className="no-rider-message">
                <FormattedMessage id="driver.no_rider_available" />
              </li>
            )}
          </ul>
          <div className="footer-button-wrapper">
            <button
              onClick={() => {
                setShowRiderPopup(false);
                setSelectedRider(null);
              }}
              className="rider-selection-button cancel-button"
            >
              <FormattedMessage id="void_cancel" />
            </button>
            <ThrottleButton
              onClick={() => assignDriverToOrder(selectedRider?.id)}
              className="rider-selection-button assign-button"
              disabled={!Boolean(selectedRider)}
            >
              <FormattedMessage id="assign" />
            </ThrottleButton>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state: any) => {
  let drivers = state.drivers.company_drivers || [];
  let company = state.company.company_details;

  return { drivers, company };
};

export default connect(mapStateToProps)(RiderSelctionPopup);
