import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";

function MainNav(props: any) {
  const [selectedNavId, setSelectedNavId] = useState(0);

  const mainNavs = [
    {
      id: 0,
      title: <FormattedMessage id="global.home_deliveries" />,
      path: "/home-delivery/new",
    },
    {
      id: 1,
      title: <FormattedMessage id="global.pickups" />,
      path: "/pickup/new",
    },
    {
      id: 2,
      title: <FormattedMessage id="global.scheduled" />,
      path: "/scheduled",
    },
  ];

  useEffect(() => {
    let location = window.location.pathname;
    location = location.split("/")[1];
    if (location === "home-delivery") {
      setSelectedNavId(0);
    }
    if (location === "pickup") {
      setSelectedNavId(1);
    }
    if (location === "scheduled") {
      setSelectedNavId(2);
    }
  }, [window.location.pathname]);

  const onNavClick = (selectedNavId: number) => {
    setSelectedNavId(selectedNavId);
  };

  return (
    <ul className="nav main-nav">
      {mainNavs.map((navItem: any, index: any) => {
        return (
          <li key={index} className="nav-item">
            <NavLink
              className={selectedNavId === navItem.id ? "item active" : "item"}
              strict
              onClick={() => onNavClick(navItem.id)}
              activeClassName="active"
              to={navItem.path}
            >
              {navItem.title}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
}
export default MainNav;
