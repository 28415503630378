export const FETCH_DRIVERS = "drivers/FETCH_DRIVERS";
export const FETCH_DRIVERS_SUCCESS = "drivers/FETCH_DRIVERS_SUCCESS";
export const FETCH_DRIVERS_FAIL = "drivers/FETCH_DRIVERS_FAIL";
export const ASSIGN_DRIVER = "drivers/ASSIGN_DRIVER";
export const FETCH_RIDER_COORDINATES = "drivers/FETCH_RIDER_COORDINATES";
export const FETCH_RIDER_COORDINATES_SUCCESS =
  "drivers/FETCH_RIDER_COORDINATES_SUCCESS";
export const FETCH_RIDER_COORDINATES_FAIL =
  "drivers/FETCH_RIDER_COORDINATES_FAIL";
export const ASSIGN_NEW_RIDER = "drivers/ASSIGN_NEW_RIDER";
export const ASSIGN_NEW_RIDER_SUCCESS = "drivers/ASSIGN_NEW_RIDER_SUCCESS";
export const ASSIGN_NEW_RIDER_FAIL = "drivers/ASSIGN_NEW_RIDER_FAIL";
export const ASSIGN_READY_ORDER_RIDER = "drivers/ASSIGN_READY_ORDER_RIDER";
export const ASSIGN_READY_ORDER_RIDER_SUCCESS =
  "drivers/ASSIGN_READY_ORDER_RIDER_SUCCESS";
export const ASSIGN_READY_ORDER_RIDER_FAIL =
  "drivers/ASSIGN_READY_ORDER_RIDER_FAIL";
export const UNASSIGN_NEW_ORDER_RIDER = "drivers/UNASSIGN_NEW_ORDER_RIDER";
export const UNASSIGN_NEW_ORDER_RIDER_SUCCESS =
  "drivers/UNASSIGN_NEW_ORDER_RIDER_SUCCESS";
export const UNASSIGN_NEW_ORDER_RIDER_FAIL =
  "drivers/UNASSIGN_NEW_ORDER_RIDER_FAIL";
export const UNASSIGN_READY_ORDER_RIDER = "drivers/UNASSIGN_READY_ORDER_RIDER";
export const UNASSIGN_READY_ORDER_RIDER_SUCCESS =
  "drivers/UNASSIGN_READY_ORDER_RIDER_SUCCESS";
export const UNASSIGN_READY_ORDER_RIDER_FAIL =
  "drivers/UNASSIGN_READY_ORDER_RIDER_FAIL";
