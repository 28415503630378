import React from "react";
import { getIconImageSource } from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage } from "react-intl";

interface IItemSelectionGridViewProps {
  title?: string;
  itemsList: {
    id: number | string;
    icon?: string;
    name: string;
  }[];
  onSelect: (item: any) => void;
}
const ItemSelectionGridView = ({
  title,
  itemsList,
  onSelect: handleSelect,
}: IItemSelectionGridViewProps) => {
  return (
    <>
      {title ? <p className="grid-selection-title">{title}</p> : null}
      <div className="payment-types-container">
        {itemsList.map((item, index) => (
          <div
            key={item.id}
            className="payment-type-tile"
            onClick={() => handleSelect(item)}>
            {item.icon ? (
              <span className="icon">
                <img alt={item.icon} src={getIconImageSource(item.icon)} />
              </span>
            ) : null}
            <p className="payment-type">{item.name}</p>
          </div>
        ))}
      </div>
    </>
  );
};

interface IItemSelectionGridFooterProps {
  showCloseButtonAs?: "back" | "";
  currentPage: number;
  totalPages: number;
  nextPageAction(): void;
  prevPageAction(): void;
  onClose(): void;
}

const ItemSelectionGridFooter = ({
  showCloseButtonAs = "",
  currentPage,
  totalPages,
  nextPageAction,
  prevPageAction,
  onClose: handleClose,
}: IItemSelectionGridFooterProps) => {
  return (
    <>
      {showCloseButtonAs === "back" ? (
        <div
          className="btn close-payment-types variant-back"
          onClick={handleClose}>
          <FontAwesomeIcon icon={faChevronLeft} /> Back
        </div>
      ) : (
        <div className="btn close-payment-types" onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}
      <p className="page-no">
        {currentPage} <FormattedMessage id="of" /> {totalPages}
      </p>
      <div className="nav-buttons">
        <button className="btn nav-payment-types" onClick={prevPageAction}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button className="btn nav-payment-types" onClick={nextPageAction}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </>
  );
};

export { ItemSelectionGridView, ItemSelectionGridFooter };
