import React from "react";
import { FormattedMessage } from "react-intl";
import Order from "../order";

const SearchResults = ({
  orders,
  selecteOrderId,
  disableButton,
  markOrderCollected,
}: any) => {
  return (
    <table className="orders-table" cellPadding="0" cellSpacing="0">
      <thead>
        <tr>
          <th></th>
          <th style={{ width: "180px" }}>
            <FormattedMessage id="order.no" />.
          </th>
          <th>
            <FormattedMessage id="order.order_time" />
          </th>
          <th>
            <FormattedMessage id="order.elapsed" />
          </th>
          <th>
            <FormattedMessage id="order.cust_or_tel" />
          </th>
          <th>
            <FormattedMessage id="order.agent" />
          </th>
          <th>
            <FormattedMessage id="order.amount" />
          </th>
          <th className="text-center">
            <FormattedMessage id="order.action" />
          </th>
        </tr>
      </thead>
      <tbody className="orders-list-table-body">
        {orders.length > 0
          ? orders.map((order: any) => (
              <Order
                orderType="pickup"
                orderStatus="ready"
                key={`result_${order.order_id}`}
                handleOrderState={markOrderCollected}
                disableButton={
                  order.order_id === selecteOrderId && disableButton
                }
                item={order}
              />
            ))
          : null}
      </tbody>
    </table>
  );
};

export { SearchResults };
