import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import Swal from "sweetalert2";

interface ICancellationPopupProps {
  onCancel: any;
  cancelReasons?: any;
  onRejectConfirm: any;
  supervisorPassword?: any;
}

function CancellationPopup(props: ICancellationPopupProps) {
  const [selectedReasonId, setSelectedReasonId] = useState(0 as any);
  const [cancellationReasons, setCancellationReasons] = useState([] as any);
  const [supervisorPassword, setSupervisorPassword] = useState("");
  const [disabledConfirm, setDisabledConfirm] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [reasonValid, setReasonValid] = useState(true);
  const passwordInput: any = React.useRef();

  React.useEffect(() => {
    setCancellationReasons(props.cancelReasons);
  }, [props.cancelReasons]);

  React.useEffect(() => {
    if (cancellationReasons.length) {
      setSelectedReasonId(cancellationReasons[0].id);
    }
  }, [cancellationReasons]);

  React.useEffect(() => {
    if (selectedReasonId !== 0) {
      if (!props.supervisorPassword) {
        setDisabledConfirm(false);
      } else {
        if (supervisorPassword.length > 0) {
          setDisabledConfirm(false);
        }
      }
    }
  }, [selectedReasonId, supervisorPassword]);

  const onselectionchange = (e: any) => {
    setSelectedReasonId(e.target.value);
  };

  const onConfirm = (e: any) => {
    e.preventDefault();
    if (selectedReasonId === 0) {
      setReasonValid(false);
    }
    props.onRejectConfirm(selectedReasonId, supervisorPassword);
  };

  const onCancel = (e: React.SyntheticEvent) => {
    e.preventDefault();
    props.onCancel();
  };

  return (
    <Modal
      centered
      dialogClassName="custom curved"
      onHide={() => props.onCancel()}
      show={true}
    >
      <div className="cancellation-popup">
        <h1 className="popup-header">
          <b>
            <FormattedMessage id="void_order" />
          </b>
        </h1>
        <Form
          onSubmit={onConfirm}
          onReset={onCancel}
          className="cancellation-form"
        >
          <Form.Group>
            <Form.Label>
              <FormattedMessage id="void_reason" />:
            </Form.Label>
            {cancellationReasons.length ? (
              <Form.Control
                as="select"
                required
                onChange={(e: any) => {
                  setReasonValid(true);
                  onselectionchange(e);
                }}
                isValid={false}
                className={!reasonValid ? "error-input" : ""}
              >
                {cancellationReasons.map((reason: any, index: any) => {
                  return (
                    <option
                      key={index}
                      selected={index === 0}
                      value={reason.id}
                    >
                      {reason.reason}
                    </option>
                  );
                })}
              </Form.Control>
            ) : null}
          </Form.Group>
          {props.supervisorPassword ? (
            <Form.Group controlId="formGridAddress1">
              <Form.Label>
                <FormattedMessage id="void_password" />:
              </Form.Label>
              <Form.Control
                value={supervisorPassword}
                onChange={(e: any) => {
                  setPasswordValid(true);
                  setSupervisorPassword(e.target.value);
                }}
                ref={passwordInput}
                required
                type="password"
                className={!passwordValid ? "error-input" : ""}
              />
            </Form.Group>
          ) : null}

          <div className="form-actions">
            <button type="reset" className="btn btn-secondary cancel-btn mr-2">
              <FormattedMessage id="void_cancel" />
            </button>
            <button
              disabled={
                disabledConfirm ||
                (props.supervisorPassword && supervisorPassword.length < 1)
                  ? true
                  : false
              }
              type="submit"
              className={
                disabledConfirm ||
                (props.supervisorPassword && supervisorPassword.length < 1)
                  ? "btn btn-danger disabled-button"
                  : "btn btn-danger"
              }
            >
              <FormattedMessage id="void_proceed" />
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state: any) => {
  let cancelReasons = state.cancelReasons;
  let supervisorPassword = state.company.company_details.supervisor_password;
  return { cancelReasons, supervisorPassword };
};

export default connect(mapStateToProps, null)(CancellationPopup);
