import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { formatDecimal } from "../../helpers/itemCalculations";
import { homeDeliveryOperations } from "../../state/features/homeDelivery";

import "./index.scss";

interface IRiderStatsSectionProps {
  fetchRiderStats: any;
  companyId: any;
  riderStats: any;
  currency: any;
  selectDriver: any;
  selectedDriver: any;
}

const RiderStatsSection = (props: IRiderStatsSectionProps) => {
  const [riders, setRiders] = useState([] as any);
  const [avgDeliveryTime, setAvgDeliveryTime] = useState(0 as any);
  const [totalAmount, setTotalAmount] = useState(0 as any);
  const [showDriverDropdown, setShowDriverDropdown] = useState(false);

  useEffect(() => {
    setRiders(props.riderStats);
  }, [props.riderStats]);

  useEffect(() => {
    if (riders.length > 0) {
      let riderStats = riders.filter(
        (rider: any) => rider.name !== "All Riders"
      );
      let avg_time = Math.floor(
        riderStats.reduce(
          (total: number, rider: any) => total + rider.average_delivery_time,
          0
        ) / riderStats.length
      );

      setAvgDeliveryTime(riderStats.length == 0 ? "0" : formatTime(avg_time));
      setTotalAmount(
        formatDecimal(
          riderStats.reduce(
            (total: number, rider: any) => total + rider.total_amount,
            0
          )
        )
      );
    }
  }, [riders]);

  const formatTime = (seconds: number) => {
    let time;
    if (seconds < 60) {
      return seconds === 1 ? "1 second" : `${seconds} seconds`;
    } else if (seconds >= 60 && seconds < 3600) {
      time = Math.floor(seconds / 60);
      return time === 1 ? "1 minute" : `${time} minutes`;
    } else {
      time = Math.floor(seconds / 3600);
      return time === 1 ? "1 hour" : `${time} hours`;
    }
  };

  const selectDriver = (rider: any) => {
    setShowDriverDropdown(false);
    props.selectDriver(rider);
  };

  return (
    <div className="rider-stats-section">
      {riders.length > 0 ? (
        <>
          <div className="driver-filter">
            <label>
              <FormattedMessage id="order.driver" />:
            </label>
            <Dropdown
              className="drivers-dropdown"
              drop="down"
              show={showDriverDropdown}
              onToggle={() => setShowDriverDropdown(!showDriverDropdown)}
            >
              <Dropdown.Toggle variant="" id="" className="dropdown-icon">
                <span>
                  {props.selectedDriver ? (
                    props.selectedDriver.name !== "All Riders" ? (
                      props.selectedDriver.name
                    ) : (
                      <FormattedMessage id="all.drivers" />
                    )
                  ) : (
                    <FormattedMessage id="driver.select_driver" />
                  )}
                </span>
                <FontAwesomeIcon icon={faChevronDown} />
              </Dropdown.Toggle>
              <Dropdown.Menu className="drivers-list" flip={false}>
                {riders.map((rider: any, index: number) => (
                  <div
                    style={{ padding: "5px 0" }}
                    key={index}
                    onClick={() => selectDriver(rider)}
                  >
                    <span className="driver-name">{rider.name}</span>
                  </div>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="average-delivery-time section-item">
            <span>
              <FormattedMessage id="order.average_delivery_time" />
            </span>
            <p>{avgDeliveryTime}</p>
          </div>
          <div className="total-amount section-item">
            <span>
              <FormattedMessage id="order.total_amount" />
            </span>
            <p>
              {totalAmount + " "}
              {props.currency ? props.currency : ""}
            </p>
          </div>
        </>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  let currency = state.company.company_details.currency;
  let companyId = state.company.company_details.company_id;

  return { currency, companyId };
};

const mapDispatchToProps = {
  fetchRiderStats: homeDeliveryOperations.fetchDeliveredDriverOrderDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(RiderStatsSection);
