import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

interface ISetDeliveryTimePopup {
  show: any;
  handleShow: any;
  handleApprove: any;
  orderId: any;
  defaultDeliveryEta: any;
}

function SetDeliveryTimePopup(props: ISetDeliveryTimePopup) {
  const [eta, setEta] = React.useState(0 as number);
  const [disabledConfirm, setConfirmDisabled] = React.useState(false);
  const etaInput: any = React.useRef();
  const setEtaInput = (e: any) => {
    let regExp = /^([0-9]|[0-9][0-9]|[0-9][0-9][0-9])$/;
    setConfirmDisabled(!regExp.test(e.target.value));
    setEta(e.target.value);
  };

  useEffect(() => {
    if (etaInput.current) {
      etaInput.current.focus();
    }
  }, [props.show]);

  useEffect(() => {
    setEta(props.defaultDeliveryEta);
  }, [props.defaultDeliveryEta]);

  return (
    <Modal
      show={props.show}
      dialogClassName="custom curved delivery-time-popup"
      centered
      size="xl"
      contentClassName="delivery-time-popup-content"
      onHide={() => props.handleShow(!props.show)}
    >
      <Modal.Body>
        <h1 className="head">
          <FormattedMessage id="eta.confirm" />
        </h1>
        <p className="message">
          <FormattedMessage id="eta.message" />
        </p>
        <div className="delivery-time-section">
          <input
            className="delivery-time-input"
            type="number"
            value={eta}
            required
            min={5}
            ref={etaInput}
            onChange={(e: any) => setEtaInput(e)}
          />
          <p className="delivery-time-units">
            <FormattedMessage id="time.minutes" />{" "}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className="popup-actions">
        <button
          className="btn btn-secondary cancel-btn mr-2"
          onClick={() => props.handleShow(!props.show)}
        >
          <FormattedMessage id="void_cancel" />
        </button>
        <button
          disabled={disabledConfirm}
          className="btn btn-success"
          onClick={() => props.handleApprove(props.orderId, eta)}
        >
          <FormattedMessage id="confirm" />
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default SetDeliveryTimePopup;
