import React from "react";
import { FormattedMessage } from "react-intl";
import { STRIPE_CONNECT_ERROR } from "../../utils";

const TransactionFailReason = ({
  stripeConnectError,
}: {
  stripeConnectError: {
    type: STRIPE_CONNECT_ERROR;
    data?: any;
    message: string;
  } | null;
}) => {
  if (!stripeConnectError) {
    return (
      <FormattedMessage
        id="payment.stripe_connect.error.generic_error"
        defaultMessage={
          "Sorry, we're unable to process the payment at this time. {break}Please try again later."
        }
        values={{
          break: <br />,
        }}
      />
    );
  }

  switch (stripeConnectError.type) {
    case STRIPE_CONNECT_ERROR.DISCONNECTION_ERROR:
      return (
        <FormattedMessage
          id="payment.stripe_connect.error.disconnection_error"
          defaultMessage={
            "{deviceName} disconnected unexpectedly. {break}Please reconnect the card reader and try again."
          }
          values={{
            deviceName: <strong>{stripeConnectError.data.deviceId}</strong>,
            break: <br />,
          }}
        />
      );
    case STRIPE_CONNECT_ERROR.DISCOVERY_ERROR:
      return (
        <FormattedMessage
          id="payment.stripe_connect.error.discovery_error"
          defaultMessage={
            "An error occurred while searching for readers.  {break}Please try again"
          }
          values={{
            break: <br />,
          }}
        />
      );
    case STRIPE_CONNECT_ERROR.NO_READERS:
      return (
        <FormattedMessage
          id="payment.stripe_connect.error.no_readers_error"
          defaultMessage={
            "Unable to find any readers in your network. {break}Please check your setup and try again."
          }
          values={{
            break: <br />,
          }}
        />
      );
    case STRIPE_CONNECT_ERROR.READER_NOT_FOUND:
      return (
        <FormattedMessage
          id="payment.stripe_connect.error.reader_not_found_error"
          defaultMessage={
            "We couldn't establish a connection with {deviceName}. {break}Please check your card reader and try again."
          }
          values={{
            deviceName: <strong>{stripeConnectError.data.deviceId}</strong>,
            break: <br />,
          }}
        />
      );
    case STRIPE_CONNECT_ERROR.REQUIRES_CONFIRMATION:
      return (
        <FormattedMessage
          id="payment.stripe_connect.error.requires_confirmation"
          defaultMessage={
            "Payment processing failed due to a connectivity problem. {break}Please check your network and retry. {break}{break}{reference}"
          }
          values={{
            reference: (
              <small>
                <strong>{stripeConnectError.data.reference}</strong>
              </small>
            ),
            break: <br />,
          }}
        />
      );
    case STRIPE_CONNECT_ERROR.REQUEST_TIMED_OUT:
      return (
        <FormattedMessage
          id="payment.stripe_connect.error.request_timed_out"
          defaultMessage={
            "Payment processing timed out due to a temporary connection issue. {break}Please retry the payment. {break}{break}If timeouts persist, check your network connection. Ensure your POS and Reader are on the same network and that the connection is strong. {break}{reference}"
          }
          values={{
            reference: (
              <small>
                <strong>{stripeConnectError.data.reference}</strong>
              </small>
            ),
            break: <br />,
          }}
        />
      );
    case STRIPE_CONNECT_ERROR.READER_CONNECT_ERROR:
      return (
        <FormattedMessage
          id="payment.stripe_connect.error.unable_to_connect_reader_error"
          defaultMessage={
            "Connection issue with {deviceName}. {break}Check your network connection. Ensure your card reader and Delivery Manager are on the same network."
          }
          values={{
            deviceName: <strong>{stripeConnectError.data.deviceId}</strong>,
            break: <br />,
          }}
        />
      );
    case STRIPE_CONNECT_ERROR.PAYMENT_COLLECTION_ERROR:
    case STRIPE_CONNECT_ERROR.PAYMENT_CONFIRMATION_ERROR:
      return <>{stripeConnectError.message}</>;

    default:
      return (
        <FormattedMessage
          id="payment.stripe_connect.generic_error"
          defaultMessage={
            "Sorry, we're unable to process the payment at this time. {break}Please try again later."
          }
          values={{
            break: <br />,
          }}
        />
      );
  }
};

export default TransactionFailReason;
