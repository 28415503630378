import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { filterOrders } from "../../helpers/orderFunctions";
import { currentTabOperations } from "../../state/features/currentTab";
import { pickupOperations } from "../../state/features/pickup";
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";
import NoOrders from "../noOrders";
import Order from "../order";
import { AnimateOrderTransitions } from "../animateOrderTransitions";
import { AnimateTableTransition } from "../animateTableTransitions";
import { useEntryTransitionConfig } from "../../hooks/useEntryTransitionConfig";

interface IPickupNewOrdersProps {
  markOrderReady: any;
  newOrders: any;
  setCurrentTab: any;
}

function PickupNewOrders(props: IPickupNewOrdersProps) {
  const [selecteOrderId, setSelectedOrderId] = useState(0);
  const [disableButton, setDisable] = useState(false);
  const [filterdNewOrders, setFilteredNewOrders] = useState([] as any);
  const { enableEntryTransition } = useEntryTransitionConfig();

  useEffect(() => {
    props.setCurrentTab("pickup.new");
  }, []);

  useEffect(() => {
    setFilteredNewOrders(props.newOrders);
  }, [JSON.stringify(props.newOrders)]);

  const setOrderReady = (orderId: any) => {
    setDisable(true);
    setSelectedOrderId(orderId);
    props
      .markOrderReady(orderId)
      .then(() => {
        setDisable(false);
      })
      .catch((err: AxiosErrorResponse) => {
        console.log(err);
        setDisable(false);
      });
  };

  const onFilterChanges = (filterOption: any) => {
    setFilteredNewOrders(filterOrders(filterOption, props.newOrders));
  };

  return (
    <div className="new-orders">
      <AnimateTableTransition
        isVisited={enableEntryTransition}
        show={filterdNewOrders.length > 0}
        component={({ ref }) => (
          <table
            className="orders-table"
            cellPadding="0"
            cellSpacing="0"
            ref={ref}>
            <thead>
              <tr>
                <th></th>
                <th style={{ width: "180px" }}>
                  <FormattedMessage id="order.no" />.
                </th>
                <th>
                  <FormattedMessage id="order.order_time" />
                </th>
                <th>
                  <FormattedMessage id="order.elapsed" />
                </th>
                <th>
                  <FormattedMessage id="order.cust_or_tel" />
                </th>
                <th>
                  <FormattedMessage id="order.agent" />
                </th>
                <th>
                  <FormattedMessage id="order.amount" />
                </th>
                <th className="text-right"></th>
              </tr>
            </thead>
            <tbody className="orders-list-table-body">
              <AnimateOrderTransitions
                enableEntryTransition={enableEntryTransition}
                orders={filterdNewOrders}
                component={({ item: order }) => (
                  <Order
                    orderType="pickup"
                    orderStatus="new"
                    key={order.order_id}
                    handleOrderState={(orderId: any) => setOrderReady(orderId)}
                    item={order}
                    disableButton={
                      order.order_id === selecteOrderId && disableButton
                    }
                  />
                )}
              />
            </tbody>
          </table>
        )}
      />
      {!filterdNewOrders.length ? <NoOrders /> : null}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  const newOrders = state.order.pickup.new_pickup_orders.orders || [];
  return { newOrders };
};

const mapDispatchToProps = {
  setCurrentTab: currentTabOperations.setCurrentTab,
  markOrderReady: pickupOperations.markOrderReady,
};

export default connect(mapStateToProps, mapDispatchToProps)(PickupNewOrders);
