import * as types from "./types";

const fetchRiderProximity = (data: any) => ({
  type: types.FETCH_RIDER_PROXIMITY,
  payload: data,
});

export default {
  fetchRiderProximity,
};
