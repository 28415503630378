import { ORDER_STATUS } from "../../types/orderStatus";
import { filterLabels } from "../../helpers/orderFunctions";
import { ORDER_FILTERS } from "../../types/filters";

const orderFilterOptions = [
  {
    id: ORDER_FILTERS.ALL,
    name: filterLabels.all_delivery.title,
  },
  {
    id: ORDER_FILTERS.IN_HOUSE,
    name: filterLabels.in_house_delivery.title,
  },
  {
    id: ORDER_FILTERS.PARTNER_DELIVERY,
    name: filterLabels.partner_delivery.title,
  },
];

const filterConfigs = [
  {
    showFilterTab: true,
    orderStatus: ORDER_STATUS.DELIVERY_NEW,
    filterOptions: orderFilterOptions,
    showSearch: false,
  },
  {
    showFilterTab: true,
    orderStatus: ORDER_STATUS.DELIVERY_IN_PROGRESS,
    filterOptions: orderFilterOptions,
    showSearch: false,
  },
  {
    showFilterTab: false,
    orderStatus: ORDER_STATUS.PICKUP_READY,
    filterOptions: [],
    showSearch: true,
  },
];

const getCurrentTabConfigs = (currentTabName: string) => {
  let tabConfig = filterConfigs.find(
    (config) => config.orderStatus === currentTabName
  );
  return tabConfig;
};

export { getCurrentTabConfigs };
