import React from "react";
import { FormattedMessage } from "react-intl";
import { getEnvValueFor } from "../../helpers/utils";

function LoginError() {
  const redirectToPos = () => {
    window.location.href = `${getEnvValueFor(
      "SAPAAD_CORE_BASE_URL"
    )}/sso/dmms` as any;
  };

  return (
    <div className="login-error-container">
      <svg
        height="512"
        className="icon"
        viewBox="0 0 128 128"
        width="512"
        xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="m89.917 75.305a7.759 7.759 0 0 0 7.75-7.75v-.385h6.143a1.751 1.751 0 0 0 1.75-1.75v-23.54a1.751 1.751 0 0 0 -1.75-1.75h-6.143v-.383a7.759 7.759 0 0 0 -7.75-7.747h-21.167v-6.871a10.75 10.75 0 1 0 -9.5 0v6.871h-21.167a7.759 7.759 0 0 0 -7.75 7.75v.383h-6.143a1.751 1.751 0 0 0 -1.75 1.75v23.537a1.751 1.751 0 0 0 1.75 1.75h6.143v.385a7.759 7.759 0 0 0 7.75 7.75h12.167v5.316h-20.058a7.759 7.759 0 0 0 -7.75 7.75v33.129a1.751 1.751 0 0 0 1.75 1.75h79.616a1.751 1.751 0 0 0 1.75-1.75v-33.129a7.759 7.759 0 0 0 -7.75-7.75h-20.058v-5.316zm12.143-31.675v20.04h-4.393v-20.04zm-45.31-28.13a7.25 7.25 0 1 1 7.25 7.25 7.258 7.258 0 0 1 -7.25-7.25zm6 10.671a9.929 9.929 0 0 0 2.5 0v5.829h-2.5zm-36.81 37.499v-20.04h4.393v20.04zm7.893 3.885v-27.808a4.255 4.255 0 0 1 4.25-4.25h22.888.029s.019 0 .029 0h5.942.029s.019 0 .029 0h22.888a4.255 4.255 0 0 1 4.25 4.25v27.808a4.255 4.255 0 0 1 -4.25 4.25h-13.869c-.016 0-.031-.005-.048-.005s-.032 0-.048.005h-23.9c-.016 0-.031-.005-.048-.005s-.032 0-.048.005h-13.873a4.255 4.255 0 0 1 -4.25-4.25zm50.916 52.195h-41.5v-14.437h41.5zm17.309-31.379v31.379h-13.809v-16.187a1.75 1.75 0 0 0 -1.75-1.75h-45a1.751 1.751 0 0 0 -1.75 1.75v16.187h-13.807v-31.379a4.255 4.255 0 0 1 4.25-4.25h67.616a4.255 4.255 0 0 1 4.25 4.25zm-27.808-7.75h-20.5v-5.316h20.5z" />
          <path d="m55.251 49.648a7.75 7.75 0 1 0 -7.751 7.752 7.759 7.759 0 0 0 7.751-7.752zm-7.751 4.252a4.25 4.25 0 1 1 4.25-4.25 4.255 4.255 0 0 1 -4.25 4.25z" />
          <path d="m80.5 57.4a7.75 7.75 0 1 0 -7.75-7.75 7.759 7.759 0 0 0 7.75 7.75zm0-12a4.25 4.25 0 1 1 -4.25 4.25 4.255 4.255 0 0 1 4.25-4.25z" />
          <path d="m51.875 64.875a1.751 1.751 0 0 0 1.75 1.75h20.75a1.75 1.75 0 0 0 0-3.5h-20.75a1.751 1.751 0 0 0 -1.75 1.75z" />
          <path d="m94.808 96.121a4.75 4.75 0 1 0 -4.75-4.75 4.756 4.756 0 0 0 4.75 4.75zm0-6a1.25 1.25 0 1 1 -1.25 1.25 1.251 1.251 0 0 1 1.25-1.25z" />
          <path d="m28.442 91.371a4.75 4.75 0 1 0 4.75-4.75 4.756 4.756 0 0 0 -4.75 4.75zm4.75-1.25a1.25 1.25 0 1 1 -1.25 1.25 1.25 1.25 0 0 1 1.25-1.25z" />
        </g>
      </svg>

      <p className="title">
        <FormattedMessage id="oops" />!
      </p>
      <div className="message">
        <span>
          <FormattedMessage id="login_error" />
        </span>
      </div>
      <button className="btn btn-cta" onClick={redirectToPos}>
        <FormattedMessage id="login" />
      </button>
    </div>
  );
}

export default LoginError;
