import * as Sentry from "@sentry/react";

export const reportExceptionToSentry = ({
  title = "Error",
  message,
  tags = {},
}) => {
  let error = new Error(message);
  error.name = title;
  Object.entries(tags).forEach((tagEntry) => {
    Sentry.setTag(tagEntry[0], tagEntry[1]);
  });
  Sentry.captureException(error);
};
