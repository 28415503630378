import { JWT_REFRESH_TIMER_THRESHOLD_SECONDS } from "../../../helpers/constants";
import * as types from "./types";

const initialState = {
  authorize: false,
  tokenExpiry: null,
  jwtData: null,
  isTokenExpired: false,
  initiateTicker: false,
};

const authReducer = (
  state = initialState,
  action: { payload: any; type: string }
) => {
  switch (action.type) {
    case types.SET_AUTHORIZE_USER:
      return { ...state, authorize: action.payload };
    case types.SET_JWT_DATA:
      let tokenExpiry = action.payload.exp;
      let jwtData = action.payload;
      let updatedState = { ...state, tokenExpiry, jwtData };
      let timeDiff = tokenExpiry - new Date().getTime() / 1000;
      if (timeDiff <= JWT_REFRESH_TIMER_THRESHOLD_SECONDS) {
        return { ...updatedState, isTokenExpired: true, initiateTicker: false };
      }
      return {
        ...updatedState,
        authorize: true,
        isTokenExpired: false,
        initiateTicker: true,
      };
    case types.SET_INITIATE_TICKER:
      return { ...state, initiateTicker: action.payload };
    case types.SET_SESSION_EXPIRED:
      return { ...state, authorize: false };
    case types.SET_TOKEN_EXPIRY:
      return { ...state, tokenExpiry: action.payload };
    default:
      return state;
  }
};

const reducer = authReducer;

export { reducer };
