import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import MapSvg from "../../images/svg";
import { Button } from "../throttleButton";

interface IRiderRosterProps {
  showCompanyRiders: boolean;
  companyRiders: any[];
  partnerRiders: any[];
  onCompanyRiderSelect: (rider: any) => void;
  onShowRiderTracking: (rider: any) => void;
  onPartnerRiderSelect: (partnerRider: any) => void;
  assignCompanyRider: (rider: any) => void;
}

function RidersRoster({
  showCompanyRiders = false,
  companyRiders = [],
  partnerRiders = [],
  onCompanyRiderSelect = (rider) => {},
  onShowRiderTracking = (rider) => {},
  onPartnerRiderSelect = (partnerRider) => {},
  assignCompanyRider = (rider) => {},
}: IRiderRosterProps) {
  const [searchValue, setSearchValue] = useState("");

  const intl = useIntl();

  const companyRidersFilter = useMemo(() => {
    let requiredDrivers = companyRiders.filter((e: any) => {
      return e.name.toLowerCase().includes(searchValue.toLowerCase());
    });
    if (requiredDrivers.length == 0 && searchValue) {
      requiredDrivers.push("No Drivers");
    }
    return requiredDrivers;
  }, [searchValue, companyRiders]);

  const partnerRidersFilter = useMemo(() => {
    let requiredDrivers = partnerRiders.filter((e: any) => {
      return e.name.toLowerCase().includes(searchValue.toLowerCase());
    });
    if (requiredDrivers.length == 0 && searchValue) {
      requiredDrivers.push("No Drivers");
    }
    return requiredDrivers;
  }, [searchValue, partnerRiders]);

  const handleCompanyRiderSelect = (rider: any) => {
    onCompanyRiderSelect(rider);
  };

  const handleShowRiderTracking = (rider: any) => {
    onCompanyRiderSelect(rider);
    onShowRiderTracking(rider);
  };

  const handlePartnerRiderSelect = (partnerRider: any) => {
    onPartnerRiderSelect(partnerRider);
  };

  return (
    <>
      <h2>
        <FormattedMessage id="select.driver" />
      </h2>
      <p>
        <FormattedMessage id="driver.handle" />
      </p>
      {showCompanyRiders ? (
        <>
          <div className="rider-search-wrapper">
            <div className="input-group">
              <div className="input-group-prepend">
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
              </div>
              <input
                type="text"
                className="rider-search"
                placeholder={intl.formatMessage({ id: "search" })}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>
          <h3>
            <FormattedMessage id="own.driver" />
          </h3>
          <ul style={partnerRiders.length > 0 ? {} : { height: "360px" }}>
            {companyRiders.length > 0 &&
            companyRidersFilter[0] != "No Drivers" ? (
              <>
                {(companyRidersFilter.length != 0
                  ? companyRidersFilter
                  : companyRiders
                ).map((rider: any, index: number) => {
                  return (
                    <li
                      key={index}
                      onClick={() => handleCompanyRiderSelect(rider)}
                      className="driver-option">
                      {rider.name}
                      <div className="actions">
                        <Button
                          onClick={() => {
                            handleShowRiderTracking(rider);
                          }}
                          className="dropdown-group-btn show-map-button btn mr-3">
                          <MapSvg />
                        </Button>
                        <Button
                          onClick={() => {
                            handleCompanyRiderSelect(rider);
                            assignCompanyRider(rider);
                          }}
                          className="dropdown-group-btn light-green btn px-4">
                          <FormattedMessage
                            id="assign"
                            defaultMessage="Assign"
                          />
                        </Button>
                      </div>
                    </li>
                  );
                })}
              </>
            ) : (
              <li style={{ pointerEvents: "none" }}>
                <FormattedMessage id="driver.no_rider_available" />
              </li>
            )}
          </ul>
          {partnerRiders.length > 0 ? (
            <>
              <h3 className="text-uppercase">
                {" "}
                <FormattedMessage id="filter.partner_delivery" />
              </h3>
              <ul>
                {partnerRiders.length > 0 &&
                partnerRidersFilter[0] != "No Drivers" ? (
                  <>
                    {(partnerRidersFilter.length != 0
                      ? partnerRidersFilter
                      : partnerRiders
                    ).map((partner: any, index: number) => {
                      return (
                        <li key={index} className="driver-option">
                          {partner.name}
                          <div className="actions">
                            <Button
                              onClick={() => handlePartnerRiderSelect(partner)}
                              className="dropdown-group-btn light-green btn px-4">
                              <FormattedMessage id="assign" />
                            </Button>
                          </div>
                        </li>
                      );
                    })}
                  </>
                ) : (
                  <li style={{ pointerEvents: "none" }}>
                    <FormattedMessage id="logistic_no_partners" />
                  </li>
                )}
              </ul>
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
}
export default RidersRoster;
