import React from "react";
import { useRef } from "react";
import { CSSTransition } from "react-transition-group";

interface IAnimateTableTransitionProps {
  component: any;
  show: boolean;
  isVisited: boolean;
}

const AnimateTableTransition = ({
  component,
  show,
  isVisited,
}: IAnimateTableTransitionProps) => {
  const nodeRef = useRef();
  return (
    <CSSTransition
      in={show}
      nodeRef={nodeRef}
      timeout={{ enter: 0, exit: 200 }}
      mountOnEnter
      classNames={
        isVisited ? "orders-table-animate" : "orders-table-animate-void"
      }
      unmountOnExit>
      {component({ ref: nodeRef })}
    </CSSTransition>
  );
};

export { AnimateTableTransition };
