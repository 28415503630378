import actions from "./actions";

const orderCreated = actions.orderCreated;
const orderUpdated = actions.orderUpdated;
const orderConfirmed = actions.orderConfirmed;
const orderRejected = actions.orderRejected;
const orderVoided = actions.orderVoided;
const orderMarkedReady = actions.orderMarkedReady;
const orderPickUpMarkedReady = actions.orderPickUpMarkedReady;
const orderMarkedDelivered = actions.orderMarkedDelivered;
const orderMarkedCollected = actions.orderMarkedCollected;
const orderRiderAssigned = actions.orderRiderAssigned;
const orderMarkedReprinted = actions.orderMarkedReprinted;
const customerArrived = actions.customerArrived;
const orderMarkedPickedUp = actions.orderMarkedPickedUp;
const orderRiderUnassigned = actions.orderRiderUnassigned;
const orderTipCollected = actions.orderTipCollected;
const orderCompanyRiderAssigned = actions.orderCompanyRiderAssigned;
const orderPartnerRiderAssigned = actions.orderPartnerRiderAssigned;

export default {
  orderCreated,
  orderUpdated,
  orderConfirmed,
  orderRejected,
  orderVoided,
  orderMarkedReady,
  orderPickUpMarkedReady,
  orderRiderAssigned,
  orderMarkedDelivered,
  orderMarkedCollected,
  orderMarkedReprinted,
  customerArrived,
  orderMarkedPickedUp,
  orderRiderUnassigned,
  orderTipCollected,
  orderCompanyRiderAssigned,
  orderPartnerRiderAssigned,
};
