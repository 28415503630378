import { sortBranchesAlphabetically } from "../../../helpers/utils";
import * as types from "./types";

const branchesReducer = (state = [], action: any) => {
  switch (action.type) {
    case types.FETCH_BRANCHES_SUCCESS:
      return sortBranchesAlphabetically(action.payload.data || []);

    default:
      return state;
  }
};

const reducer = branchesReducer;

export default reducer;
