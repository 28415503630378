import * as types from "./types";

const fetchPickupUnconfirmedOrders = (location: any) => ({
  type: types.FETCH_UNCONFIRMED_PICKUP_ORDERS,
  payload: {
    request: {
      method: "get",
      url: location
        ? `/api/v1/orders/pickup/unconfirmed?location_id=${location?.id}`
        : "/api/v1/orders/pickup/unconfirmed",
    },
  },
});

const markPickupUnconfirmedOrderConfirm = (orderId: any, orderSource:any) => ({
  type: types.SET_UNCONFIRMED_PICKUP_ORDER_CONFIRM,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/pickup/unconfirmed/${orderId}/confirm`
    },
  },
  param: { order_id: orderId, order_source: orderSource },
});

const markPickupUnconfirmedOrderReject = (
  orderId: any,
  isAutoCancelled: boolean
) => ({
  type: types.SET_UNCONFIRMED_PICKUP_ORDER_REJECT,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/pickup/unconfirmed/${orderId}/reject`,
      data: { auto_cancelled: isAutoCancelled },
    },
  },
  param: { order_id: orderId },
});

const markPickupUnconfirmedOrderRejectWithReason = (
  orderId: any,
  isAutoCancelled: boolean,
  cancelReasonId: any,
  supervisorPassword:any
) => ({
  type: types.SET_UNCONFIRMED_PICKUP_ORDER_REJECT,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/pickup/unconfirmed/${orderId}/reject`,
      data: {
        auto_cancelled: isAutoCancelled,
        cancel_reason_id: cancelReasonId,
        supervisor_password: supervisorPassword,
      },
    },
  },
  param: { order_id: orderId },
});

const markPickupOrderConfirmedWithPayment = (orderId: any, orderPayments: any) =>({
  type: types.SET_UNCONFIRMED_PICKUP_ORDER_CONFIRM_WITH_PAYMENT,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/pickup/unconfirmed/${orderId}/confirm`,
      data: orderPayments,
    }
  }
})

const fetchNewPickupOrders = (location: any) => ({
  type: types.FETCH_NEW_PICKUP_ORDERS,
  payload: {
    request: {
      method: "get",
      url: location
        ? `/api/v1/orders/pickup/new?location_id=${location.id}`
        : "/api/v1/orders/pickup/new",
    },
  },
});

const markOrderReady = (orderId: any) => ({
  type: types.SET_PICKUP_ORDER_MARK_AS_READY,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/pickup/new/${orderId}/mark-as-ready`,
    },
  },
  param: { order_id: orderId },
});

const fetchReadyPickupOrders = (location: any) => ({
  type: types.FETCH_READY_PICKUP_ORDERS,
  payload: {
    request: {
      method: "get",
      url: location
        ? `/api/v1/orders/pickup/waiting-for-collection?location_id=${location.id}`
        : "/api/v1/orders/pickup/waiting-for-collection",
    },
  },
});

const fetchCollectedPickupOrders = (location: any) => ({
  type: types.FETCH_COLLECTED_PICKUP_ORDERS,
  payload: {
    request: {
      method: "get",
      url: location
        ? `/api/v1/orders/pickup/collected?location_id=${location?.id}`
        : "/api/v1/orders/pickup/collected",
    },
  },
});

const markOrderCollected = (orderId: any, orderPayments: any) => ({
  type: types.SET_ORDER_COLLECTED,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/pickup/waiting-for-collection/${orderId}/mark-as-collected`,
      data: orderPayments,
    },
  },
  param: { order_id: orderId },
});

const setCollectedRefreshRequired = () => ({
  type: types.SET_COLLECTED_ORDERS_REFRESH_REQUIRED,
  payload: [],
});

export default {
  fetchNewPickupOrders,
  markOrderReady,
  fetchReadyPickupOrders,
  markOrderCollected,
  fetchCollectedPickupOrders,
  fetchPickupUnconfirmedOrders,
  markPickupUnconfirmedOrderConfirm,
  markPickupUnconfirmedOrderReject,
  setCollectedRefreshRequired,
  markPickupUnconfirmedOrderRejectWithReason,
  markPickupOrderConfirmedWithPayment,
};
