import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

interface iRadialTimeCountProps {
  auto_reject_in?: any;
  placedTime?: any;
  orderId?: any;
  handleAutoReject?: any;
  limboStateOrders?:any;
}

function RadialTimeCount(props: iRadialTimeCountProps) {
  const [autoRejectInSecs, setAutoRejectInSecs] = useState(0);
  const [differenceInTime, setDifferenceInTime] = useState(60);
  const [startTimer, setStartTimer] = useState(false);
  const [timer, setTimer] = useState((<div></div>) as any);
  const [isLimboState, setIsLimboState] = useState(false);

  useEffect(() => {
    let placed_at_time: any = new Date(props.placedTime);
    let reject_time: any = new Date(
      placed_at_time.getTime() + props.auto_reject_in * 60000
    );
    let current_time: any = new Date();
    var differenceInTimeCopy = (reject_time - current_time) / 1000;
    if (differenceInTimeCopy < 60 && differenceInTimeCopy > 0) {
      setDifferenceInTime(differenceInTimeCopy);
    }
    if (differenceInTimeCopy <= 0 && !isLimboState) {
      props.handleAutoReject(props.orderId);
    }

    let timeout = setTimeout(() => {
      setStartTimer(false);
      setTimer(
        <div className="timer">
          <div className="value red"><FormattedMessage id="order.rejected"/></div>
        </div>
      );
      if (props.orderId && !isLimboState) {
        props.handleAutoReject(props.orderId);
      }
    }, differenceInTimeCopy * 1000);
    let elapsedSeconds: any;
    let timeIntervel = setInterval(() => {
      let current_time: any = new Date();
      var differenceInTime = Math.abs((current_time - reject_time) / 1000);
      let elapsedHours: any = differenceInTime / (60 * 60);
      var elapsedMinutes: any = (elapsedHours * 60) % 60;
      elapsedSeconds = (elapsedMinutes * 60) % 60;
      if (parseInt(elapsedHours) > 0) {
        setTimer(
          <div className="timer">
            <div className="value">{parseInt(elapsedHours)}</div>
            <div className="text"><FormattedMessage id="time.hrs"/></div>
          </div>
        );
      } else if (parseInt(elapsedMinutes) > 0) {
        setTimer(
          <div className="timer">
            <div className="value">{parseInt(elapsedMinutes + 1)}</div>
            <div className="text"><FormattedMessage id="time.mns"/></div>
          </div>
        );
      } else {
        setTimer(
          <div className="timer">
            <div className="value">{parseInt(elapsedSeconds)}</div>
            <div className="text"><FormattedMessage id="time.secs"/></div>
          </div>
        );
        setStartTimer(true);
      }
    }, 1000);
    setAutoRejectInSecs(parseInt(elapsedSeconds));
    return () => {
      clearInterval(timeIntervel);
      clearTimeout(timeout);
    };
  }, [props.placedTime]);

  useEffect(() => {
    if (props.limboStateOrders.length) {
      if (props.limboStateOrders.includes(props.orderId)) {
        setIsLimboState(true);
      }
    }
  }, [props.limboStateOrders.length]);

  return (
    <div className="radial-time-count">
      <div className="timer-wrapper">
        <CountdownCircleTimer
          isPlaying={startTimer}
          strokeWidth={3}
          trailColor={startTimer ? "#ff0000" : "#aaaaaa"}
          size={55}
          initialRemainingTime={autoRejectInSecs}
          duration={differenceInTime}
          colors={startTimer ? [["#73c373"], ["#ff0000"]] : "#aaaaaa"}
          onComplete={() => [true, 1000]}
        >
          {timer}
        </CountdownCircleTimer>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let limboStateOrders = state.limboStateOrders;
  return { limboStateOrders };
};

export default connect(mapStateToProps)(RadialTimeCount);
