import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";

// Import translation data for each supported locale
import EnglishLocale from "../locales/en.json";

// Used to send locale data and methods to update locale data in all components
export const LocaleContext = React.createContext();

function loadMessages(locale: any) {
  switch (locale) {
    case "ar":
      return import("../locales/ar.json");
    case "en":
      return import("../locales/en.json");
    case "ms":
      return import("../locales/ms.json");
    case "zh":
      return import("../locales/zh.json");
    default:
      return import("../locales/en.json");
  }
}

interface ILocaleWrapperProps {
  company: any;
  children?: any;
}

const LocaleWrapper = (props: ILocaleWrapperProps) => {
  const [locale, setLocale] = useState("en");
  const [messages, setMessages] = useState(EnglishLocale);

  useEffect(() => {
    if (props.company.user_locale) {
      updateLocale(props.company.user_locale);
    } else {
      updateLocale("en");
    }
  }, [props.company]);

  const updateLocale = (userLocale: any) => {
    setLocale(userLocale);
    loadMessages(userLocale).then((messages: any) => {
      setMessages(messages);
    });

    // Update RTL or LTR based on selected locale
    document.getElementsByTagName("html")[0].setAttribute("lang", userLocale);
    localStorage.setItem("locale", userLocale);
    // if (userLocale == "ar") {
    //   document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    //   document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
    //   document.getElementsByTagName("body")[0].classList.add("rtl");
    // } else {
    //   document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    //   document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    //   document.getElementsByTagName("body")[0].classList.remove("rtl");
    // }
  };

  return (
    <LocaleContext.Provider value={{ locale, updateLocale }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

const mapStateToProps = (state: any) => {
  let company = state.company.company_details;

  return {
    company,
  };
};

export default connect(mapStateToProps)(LocaleWrapper);
