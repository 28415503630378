import { removeLoyaltyPayment } from "../../../helpers/orderFunctions";
import * as types from "./types";

const printSettingsReducer = (state = [], action: any) => {
  switch (action.type) {
    case types.FETCH_PRINT_SETTINGS_SUCCESS:
      return action.payload.data || [];

    default:
      return state;
  }
};

const reducer = printSettingsReducer;

export default reducer;
