import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import PaymentPendingDark from "../../../../images/payment-status/payment-animation-dark.gif";
import PaymentPending from "../../../../images/payment-status/payment-animation.gif";
import PaymentErrorGeneric from "../../../../images/payment-status/payment-error-generic.png";
import { PAYMENT_STATUS } from "../../../../types/payments";
import { useAppStateSelector } from "../../../../hooks/useAppStateSelector";
import { STRIPE_CONNECT_ERROR } from "../../utils";
import TransactionFailReason from "./transactionFailReason";

const PaymentSuccessAnimation = () => {
  return (
    <svg
      id="successAnimation"
      className="transaction-status-animation success"
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 70 70">
      <path
        id="successAnimationResult"
        fill="#D8D8D8"
        d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"></path>
      <circle
        id="successAnimationCircle"
        cx="35"
        cy="35"
        r="24"
        stroke="#979797"
        stroke-width="2"
        stroke-linecap="round"
        fill="transparent"></circle>
      <polyline
        id="successAnimationCheck"
        stroke="#979797"
        stroke-width="2"
        points="23 34 34 43 47 27"
        fill="transparent"></polyline>
    </svg>
  );
};

const StripeConnectTxnStatusView = ({
  txnStatus,
  showCancelTxnCta,
  stripeConnectError,
  onTxnCancelCtaClick,
  onRetryCtaClick,
  onCloseTxnStatusViewClick,
}: any) => {
  const theme = useAppStateSelector((state) => state.theme);

  const showCloseTxnViewCta = useMemo(
    () =>
      [
        STRIPE_CONNECT_ERROR.GENERIC_ERROR,
        STRIPE_CONNECT_ERROR.DISCONNECTION_ERROR,
        STRIPE_CONNECT_ERROR.DISCOVERY_ERROR,
        STRIPE_CONNECT_ERROR.NO_READERS,
        STRIPE_CONNECT_ERROR.READER_CONNECT_ERROR,
        STRIPE_CONNECT_ERROR.READER_NOT_FOUND,
      ].includes(stripeConnectError?.type),
    [stripeConnectError]
  );

  const showRetryCta = useMemo(
    () =>
      [
        STRIPE_CONNECT_ERROR.REQUIRES_CONFIRMATION,
        STRIPE_CONNECT_ERROR.PAYMENT_CONFIRMATION_ERROR,
        STRIPE_CONNECT_ERROR.REQUEST_TIMED_OUT,
      ].includes(stripeConnectError?.type),
    [stripeConnectError]
  );

  return (
    <div className="payment-intent-container px-2 py2">
      <div className="transaction-status-container">
        {txnStatus === PAYMENT_STATUS.IN_PROGRESS ? (
          <>
            <img
              src={theme === "light" ? PaymentPending : PaymentPendingDark}
              alt="payment-in-progress"
              className="transaction-status-animation"
            />
            <h3 className="transaction-status-heading">
              <FormattedMessage id="payment.intent_pending_heading" />
            </h3>
            <p className="transaction-status-sub-heading">
              <FormattedMessage id="payment.intent_pending_sub_heading" />
            </p>
            <div className="info-help-section">
              {showCancelTxnCta ? (
                <button
                  className="btn btn-outline-secondary"
                  style={{ borderColor: "#da4f49", color: "#da4f49" }}
                  onClick={onTxnCancelCtaClick}>
                  <FormattedMessage
                    id="cancelTransaction"
                    defaultMessage="Cancel"
                  />
                </button>
              ) : null}
            </div>
          </>
        ) : null}
        {txnStatus === PAYMENT_STATUS.PAID && (
          <>
            <PaymentSuccessAnimation />
            <h3 className="transaction-status-heading payment-success">
              <FormattedMessage
                id="payment.intent_success_heading"
                defaultMessage="Success"
              />
            </h3>
            <p className="transaction-status-sub-heading">
              <FormattedMessage id="payment.intent_success_sub_heading" />
            </p>
          </>
        )}
        {txnStatus === PAYMENT_STATUS.FAILED && (
          <>
            <img
              src={PaymentErrorGeneric}
              alt="payment-failed"
              className="transaction-status-icon failed"
            />
            <h2 className="txn-status-title">
              <FormattedMessage
                id="payment.stripe_connect.error.title"
                defaultMessage="Oops!"
              />
            </h2>
            <h3
              className={`transaction-status-heading payment-failed ${theme}`}>
              <TransactionFailReason stripeConnectError={stripeConnectError} />
            </h3>
            <div className="info-help-section">
              {showCloseTxnViewCta ? (
                <button
                  className="close-overlay btn"
                  onClick={onCloseTxnStatusViewClick}>
                  <FormattedMessage
                    id="payment.stripe_connect.error.go_back"
                    defaultMessage="Go Back"
                  />
                </button>
              ) : null}
              {showRetryCta ? (
                <button className="close-overlay btn" onClick={onRetryCtaClick}>
                  <FormattedMessage id="payment.retry" defaultMessage="Retry" />
                </button>
              ) : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default StripeConnectTxnStatusView;
