import React from "react";
import { FormattedMessage } from "react-intl";

const orderStatus = {
  unconfirmed: {
    online: {
      title: <FormattedMessage id="status.received" />,
    },
    call_center: {
      title: <FormattedMessage id="tab.unconfirmed" />,
    },
    walk_in: {
      title: <FormattedMessage id="status.received" />,
    },
  },
  created: {
    online: {
      title: <FormattedMessage id="status.new" />,
    },
    call_center: {
      title: <FormattedMessage id="status.new" />,
    },
    walk_in: {
      title: <FormattedMessage id="status.accepted" />,
    },
  },
  marked_as_ready: {
    online: {
      title: <FormattedMessage id="status.being_picked" />,
    },
    call_center: {
      title: <FormattedMessage id="status.ready_to_pickup" />,
    },
    walk_in: {
      title: <FormattedMessage id="status.ready_to_collect" />,
    },
  },
  rider_assigned: {
    online: {
      title: <FormattedMessage id="status.on_the_way" />,
    },
    call_center: {
      title: <FormattedMessage id="logistic.del_inprogress" />,
    },
    walk_in: {
      title: <FormattedMessage id="logistic.del_inprogress" />,
    },
  },
  marked_as_picked_up: {
    online: {
      title: <FormattedMessage id="logistic.del_inprogress" />,
    },
    call_center: {
      title: <FormattedMessage id="logistic.del_inprogress" />,
    },
    walk_in: {
      title: <FormattedMessage id="logistic.del_inprogress" />,
    },
  },
  marked_as_delivered: {
    online: {
      title: <FormattedMessage id="tab.delivered" />,
    },
    call_center: {
      title: <FormattedMessage id="tab.delivered" />,
    },
    walk_in: {
      title: <FormattedMessage id="tab.delivered" />,
    },
  },
  marked_as_collected: {
    online: {
      title: <FormattedMessage id="tab.collected" />,
    },
    call_center: {
      title: <FormattedMessage id="tab.collected" />,
    },
    walk_in: {
      title: <FormattedMessage id="tab.collected" />,
    },
  },
  order_updated: {
    online: {
      title: <FormattedMessage id="status.updated" />,
    },
    call_center: {
      title: <FormattedMessage id="status.updated" />,
    },
    walk_in: {
      title: <FormattedMessage id="status.updated" />,
    },
  },
  marked_as_reprinted: {
    online: {
      title: <FormattedMessage id="status.reprinted" />,
    },
    call_center: {
      title: <FormattedMessage id="status.reprinted" />,
    },
    walk_in: {
      title: <FormattedMessage id="status.reprinted" />,
    },
  },
  marked_as_confirmed: {
    online: {
      title: <FormattedMessage id="status.preparing" />,
    },
    call_center: {
      title: <FormattedMessage id="status.preparing" />,
    },
    walk_in: {
      title: <FormattedMessage id="status.accepted" />,
    },
  },
} as any;

export default orderStatus;
