import React, { useMemo } from "react";
import { ReactElement, createRef, useEffect, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

interface IAnimateOrderTransitionsProps {
  orders: any[];
  component({ item }: { item: any }): ReactElement;
  enableEntryTransition: boolean;
}

const AnimateOrderTransitions = ({
  orders,
  component,
  enableEntryTransition
}: IAnimateOrderTransitionsProps) => {

  const ordersWithRef = useMemo(
    () =>
      orders.map((o) => ({
        ...o,
        id: o?.order_id,
        ref: createRef(),
      })),
    [JSON.stringify(orders)]
  );

  return (
    <TransitionGroup className="list" component={null}>
      {ordersWithRef.map((item: any) => (
        <CSSTransition
          key={item.id}
          nodeRef={item.ref}
          timeout={{ enter: 0, exit: 200 }}
          in={enableEntryTransition}
          mountOnEnter
          classNames="order-item"
          unmountOnExit>
          {component({ item })}
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export { AnimateOrderTransitions };
