import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { currentTabOperations } from "../../state/features/currentTab";
import NoOrders from "../noOrders";
import Order from "../order";
import { pickupOperations } from "../../state/features/pickup";
import Swal from "sweetalert2";
import { getEnvValueFor } from "../../helpers/utils";
import { validateCollectedPayments } from "../../helpers/orderFunctions";
import { FormattedMessage, useIntl } from "react-intl";
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";
import { AnimateOrderTransitions } from "../animateOrderTransitions";
import { AnimateTableTransition } from "../animateTableTransitions";
import { useEntryTransitionConfig } from "../../hooks/useEntryTransitionConfig";

interface IPickupUnconfirmedOrdersProps {
  unconfirmedOrders: any;
  setCurrentTab: any;
  markOrderConfirm?: any;
  markOrderReject?: any;
  markOrderRejectWithReason?: any;
  markPickupOrderConfirmedWithPayment?: any;
  company: any;
}

function PickupUnconfirmedOrders(props: IPickupUnconfirmedOrdersProps) {
  const [selectedOrderId, setSelectedOrderId] = useState(0);
  const [disableButton, setDisable] = useState(false);
  const [hideDetails, setHideDetails] = useState(false);
  const intl = useIntl();
  const formattedMessage = (formatId: any) => {
    const translatedMessage = intl.formatMessage({ id: formatId });
    return translatedMessage;
  };
  const { enableEntryTransition } = useEntryTransitionConfig();

  useEffect(() => {
    props.setCurrentTab("pickup.unconfirmed");
  }, []);

  const handleApprove = (orderId: any, orderSource: any) => {
    setDisable(true);
    setSelectedOrderId(orderId);
    setHideDetails(false);
    props
      .markOrderConfirm(orderId, orderSource)
      .then((res: any) => {
        setDisable(false);
        setHideDetails(true);
      })
      .catch((err: AxiosErrorResponse) => {
        console.log(err);
        setDisable(false);
      });
  };
  const handleReject = (
    orderId: any,
    cancelReasonId: any,
    supervisorPassword: any
  ) => {
    setDisable(true);
    setSelectedOrderId(orderId);
    setHideDetails(false);
    props
      .markOrderRejectWithReason(
        orderId,
        false,
        cancelReasonId,
        supervisorPassword
      )
      .then((res: any) => {
        setHideDetails(true);
        setDisable(false);
      })
      .catch((err: any) => {
        if (
          err.error.response !== undefined &&
          err.error.response.status === 422
        ) {
          setDisable(false);
          let text: any = formattedMessage("global.password_invalid");
          Swal.fire({
            icon: "warning",
            text: text,
          });
        }
      });
  };

  const handleAutoReject = (orderId: any) => {
    props
      .markOrderReject(orderId, true)
      .catch((err: AxiosErrorResponse) => console.log(err));
  };

  const handleConfirmWithPayment = (orderId: any, orderPayments: any) => {
    setDisable(true);
    setSelectedOrderId(orderId);
    setHideDetails(false);
    let validationEnabledCompanies: any = JSON.parse(
      getEnvValueFor("VALIDATION_ENABLED_COMPANIES") || "[]"
    );
    if (validationEnabledCompanies?.includes(props.company.company_id)) {
      console.debug("[APP] Initiating payment validation");
      let order: any = null;
      let orderIndex = props.unconfirmedOrders.findIndex(
        (order: any) => order.order_id === orderId
      );
      if (orderIndex !== -1) {
        order = props.unconfirmedOrders[orderIndex];
        validateCollectedPayments(order, orderPayments, () => {
          props
            .markPickupOrderConfirmedWithPayment(orderId, orderPayments)
            .then((response: any) => {
              setHideDetails(true);
              setDisable(false);
            })
            .catch((err: AxiosErrorResponse) => {
              console.log(err);
              setDisable(false);
            });
        });
      }
    } else {
      props
        .markPickupOrderConfirmedWithPayment(orderId, orderPayments)
        .then((response: any) => {
          setHideDetails(true);
          setDisable(false);
        })
        .catch((err: AxiosErrorResponse) => {
          console.log(err);
          setDisable(false);
        });
    }
  };

  return (
    <div className="unconfirmed-orders">
      <AnimateTableTransition
        isVisited={enableEntryTransition}
        show={props.unconfirmedOrders.length > 0}
        component={({ ref }) => (
          <table
            className="orders-table"
            cellPadding="0"
            cellSpacing="0"
            ref={ref}>
            <thead>
              <tr>
                <th></th>
                <th style={{ width: "180px" }}>
                  <FormattedMessage id="order.no" />.
                </th>
                <th className="orderTakenLabel">
                  <FormattedMessage id="order.taken_at" />
                </th>
                <th>
                  <FormattedMessage id="order.cust_or_tel" />
                </th>
                <th>
                  <FormattedMessage id="order.amount" />
                </th>
                <th>
                  <FormattedMessage id="order.auto_rejects" />
                </th>
                <th className="text-right"></th>
              </tr>
            </thead>
            <tbody className="orders-list-table-body">
              {props.unconfirmedOrders.length > 0 ? (
                <AnimateOrderTransitions
                  enableEntryTransition={enableEntryTransition}
                  orders={props.unconfirmedOrders}
                  component={({ item }) => (
                    <Order
                      handleApprove={handleApprove}
                      handleReject={(
                        orderId: any,
                        cancelReasonId: any,
                        supervisorPassword: any
                      ) =>
                        handleReject(
                          orderId,
                          cancelReasonId,
                          supervisorPassword
                        )
                      }
                      handleAutoReject={handleAutoReject}
                      orderType="unconfirmed"
                      orderStatus="unconfirmed"
                      handleOrderState={handleConfirmWithPayment}
                      key={item.order_id}
                      disableButton={
                        item.order_id === selectedOrderId && disableButton
                      }
                      item={item}
                      hideDetails={hideDetails}
                    />
                  )}
                />
              ) : null}
            </tbody>
          </table>
        )}
      />
      {!props.unconfirmedOrders.length ? <NoOrders /> : null}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let unconfirmedOrders =
    state.order.pickup.unconfirmed_pickup_orders.orders || [];
  let company = state.company.company_details;

  return { unconfirmedOrders, company };
};

const mapDispatchToProps = {
  setCurrentTab: currentTabOperations.setCurrentTab,
  markOrderConfirm: pickupOperations.markPickupUnconfirmedOrderConfirm,
  markOrderReject: pickupOperations.markPickupUnconfirmedOrderReject,
  markOrderRejectWithReason:
    pickupOperations.markPickupUnconfirmedOrderRejectWithReason,
  markPickupOrderConfirmedWithPayment:
    pickupOperations.markPickupOrderConfirmedWithPayment,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PickupUnconfirmedOrders);
