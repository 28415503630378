import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { formatDecimal } from "../../helpers/itemCalculations";
import { formatTime } from "../../helpers/orderFunctions";
import { homeDeliveryOperations } from "../../state/features/homeDelivery";
import DriverOrderDetails from "../driverOrderDetails";

interface DeliveredDriverProps {
  driver: any;
  fetchDeliveredDriverOrderDetails?: any;
  deliveredDriverOrdersDetails?: any;
  isExpandable: boolean;
  handleExpand: any;
  currency?: any;
}

function DeliveredDriver(props: DeliveredDriverProps) {
  const [showDetails, setShowDetails] = useState(false);
  const [driver, setDriver] = useState({} as any);

  useEffect(() => {
    setDriver(props.driver);
  }, [props.driver]);

  useEffect(() => {
    if (props.isExpandable) {
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  }, [props.isExpandable]);

  const fetchDriverOrderDetails = (driverId: any) => {
    props.handleExpand(driverId);
  };

  const collapseOrderDetails = () => {
    setShowDetails(false);
    props.handleExpand(0);
  };

  const covertTimeIntoMinutes = (time: any) => {
    let minutes = Math.floor(time / 60);
    return minutes;
  };

  return (
    <React.Fragment>
      <tr>
        <td>
          <p
            className="driver-name"
            onClick={
              showDetails
                ? () => collapseOrderDetails()
                : () => fetchDriverOrderDetails(driver.id)
            }
          >
            {driver.name}
            <span>
              <FontAwesomeIcon
                className="ml-2"
                size="xs"
                icon={showDetails ? faChevronUp : faChevronDown}
              />
            </span>
          </p>
        </td>
        <td className="text-center">
          <p className="order-time total-orders">{driver.total_orders}</p>
        </td>
        <td className="text-right">
          <p className="amount order-time text-right">
            {formatDecimal(driver.total_amount) + " " + props.currency}
          </p>
        </td>
        <td className="text-center">
          <p className="order-time avg-time">
            {formatTime(driver.average_delivery_time)}
          </p>
        </td>
      </tr>

      {props.deliveredDriverOrdersDetails.length ? (
        showDetails ? (
          <tr>
            <td colSpan={5} className="all-delivered-orders">
              <DriverOrderDetails driverDetails={driver} driverId={driver.id} />
            </td>
          </tr>
        ) : null
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => {
  let deliveredOrders = state.order.home_delivery.delivered_orders.orders || [];
  let deliveredDriverOrdersDetails =
    state.order.home_delivery.delivered_orders.orders || [];
  let currency = state.company.company_details.currency;

  return { deliveredOrders, deliveredDriverOrdersDetails, currency };
};

const mapDispatchToProps = {
  fetchDeliveredDriverOrderDetails:
    homeDeliveryOperations.fetchDeliveredDriverOrderDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveredDriver);
