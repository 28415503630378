import { combineReducers } from "redux";
import * as types from "./types";

const driverReducer = (state = [], action: any) => {
  switch (action.type) {
    case types.FETCH_DRIVERS_SUCCESS:
      return action.payload.data || [];

    default:
      return state;
  }
};

const reducer = combineReducers({
  company_drivers: driverReducer,
});

export default reducer;
