import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { homeDeliveryOperations } from "../../state/features/homeDelivery";
import Order from "../order";
import OrdersFilterSection from "../ordersFilterSection";
import NoOrders from "../noOrders";
import { currentTabOperations } from "../../state/features/currentTab";
import {
  filterOrders,
  getLogisticsVisibilityConfig,
  groupOrders,
  filterLabels,
} from "../../helpers/orderFunctions";
import { FormattedMessage } from "react-intl";
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";
import { AnimateOrderTransitions } from "../animateOrderTransitions";
import { groupOrdersByDeliveryArea } from "../../helpers/utils";
import { AnimateTableTransition } from "../animateTableTransitions";
import { AnimateDeliveryAreaTransition } from "../animateDeliveryAreaTransitions";
import { useEntryTransitionConfig } from "../../hooks/useEntryTransitionConfig";

interface IWaitingForCollectionOrdersProps {
  assignDriver: any;
  readyOrders: any;
  selectedBranch: any;
  logisticsPartnerDrivers: any;
  company: any;
  setCurrentTab: any;
  refreshRequired: any;
  logisticsPartners: any[];
  markOrderPickedUp: any;
  riderAppEnabled?: boolean;
  newFeatureEnabled: boolean;
}

function WaitingForCollectionOrders(props: IWaitingForCollectionOrdersProps) {
  const [selecteOrderId, setSelectedOrderId] = useState(0);
  const [disableButton, setDisable] = useState(false);
  const [selectedRider, setSelectedRider] = useState(null);
  const [filterOption, setFilterOption] = useState(
    filterLabels["all_delivery"].title
  );
  const { enableEntryTransition } = useEntryTransitionConfig();

  useEffect(() => {
    props.setCurrentTab("homeDelivery.ready");
  }, []);

  const filterdReadyOrders = useMemo(() => {
    return groupOrders(filterOrders(filterOption, props.readyOrders));
  }, [JSON.stringify(props.readyOrders), filterOption]);

  const logisticsControlColumn = useMemo(
    () =>
      getLogisticsVisibilityConfig(
        props.readyOrders,
        props.logisticsPartners,
        props.riderAppEnabled
      ),
    [
      JSON.stringify(props.readyOrders),
      props.logisticsPartners,
      props.riderAppEnabled,
    ]
  );

  const assignDriverToOrder = (orderId: any, riderId?: any) => {
    setDisable(true);
    setSelectedOrderId(orderId);
    if (riderId) {
      props
        .markOrderPickedUp(orderId)
        .catch((err: AxiosErrorResponse) => console.log(err));
    } else {
      props
        .assignDriver(orderId, selectedRider?.id)
        .then(() => {
          setDisable(false);
        })
        .catch((err: AxiosErrorResponse) => {
          console.log(err);
          setDisable(false);
        });
    }
  };

  const hasOrders = useMemo(
    () => Object.keys(filterdReadyOrders).length > 0,
    [JSON.stringify(filterdReadyOrders)]
  );

  const ordersByDeliveryArea = groupOrdersByDeliveryArea(filterdReadyOrders);

  return (
    <div className="waiting-for-collection-orders">
      <OrdersFilterSection
        orders={props.readyOrders}
        orderType="homeDelivery"
        orderStatus="ready"
        onFilterChanges={(option: any) => setFilterOption(option)}
        selectedRider={selectedRider}
        setSelectedRider={setSelectedRider}
        newRiderSelection={props.newFeatureEnabled}
      />
      <AnimateTableTransition
        isVisited={enableEntryTransition}
        show={hasOrders}
        component={({ ref }) => (
          <table
            className="orders-table"
            cellPadding="0"
            cellSpacing="0"
            ref={ref}>
            <thead>
              <tr>
                <th></th>
                <th style={{ width: "180px" }}>
                  <FormattedMessage id="order.no" />.
                </th>
                <th className="orderTakenLabel">
                  <FormattedMessage id="order.taken_at" />
                </th>
                <th>
                  <FormattedMessage id="order.elapsed_time" />
                </th>
                <th className="text-left">
                  <FormattedMessage id="order.location" />
                </th>
                <th>
                  <FormattedMessage id="order.amount" />
                </th>
                {logisticsControlColumn.show ? (
                  props.riderAppEnabled ? (
                    <th className="text-center">
                      <FormattedMessage id="order.riders" />
                    </th>
                  ) : (
                    <>
                      <th className="text-center">
                        <FormattedMessage id="order.partner_drivers" />
                      </th>
                      <th>&nbsp;</th>
                    </>
                  )
                ) : (
                  <th>&nbsp;</th>
                )}
              </tr>
            </thead>
            {ordersByDeliveryArea.map((item: any) => (
              <AnimateDeliveryAreaTransition
                key={item.deliveryArea}
                show={Boolean(item.orders.length)}
                component={({ areaRef }) => (
                  <tbody
                    key={item.deliveryArea}
                    className="orders-list-table-body"
                    ref={areaRef}>
                    <tr className="location-header">
                      <td colSpan={8}>
                        {item.deliveryArea == "null"
                          ? "N/A"
                          : item.deliveryArea}
                      </td>
                    </tr>
                    <AnimateOrderTransitions
                      enableEntryTransition={enableEntryTransition}
                      orders={item.orders}
                      component={({ item: order }) => (
                        <Order
                          orderType="homeDelivery"
                          orderStatus="ready"
                          key={order.order_id}
                          disableButton={
                            order.order_id === selecteOrderId && disableButton
                          }
                          handleOrderState={(orderId: any, riderId?: any) =>
                            assignDriverToOrder(orderId, riderId)
                          }
                          item={order}
                          showEmptyCell={logisticsControlColumn.show}
                          selectedRider={selectedRider}
                          setSelectedRider={setSelectedRider}
                          newRiderSelection={props.newFeatureEnabled}
                        />
                      )}
                    />
                  </tbody>
                )}
              />
            ))}
          </table>
        )}
      />
      {!hasOrders ? <NoOrders /> : null}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let readyOrders = state.order.home_delivery.ready_orders.orders || [];
  let selectedBranch = state.company?.selected_branch;
  let logisticsPartnerDrivers = state.drivers.logistics_partner_drivers || [];
  let company = state.company.company_details;
  let refreshRequired = state.order.home_delivery.ready_orders.refresh_required;
  let logisticsPartners = state.logisticPartners;
  let riderAppEnabled = state.company.company_details.rider_app_enabled;
  let newFeatureEnabled = state.company.company_details.rider_assign_through_popup

  return {
    readyOrders,
    selectedBranch,
    logisticsPartnerDrivers,
    company,
    refreshRequired,
    logisticsPartners,
    riderAppEnabled,
    newFeatureEnabled
  };
};

const mapDispatchToProps = {
  assignDriver: homeDeliveryOperations.assignDriver,
  markOrderPickedUp: homeDeliveryOperations.markOrderPickedUp,
  setCurrentTab: currentTabOperations.setCurrentTab,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WaitingForCollectionOrders);
