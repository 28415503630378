import * as types from "./types";

const authorizeUser = (value: boolean) => ({
  type: types.SET_AUTHORIZE_USER,
  payload: value,
});

const setInitiateTicker = (value: boolean) => ({
  type: types.SET_INITIATE_TICKER,
  payload: value,
});

const setJwtData = (value: any) => ({
  type: types.SET_JWT_DATA,
  payload: value,
});

const setSessionExpired = () => ({
  type: types.SET_SESSION_EXPIRED,
});

export { authorizeUser, setInitiateTicker, setJwtData, setSessionExpired };
