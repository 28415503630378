import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import {
  calculateSubtotal,
  calculateTax,
  formatDecimal,
} from "../../../helpers/itemCalculations";
import { updateOrderTotalAmount } from "../../../helpers/orderFunctions";

interface iOrderItemsDetailsProps {
  order: any;
  tax_inclusive?: any;
  currency?: any;
}

function OrderItemsDetails(props: iOrderItemsDetailsProps) {
  const [orderItems, setOrderItems] = useState([] as any);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [loyaltyRedeemedValue, setloyaltyRedeemedValue] = useState(0);

  useEffect(() => {
    setOrderItems(addModifiersToItems(props.order.order_extended_info.items));
    getTotalDiscounts(props.order.order_extended_info.items);
    if (props.order.order_extended_info.payments.length) {
      props.order.order_extended_info.payments.map((payment: any) => {
        if (payment.name === "Loyalty") {
          setloyaltyRedeemedValue(payment.amount);
        }
      });
    }
  }, [props.order]);

  const addModifiersToItems = (items: any) => {
    let parentItems = items.filter(
      (item: any) => item.order_item_parent_id === 0 && item.deleted === false
    );
    parentItems.map((parentItem: any) => {
      parentItem.modifiers = [];
    });

    items.map((item: any) => {
      parentItems.map((parentItem: any) => {
        if (
          item.order_item_parent_id === parentItem.order_item_id &&
          item.deleted === false
        ) {
          parentItem.modifiers.push(item);
        }
      });
    });
    return parentItems;
  };

  const getModifierItemSubprice = (item: any) => {
    let amount: any = item.amount;
    item.modifiers.map((modifier: any) => {
      amount += modifier.amount;
    });
    return formatDecimal(amount);
  };

  const getTotalDiscounts = (items: any) => {
    let discountAppliedItems = items.filter(
      (item: any) => item.discount_amount !== null
    );
    let total_discount = 0;
    discountAppliedItems.map((item: any) => {
      total_discount += item.discount_amount;
    });
    setTotalDiscount(total_discount);
  };

  useEffect(() => {
    setTaxTotal(
      calculateTax(orderItems, props.order.order_extended_info.surcharges)
    );
  }, [orderItems]);

  return (
    <div className="order-details-table-container">
      <div className="order-details-table">
        <div className="header">
          <div className="header-item">
            <FormattedMessage id="order_popup_item_name" />
          </div>
          <div className="header-item text-right">
            <FormattedMessage id="order_popup_qnty" />
          </div>
          <div className="header-item text-center">
            <FormattedMessage id="order_popup_price" />
          </div>
          <div className="header-item text-right">
            <FormattedMessage id="order_popup_sub_price" />
          </div>
        </div>
        <div className="content-wrapper">
          {orderItems.length
            ? orderItems
                .map((orderItem: any, index: any) => {
                  return (
                    <div className="content-row" key={index}>
                      <div className="content-item">
                        {orderItem.name}
                        {orderItem.discount_name ? (
                          <div className="item-discount">
                            <span>
                              <FormattedMessage id="order_popup_discount" />:
                            </span>{" "}
                            {orderItem.discount_name}
                          </div>
                        ) : null}
                        {orderItem.modifiers.length ? (
                          <div className="modifiers-list">
                            <ul>
                              {orderItem.modifiers

                                .map((modifierItem: any, index: any) => {
                                  return (
                                    <li key={index} className="modifier-item">
                                      {modifierItem.qty_dec /
                                        orderItem.qty_dec >
                                      1
                                        ? `${
                                            modifierItem.qty_dec /
                                            orderItem.qty_dec
                                          }x `
                                        : null}
                                      {modifierItem.name}
                                      <span>
                                        {formatDecimal(modifierItem.amount)}
                                      </span>
                                    </li>
                                  );
                                })
                                .reverse()}
                            </ul>
                          </div>
                        ) : null}
                        {orderItem.note ? (
                          <div className="item-note">
                            <FormattedMessage id="order_popup_note" />:{" "}
                            {orderItem.note}
                          </div>
                        ) : null}
                      </div>
                      <div className="content-item text-center">
                        {orderItem.qty_dec}
                      </div>
                      <div className="content-item">
                        {formatDecimal(orderItem.price)}
                      </div>
                      <div
                        className={
                          orderItem.discount_name
                            ? "content-item text-right"
                            : "content-item text-right"
                        }
                      >
                        {orderItem.discount_name ? (
                          <p>
                            {orderItem.modifiers.length
                              ? formatDecimal(
                                  getModifierItemSubprice(orderItem) -
                                    orderItem.discount_amount
                                )
                              : formatDecimal(
                                  orderItem.amount - orderItem.discount_amount
                                )}
                          </p>
                        ) : null}
                        <p
                          className={
                            orderItem.discount_name ? "line-through" : ""
                          }
                        >
                          {orderItem.modifiers.length
                            ? getModifierItemSubprice(orderItem)
                            : formatDecimal(orderItem.amount)}
                        </p>
                      </div>
                    </div>
                  );
                })
                .reverse()
            : false}
        </div>
      </div>
      <div className="amount-details">
        <div className="amount-breakdown">
          <div className="amount">
            <p className="label">
              <FormattedMessage id="order_popup_subtotal" /> :
            </p>
            <p className="value">{calculateSubtotal(orderItems)}</p>
          </div>
          {totalDiscount > 0 ? (
            <div className="amount">
              <p className="label">
                <FormattedMessage id="order_popup_total_discount" /> :
              </p>
              <p className="value">-{formatDecimal(totalDiscount)}</p>
            </div>
          ) : null}
          {props.order.order_extended_info.surcharges.length
            ? props.order.order_extended_info.surcharges.map(
                (surcharge: any, index: any) => {
                  return (
                    <div key={index} className="amount">
                      <p className="label">{surcharge.name + "  : "} </p>
                      <p className="value">{formatDecimal(surcharge.amount)}</p>
                    </div>
                  );
                }
              )
            : null}

          {props.order.order_extended_info.discounts.length
            ? props.order.order_extended_info.discounts.map(
                (discount: any, index: any) => {
                  return (
                    <div key={index} className="amount">
                      <p className="label">{discount.name + "  : "} </p>
                      <p className="value">{`-${formatDecimal(
                        discount.amount
                      )}`}</p>
                    </div>
                  );
                }
              )
            : null}
          {!props.tax_inclusive && taxTotal > 0 ? (
            <div className="amount">
              <p className="label">
                <FormattedMessage id="order_popup_tax" /> :
              </p>
              <p className="value">{formatDecimal(taxTotal)}</p>
            </div>
          ) : null}
          {loyaltyRedeemedValue ? (
            <div className="amount">
              <p className="label">
                <FormattedMessage id="order_popup_loyalty" /> :{" "}
              </p>
              <p className="value font-weight-bold">
                -{formatDecimal(loyaltyRedeemedValue)}
              </p>
            </div>
          ) : null}
          <div className="amount total">
            <p className="label">
              <FormattedMessage id="order_popup_total" /> :{" "}
            </p>
            <p className="value">
              {props.currency}{" "}
              {formatDecimal(updateOrderTotalAmount(props.order))}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let currency = state.company.company_details.currency;
  let tax_inclusive = state.company.company_details.is_tax_inclusive;
  return { currency, tax_inclusive };
};

export default connect(mapStateToProps)(OrderItemsDetails);
