import { useEffect, useRef, useState } from "react";
import "@stripe/terminal-js";
import type { Terminal } from "@stripe/terminal-js";
import { STRIPE_CONNECT_ERROR, STRIPE_CONNECT_TXN_STEP } from "../../utils";
import { PAYMENT_STATUS } from "../../../../types/payments";

interface Params {
  amount: string | number;
  paymentTypeId: string;
  getDeviceId: any;
  clearDeviceId: any;
  locationPartnerConfig: any;
  locationId: number;
  simulated?: boolean;
  processStripeConnectTerminalPayment(stripeLocationId: any): Promise<any>;
  fetchStripeConnectTerminalPayment(paymentIntentId: any): Promise<any>;
  initiateStripeConnectPaymentIntentCreate(payload: any): Promise<any>;
  cancelStripeConnectTerminalPayment(
    paymentIntentId: any,
    payload: any
  ): Promise<any>;
  handleCloseTxnStatusView(): void;
  onTransactionCancel(): void;
  onTransactionSuccess(configs?: any): void;
}

const useStripeConnectPayment = ({
  amount,
  orderId,
  orderNo,
  paymentTypeId,
  getDeviceId,
  clearDeviceId,
  locationPartnerConfig,
  locationId,
  simulated = false,
  processStripeConnectTerminalPayment,
  fetchStripeConnectTerminalPayment,
  initiateStripeConnectPaymentIntentCreate,
  cancelStripeConnectTerminalPayment,
  handleCloseTxnStatusView,
  onTransactionCancel,
  onTransactionSuccess,
}: Params) => {
  const stripeLocationId = locationPartnerConfig?.stripe_location_id ?? null;
  const stripeCurrencyValue = locationPartnerConfig?.currency ?? null;
  const stripeAccountId =
    locationPartnerConfig?.stripe_connected_account_id ?? null;
  const stripeAccountMCC = locationPartnerConfig?.mcc ?? null;
  const [stripeConnectError, setStripeConnectError] = useState<null | {
    type: STRIPE_CONNECT_ERROR;
    data?: any;
    message?: string;
  }>(null);
  const [txnStatus, setTxnStatus] = useState<PAYMENT_STATUS>(
    PAYMENT_STATUS.WAITING
  );
  const [enableCancelTxn, setEnableCancelTxn] = useState(true);

  const txnSuccessTimeout = useRef(null);
  const txnStep = useRef<STRIPE_CONNECT_TXN_STEP | "">("");
  const stripeConnectTerminal = useRef<Terminal | null>(null);
  const paymentIntentResponse = useRef(null);
  const paymentIntent = useRef(null);
  const canProceedTxn = useRef(true);

  const checkTxnProgress = (txnStepMethod: () => any) => {
    if (canProceedTxn.current) {
      return txnStepMethod();
    }
  };

  const resetTxnProgressValues = () => {
    txnStep.current = "";
    txnSuccessTimeout.current = null;
    paymentIntentResponse.current = null;
    paymentIntent.current = null;
    stripeConnectTerminal.current = null;
    canProceedTxn.current = true;
  };

  useEffect(() => {
    if (stripeConnectError) {
      setTxnStatus(PAYMENT_STATUS.FAILED);
    }
  }, [stripeConnectError]);

  const handleCancelTxnCtaClick = () => {
    if (txnStep.current === STRIPE_CONNECT_TXN_STEP.COLLECT_PAYMENT_INTENT) {
      cancelOutstandingCollectPayment(stripeConnectTerminal.current);
    }
    // If PaymentIntent is present, request for cancellation
    if (paymentIntentResponse.current) {
      initiateStripeConnectPaymentIntentCancel(paymentIntentResponse.current);
    }
    resetTxnProgressValues();
    handleCloseTxnStatusView();
    clearDeviceId();
  };

  const handleRetryTxnCtaClick = () => {
    if (txnStep.current === STRIPE_CONNECT_TXN_STEP.COLLECT_PAYMENT_INTENT) {
      collectStripeConnectPaymentMethod(
        stripeConnectTerminal.current,
        paymentIntentResponse.current
      );
    }
    if (txnStep.current === STRIPE_CONNECT_TXN_STEP.CONFIRM_PAYMENT_INTENT) {
      confirmStripeConnectPaymentIntent(
        paymentIntent.current,
        paymentIntentResponse.current
      );
    }
  };

  const initiatePaymentWithStripeConnect = () => {
    checkTxnProgress(() => createConnectionToken());
  };

  const createConnectionToken = () => {
    setTxnStatus(PAYMENT_STATUS.IN_PROGRESS);
    processStripeConnectTerminalPayment(stripeLocationId)
      .then((response: any) => {
        if (!response.error) {
          let token = response.payload.data.connection_token;
          console.log("token created, creating terminal...");
          checkTxnProgress(() => initiateStripeConnectTerminalPayment(token));
        } else {
          setStripeConnectError({ type: STRIPE_CONNECT_ERROR.GENERIC_ERROR });
          console.error(
            "Failed to generate a valid Connection Token",
            response
          );
        }
      })
      .catch((error: any) => {
        setStripeConnectError({ type: STRIPE_CONNECT_ERROR.GENERIC_ERROR });
        console.error("Error occured while generating Connection Token", error);
      });
  };

  const initiateStripeConnectTerminalPayment = (connectionToken: string) => {
    txnStep.current = STRIPE_CONNECT_TXN_STEP.INITIATING_TERMINAL;
    // Initialise Stripe Terminal SDK
    let stripeTerminal = StripeTerminal.create({
      onFetchConnectionToken() {
        return connectionToken;
      },
      onUnexpectedReaderDisconnect() {
        canProceedTxn.current = false;
        setStripeConnectError({
          type: STRIPE_CONNECT_ERROR.DISCONNECTION_ERROR,
          data: { deviceId: getDeviceId() },
        });
      },
    });
    checkTxnProgress(() =>
      discoverAndConnectStripeConnectReader(stripeTerminal)
    );
    stripeConnectTerminal.current = stripeTerminal;
  };

  const discoverAndConnectStripeConnectReader = (
    stripeConnectTerminal: any
  ) => {
    txnStep.current = STRIPE_CONNECT_TXN_STEP.DISCOVERING_READER;
    let config = { simulated, location: stripeLocationId };
    stripeConnectTerminal.discoverReaders(config).then((result: any) => {
      console.log(result);
      if (result.error) {
        console.error("Unable to discover readers", result.error);
        setStripeConnectError({ type: STRIPE_CONNECT_ERROR.DISCOVERY_ERROR });
      }
      if (result.discoveredReaders.length === 0) {
        console.warn("No readers found in the network");
        setStripeConnectError({ type: STRIPE_CONNECT_ERROR.NO_READERS });
      }
      if (result.discoveredReaders.length > 0) {
        let selectedReader = result.discoveredReaders.find(
          (reader: any) => reader.serial_number === getDeviceId()
        );
        if (selectedReader) {
          stripeConnectTerminal
            .connectReader(selectedReader, {
              fail_if_in_use: true,
            })
            .then((connectResult: any) => {
              if (connectResult.error) {
                console.error(
                  "Unable to connect to selected reader",
                  connectResult.error
                );
                setStripeConnectError({
                  type: STRIPE_CONNECT_ERROR.READER_CONNECT_ERROR,
                  data: { deviceId: getDeviceId() },
                });
              } else {
                checkTxnProgress(() => requestPaymentIntent());
              }
            });
        } else {
          console.log(
            "Unable to find the selected reader in the network",
            getDeviceId()
          );
          setStripeConnectError({
            type: STRIPE_CONNECT_ERROR.READER_NOT_FOUND,
            data: { deviceId: getDeviceId() },
          });
        }
      }
    });
  };

  const requestPaymentIntent = () => {
    let referenceId = `stripe-connect-transaction-${locationId}-${getDeviceId()}-${new Date().getTime()}`;
    let payload = {
      payment_type_id: paymentTypeId,
      device_id: getDeviceId(),
      amount: parseFloat(amount),
      reference_id: referenceId,
      location_id: locationId,
      order_type: "call-center",
      order_id: orderId,
      order_number: orderNo,
      currency: stripeCurrencyValue,
      stripe_connected_account_id: stripeAccountId,
      mcc: stripeAccountMCC,
    };
    initiateStripeConnectPaymentIntentCreate(payload)
      .then((response) => {
        if (response?.payload?.status === 200) {
          let paymentIntentObject = {
            ...response.payload.data,
            referenceId: referenceId,
            deviceId: getDeviceId(),
          };
          paymentIntentResponse.current = paymentIntentObject;
          if (
            paymentIntentObject?.payment_intent_id &&
            paymentIntentObject?.client_secret
          ) {
            console.info(
              "Received Payment Intent",
              paymentIntentObject.payment_intent_id
            );
            checkTxnProgress(() =>
              collectStripeConnectPaymentMethod(
                stripeConnectTerminal.current,
                paymentIntentObject
              )
            );
          } else if (paymentIntentObject?.payment_intent_id) {
            console.error(
              "Unable to begin processing Payment Intent due to request being terminated",
              paymentIntentObject
            );
            initiateStripeConnectPaymentIntentCancel(paymentIntentObject);
          } else {
            console.error(
              "Failed to create a Payment Intent",
              paymentIntentObject
            );
            setStripeConnectError({ type: STRIPE_CONNECT_ERROR.GENERIC_ERROR });
          }
        }
      })
      .catch((error) => {
        console.error("Error occured while creating Payment Intent", error);
        setStripeConnectError({ type: STRIPE_CONNECT_ERROR.GENERIC_ERROR });
      });
  };

  const collectStripeConnectPaymentMethod = (
    stripeConnectTerminal: any,
    paymentIntentResponse: any
  ) => {
    console.log("paymentIntentResponse:", paymentIntentResponse);
    txnStep.current = STRIPE_CONNECT_TXN_STEP.COLLECT_PAYMENT_INTENT;
    stripeConnectTerminal
      .collectPaymentMethod(paymentIntentResponse.client_secret)
      .then((result: any) => {
        if (result.error) {
          console.error(
            "Unable to collect Payment Method.",
            result.error.message
          );
          setStripeConnectError({
            type: STRIPE_CONNECT_ERROR.PAYMENT_COLLECTION_ERROR,
            message: result.error.message,
          });
        } else {
          console.info(
            "Initiating Payment Intent processing",
            paymentIntentResponse.payment_intent_id
          );
          let stripeConnectPaymentIntent = result.paymentIntent;
          paymentIntent.current = stripeConnectPaymentIntent;
          confirmStripeConnectPaymentIntent(
            stripeConnectPaymentIntent,
            paymentIntentResponse
          );
        }
      });
  };

  const confirmStripeConnectPaymentIntent = (
    paymentIntent: any,
    paymentIntentResponse: any
  ) => {
    txnStep.current = STRIPE_CONNECT_TXN_STEP.CONFIRM_PAYMENT_INTENT;
    // Disable cancel button once payment is collected.
    setEnableCancelTxn(false);
    // Confirm the collected Payment method
    stripeConnectTerminal.current
      .processPayment(paymentIntent)
      .then((result: any) => {
        if (result.error) {
          if (result.error.payment_intent) {
            paymentIntent = result.error.payment_intent;
            let stripeConnectPaymentIntentId = paymentIntent.id;
            let stripeConnectPaymentIntentClientSecret =
              paymentIntent.client_secret;
            if (paymentIntent.status == "succeeded") {
              console.info(
                "Payment Confirmation already completed",
                stripeConnectPaymentIntentId
              );
              fetchUpdatedPaymentIntent(paymentIntentResponse);
            } else if (paymentIntent.status == "requires_confirmation") {
              console.error(
                "Temporary connectivity problem.",
                stripeConnectPaymentIntentId,
                result.error.message
              );
              setStripeConnectError({
                type: STRIPE_CONNECT_ERROR.REQUIRES_CONFIRMATION,
                data: { reference: stripeConnectPaymentIntentId },
              });
            } else {
              console.error(
                "Payment method declined.",
                stripeConnectPaymentIntentId,
                result.error.message
              );
              setStripeConnectError({
                type: STRIPE_CONNECT_ERROR.PAYMENT_CONFIRMATION_ERROR,
                message: result.error.message,
              });
            }
          } else {
            let stripeConnectPaymentIntentId = paymentIntent.id;
            console.error(
              "Request to Stripe timed out, unknown PaymentIntent status",
              stripeConnectPaymentIntentId,
              result.error.message
            );
            setStripeConnectError({
              type: STRIPE_CONNECT_ERROR.REQUEST_TIMED_OUT,
              data: { reference: stripeConnectPaymentIntentId },
            });
          }
        } else {
          console.info(
            "Payment Confirmation completed",
            paymentIntentResponse.payment_intent_id
          );
          fetchUpdatedPaymentIntent(paymentIntentResponse);
        }
      });
  };

  const fetchUpdatedPaymentIntent = (paymentIntentResponse: any) => {
    fetchStripeConnectTerminalPayment(paymentIntentResponse.payment_intent_id)
      .then((response: any) => {
        if (response.payload.status == 200) {
          console.info(
            "Payment Capture completed",
            paymentIntentResponse.payment_intent_id
          );
          let configs = {
            referenceId: response.payload.data.metadata.reference_id,
            deviceId: response.payload.data.metadata.device_id,
            locationId: response.payload.data.metadata.location_id,
            metaData: response.payload.data.metadata,
            refCode: response.payload.data.metadata.card_no,
          };
          setTxnStatus(PAYMENT_STATUS.PAID);
          txnStep.current = "";
          txnSuccessTimeout.current = setTimeout(() => {
            handleCloseTxnStatusView();
            clearDeviceId();
          }, 5000);
          resetTxnProgressValues();
          onTransactionSuccess(configs);
        } else {
          console.error(
            "Payment was not captured",
            paymentIntentResponse.payment_intent_id,
            response.failure_message
          );
          initiateStripeConnectPaymentIntentCancel(paymentIntentResponse);
          setStripeConnectError({
            type: STRIPE_CONNECT_ERROR.PAYMENT_CONFIRMATION_ERROR,
            message: response.failure.message,
          });
        }
      })
      .catch((error: any) => {
        console.error(
          "Error occured while finding updated Payment Intent",
          error
        );
        initiateStripeConnectPaymentIntentCancel(paymentIntentResponse);
        setStripeConnectError({ type: STRIPE_CONNECT_ERROR.GENERIC_ERROR });
      });
  };

  const initiateStripeConnectPaymentIntentCancel = (
    paymentIntentResponse: any
  ) => {
    txnStep.current = STRIPE_CONNECT_TXN_STEP.CANCEL_PAYMENT_INTENT;
    console.info(
      "Cancelling Payment Intent",
      paymentIntentResponse.payment_intent_id
    );
    let payload = {
      payment_type_id: paymentTypeId,
      device_id: paymentIntentResponse.deviceId,
      amount: parseFloat(amount),
      reference_id: paymentIntentResponse.referenceId,
      location_id: locationId,
      remote_transaction_id: paymentIntentResponse.payment_intent_id,
    };

    cancelStripeConnectTerminalPayment(
      paymentIntentResponse.payment_intent_id,
      payload
    )
      .then((response) => {
        console.info("Payment Intent Cancelled");
        onTransactionCancel();
      })
      .catch((error) => {
        console.error("Error occured while cancelling Terminal payment", error);
        setStripeConnectError({
          type: STRIPE_CONNECT_ERROR.GENERIC_ERROR,
          message: error?.message,
        });
      });
    resetTxnProgressValues();
  };

  const cancelOutstandingCollectPayment = (stripeConnectTerminal: any) => {
    stripeConnectTerminal
      .cancelCollectPaymentMethod()
      .then((res: any) => {
        console.info("cancelled collect payment");
      })
      .catch((error: any) => {
        console.error("Error occured while cancelling Collect payment step", error);
        setStripeConnectError({ type: STRIPE_CONNECT_ERROR.GENERIC_ERROR });
      });
  };

  return {
    stripeConnectError,
    txnStatus,
    enableCancelTxn,
    handleCancelTxnCtaClick,
    handleRetryTxnCtaClick,
    initiatePaymentWithStripeConnect,
  };
};

export default useStripeConnectPayment;
