import React from "react";
import { connect } from "react-redux";
import { orderOperations } from "../../state/features/order";
import { getEnvValueFor } from "../../helpers/utils";

interface IElapsedTimeProps {
  time: number;
  removeExpiredOrder: any;
  orderId: any;
}

const formatTimeDigits = (digits: any) => {
  digits = digits.toString();
  return digits.length === 1 ? "0" + digits : digits;
};

const calculateElapsedTime = (epochTime: any) => {
  var currentTime = new Date().getTime();
  var differenceInTime = Math.abs((epochTime - currentTime) / 1000);
  var differenceInMins = Math.floor(differenceInTime / 60);
  var elapsedHours = differenceInTime / (60 * 60);
  var elapsedMinutes = (elapsedHours * 60) % 60;
  var elapsedSeconds = (elapsedMinutes * 60) % 60;
  var elapsedTime =
    formatTimeDigits(Math.floor(elapsedHours)) +
    ":" +
    formatTimeDigits(Math.floor(elapsedMinutes)) +
    ":" +
    formatTimeDigits(Math.floor(elapsedSeconds));
  return elapsedTime;
};

function ElapsedTime(props: IElapsedTimeProps) {
  const [elapsedTime, setElapsedTime] = React.useState("");
  const [timeElapsed, setTimeElapsed] = React.useState(0);
  const [timeLimit, setTimeLimit] = React.useState(false);

  const calculateTimeLimit = (epochTime: any) => {
    var currentTime = new Date().getTime();
    var differenceInTime = Math.abs((epochTime - currentTime) / 1000);
    var elapsedHours = differenceInTime / (60 * 60);
    var differenceInMins = Math.floor(differenceInTime / 60);
    if (differenceInMins > 55) {
      setTimeLimit(true);
    } else {
      setTimeLimit(false);
    }
    
    // dividing by 3600 to convert seconds into hours
    var orderExpiryHours = parseInt(getEnvValueFor("ORDER_EXPIRY_THRESHOLD") || `${48 * 60 * 60}`) / 3600;

    if (parseInt(elapsedHours as any) >= orderExpiryHours) {
      props.removeExpiredOrder(props.orderId);
    }
  };

  React.useEffect(() => {
    if (props.time !== undefined) {
      setTimeElapsed(props.time);
    }
  }, [props.time]);
  
  React.useEffect(()=>{
    let updateTime = setInterval(() => {
      if (timeElapsed > 0) {
        calculateTimeLimit(timeElapsed);
      }
      return setElapsedTime(
        props.time > 0 ? calculateElapsedTime(timeElapsed) : ""
      );
    }, 500);
    return () => {
      setElapsedTime("");
      clearInterval(updateTime);
    };
  },[timeElapsed])

  return <p className={timeLimit ? 'red-color' : ''}>{elapsedTime}</p>;
}

const mapDispatchToProps =  {
  removeExpiredOrder:orderOperations.removeExpiredOrder
}

export default connect(null, mapDispatchToProps)(ElapsedTime);
