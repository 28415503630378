import { useMemo, useState } from "react";
import { ORDER_FILTERS } from "../types/filters";
import order from "../components/order";

const useOrdersFilter = (orders: any[]) => {
  const [filterId, setFilterId] = useState(0);
  const [searchResultOrders, setSearchResultOrders] = useState<any[]>([]);

  const updateFilterId = (newFilterId: number) => {
    setFilterId(newFilterId);
  };

  const filteredOrders = useMemo(() => {
    switch (filterId) {
      case ORDER_FILTERS.ALL:
        return orders;
      case ORDER_FILTERS.IN_HOUSE:
        return orders.filter((order: any) => {
          if (!order.logistics_job) {
            return order;
          } else if (order.logistics_job.status == 3) {
            return order;
          }
        });
      case ORDER_FILTERS.PARTNER_DELIVERY:
        return orders.filter(
          (order: any) => order.logistics_job && order.logistics_job.status != 3
        );
      default:
        return orders;
    }
  }, [orders, filterId]);

  const onSearchChange = (searchValue: string) => {
    let key = searchValue;
    let searchOrders = orders.filter((order: any) => {
      if (
        order.order_extended_info.order_number
          .toLowerCase()
          .includes(key.toLowerCase())
      ) {
        return true;
      } else if (
        order.order_extended_info.customer.telephone &&
        order.order_extended_info.customer.telephone.includes(key)
      ) {
        return true;
      }
      return false;
    });
    if (searchOrders.length === order.length) {
      setSearchResultOrders([]);
    } else {
      setSearchResultOrders(searchOrders);
    }
  };

  return { filteredOrders, updateFilterId, searchResultOrders, onSearchChange };
};

export default useOrdersFilter;
