import React from "react";
import * as companyTypes from "../company/types";
import * as branchesTypes from "../branches/types";
import * as paymentTypes from "../payments/types";
import * as homeDeliveryTypes from "../homeDelivery/types";
import * as pickupTypes from "../pickup/types";
import * as riderTypes from "../drivers/types";
import * as orderTypes from "../order/types";
import * as reprintTypes from "../reprints/types";
import * as jwtTypes from "../jwt/types";
import * as logisticTypes from "../logisticPartners/types";
import * as driversTypes from "../drivers/types";
import * as types from "./types";
import {
  generateAggregatorError,
  generateErrorMessage,
} from "../../../helpers/utils";
import { logisticErrors } from "../../../helpers/logisticErrors";

let errorMessage = {
  title: generateErrorMessage(logisticErrors.oops),
  text: generateErrorMessage(logisticErrors.globalErr),
  icon: "warning",
};

let thirdPartyCancelErr = {
  title: generateErrorMessage(logisticErrors.thirdPartyErr),
};

const aggregtorErrorMessage = (orderSource: any) => {
  return {
    icon: "error",
    title: generateErrorMessage(logisticErrors.unConfirmErr),
    text: generateAggregatorError(orderSource),
  };
};

const errorReducer = (state: any = { showError: false }, action: any) => {
  switch (action.type) {
    case types.NETWORK_ERROR:
      return { ...state, showError: true, errorType: "noInternet" };
    case companyTypes.FETCH_COMPANY_FAIL:
    case branchesTypes.FETCH_BRANCHES_FAIL:
    case paymentTypes.FETCH_PAYMENTS_FAIL:
    case homeDeliveryTypes.FETCH_NEW_ORDERS_FAIL:
    case homeDeliveryTypes.FETCH_UNCONFIRMED_ORDERS_FAIL:
    case homeDeliveryTypes.FETCH_READY_ORDERS_FAIL:
    case homeDeliveryTypes.FETCH_IN_PROGRESS_ORDERS_FAIL:
    case homeDeliveryTypes.FETCH_RIDER_STATS_FAIL:
    case homeDeliveryTypes.FETCH_RIDER_ORDERS_FAIL:
    case riderTypes.FETCH_DRIVERS_FAIL:
    case orderTypes.FETCH_ORDER_FAIL:
    case reprintTypes.FETCH_REPRINTS_FAIL:
    case reprintTypes.SET_ORDER_MARK_AS_REPRINTED_FAIL:
    case homeDeliveryTypes.SET_ORDER_MARK_AS_READY_FAIL:
    case homeDeliveryTypes.SET_ORDER_ASSIGN_RIDER_FAIL:
    case homeDeliveryTypes.SET_ORDER_MARK_AS_PICKUP_FAIL:
    case homeDeliveryTypes.SET_ORDER_MARK_AS_DELIVERED_FAIL:
    case pickupTypes.FETCH_NEW_PICKUP_ORDERS_FAIL:
    case pickupTypes.FETCH_READY_PICKUP_ORDERS_FAIL:
    case pickupTypes.FETCH_COLLECTED_PICKUP_ORDERS_FAIL:
    case pickupTypes.SET_PICKUP_ORDER_MARK_AS_READY_FAIL:
    case pickupTypes.SET_ORDER_COLLECTED_FAIL:
    case pickupTypes.FETCH_UNCONFIRMED_PICKUP_ORDERS_FAIL:
    case logisticTypes.CANCEL_LOGISTIC_JOB_FAIL:
    case logisticTypes.FETCH_LOGISTIC_PARTNERS_FAIL:
    case logisticTypes.ASSIGN_LOGISTIC_PARTNER_FAIL:
    case driversTypes.ASSIGN_NEW_RIDER_FAIL:
    case driversTypes.ASSIGN_READY_ORDER_RIDER_FAIL:
    case driversTypes.UNASSIGN_NEW_ORDER_RIDER_FAIL:
    case driversTypes.UNASSIGN_READY_ORDER_RIDER_FAIL:
      if (action.error.response !== undefined) {
        switch (action.error.response.status) {
          case 401:
          case 403:
          case 404:
          case 422:
          case 500:
            return {
              ...state,
              showError: true,
              errorType: "generic",
              errorMessage,
            };
          default:
            return state;
        }
      }
      break;
    case jwtTypes.REFRESH_TOKEN_FAILED:
      return { ...state, showError: true, errorType: "jwtRefreshFailed" };
    case homeDeliveryTypes.SET_UNCONFIRMED_ORDER_CONFIRM_FAIL:
    case pickupTypes.SET_UNCONFIRMED_PICKUP_ORDER_CONFIRM_FAIL:
      if (action.error.response.status !== undefined) {
        if ([401, 403, 404, 422].includes(action.error.response.status)) {
          return {
            ...state,
            showError: true,
            errorType: "generic",
            errorMessage,
          };
        }
        if (action.error.response.status === 500) {
          let sapaad_order_sources = ["sapaad_pos", "sapaad_online", "byod"];
          if (
            !sapaad_order_sources.includes(
              action.meta.previousAction.param.order_source
            )
          ) {
            return {
              ...state,
              showError: true,
              errorMessage: aggregtorErrorMessage(
                action.meta.previousAction.param.order_source
              ),
            };
          }
          return {
            ...state,
            showError: true,
            errorType: "generic",
            errorMessage,
          };
        }
      }
      return state;
    case homeDeliveryTypes.SET_UNCONFIRMED_ORDER_REJECT_FAIL:
    case pickupTypes.SET_UNCONFIRMED_PICKUP_ORDER_REJECT_FAIL:
      if (action.error.response.status !== undefined) {
        if ([404, 422].includes(action.error.response.status)) {
          return { ...state, showError: false };
        }
        return {
          ...state,
          showError: true,
          errorType: "generic",
          errorMessage,
        };
      }
      return state;
    case logisticTypes.PUSHER_RECEIVED_LOGISTIC_JOB_CANCELLED:
      return {
        ...state,
        showError: true,
        errorMessage: {
          title: action.payload.metadata.failure_message
            ? generateErrorMessage(logisticErrors.oops)
            : "",
          text: action.payload.metadata.failure_message
            ? action.payload.metadata.failure_message
            : `${thirdPartyCancelErr.title} ${action.payload.order_number}`,
          icon: action.payload.metadata.failure_message ? "warning" : "info",
        },
      };
    case logisticTypes.PUSHER_RECEIVED_LOGISTIC_CANCEL_FAILED:
      return {
        ...state,
        showError: true,
        errorMessage: {
          title: "",
          text: action.payload.metadata.failure_message,
          icon: "info",
        },
      };
    default:
      return state;
  }
};

const reducer = errorReducer;

export default reducer;
