import SapaadLogo from "../images/sapaad-square.png";
import ZomatoLogo from "../images/partners/zomato.png";
import TalabatLogo from "../images/partners/talabat.png";
import DeliverooLogo from "../images/partners/deliveroo.png";
import SapaadOnline from "../images/partners/sapaad-online.png";
import SapaadDirect from "../images/partners/sapaad-direct.png";
import Deliverect from "../images/partners/generic.png";
import UberEats from "../images/partners/uber.png";
import Foodpanda from "../images/partners/foodpanda.png";
import Gpay from "../images/partners/gpay.png";
import Grab from "../images/partners/grab.png";
import Lalamove from "../images/third-party-delivery/lalamove.png";
import Lyve from "../images/third-party-delivery/lyve.png";
import Quiqup from "../images/third-party-delivery/quiqup.png";
import Shadowfax from "../images/third-party-delivery/shadowfax.png";
import ZomatoGo from "../images/third-party-delivery/zomato-go.png";
import Otter from "../images/partners/generic.png";
import UrbanPiper from "../images/partners/generic.png";
import MyMenu from "../images/partners/my-menu.png";

const orderImages = {
  sapaad_pos: {
    image: SapaadLogo,
  },
  sapaad_online: {
    image: SapaadOnline,
  },
  zomato: {
    image: ZomatoLogo,
  },
  talabat: {
    image: TalabatLogo,
  },
  deliveroo: {
    image: DeliverooLogo,
  },
  byod: {
    image: SapaadDirect,
  },
  deliverect: {
    image: Deliverect,
  },
  uber_eats: {
    image: UberEats,
  },
  foodpanda: {
    image: Foodpanda,
  },
  gpay_food: {
    image: Gpay,
  },
  grab: {
    image: Grab,
  },
  lalamove: {
    image: Lalamove,
  },
  lyve: {
    image: Lyve,
  },
  quiqup: {
    image: Quiqup,
  },
  shadowfax: {
    image: Shadowfax,
  },
  "zomato-go": {
    image: ZomatoGo,
  },
  otter: {
    image: Otter,
  },
  urban_piper: {
    image: UrbanPiper,
  },
  my_menu: {
    image: MyMenu,
  },
} as any;

export default orderImages;
