import React, { useEffect, useState } from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { branchOperations } from "../../state/features/branches";
import { driverOperations } from "../../state/features/drivers";
import { FormattedMessage } from "react-intl";
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";

interface IBranchesDropdownProps {
  branches: any;
  selectBranch: any;
  company: any;
  selectedBranch: any;
  fetchDrivers: any;
}

function BranchesDropdown(props: IBranchesDropdownProps) {
  const [show, setShow] = useState(false);
  const [branch, setBranch] = useState({} as any);
  const [branchList, setBranchList] = useState([] as any);

  const onDropdownToggle = (e: any) => {
    if (e) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    if (Object.keys(props.company).length > 0 && props.branches.length > 0) {
      props
        .fetchDrivers(props.company.company_id)
        .catch((err: AxiosErrorResponse) => console.log(err));

      let defaultLocation = {
        name: props.company.admin ? "All branches" : "Multiple Branches",
      };
      let branches = [] as any;
      if (
        props.company.admin &&
        props.company.authorized_location_ids.length < 1
      ) {
        branches = props.branches;
      } else {
        props.company.authorized_location_ids.map(
          (locationId: any, index: number) => {
            branches[index] = props.branches.filter((branch: any) => {
              return locationId === branch.id;
            })[0];
          }
        );
      }
      if (localStorage.selectedBranch) {
        let selectedBranch = JSON.parse(localStorage.selectedBranch);
        let checkBranchExists = branches.find(
          (location: any) => location.id === selectedBranch.id
        ); // returns object if found else undefined.
        props.selectBranch(
          checkBranchExists
            ? checkBranchExists
            : props.branches.length > 1
            ? defaultLocation
            : props.branches[0]
        );
      } else {
        props.branches.length > 1
          ? props.selectBranch(defaultLocation)
          : props.selectBranch(props.branches[0]);
      }
      // remove default location if there is only single location
      if (branches.length > 1) {
        setBranchList([defaultLocation].concat(branches));
      } else {
        setBranchList(branches);
        props.selectBranch(branches[0]);
      }
    }
  }, [props.company, props.branches]);

  useEffect(() => {
    if (
      Object.keys(props.company).length > 0 &&
      Object.keys(props.selectedBranch).length > 0
    ) {
      setBranch(props.selectedBranch);
    }
  }, [props.company, props.selectedBranch]);

  const selectBranch = (selectedBranch: any) => {
    setBranch(selectedBranch);
    if (selectedBranch.id) {
      localStorage.selectedBranch = JSON.stringify(selectedBranch);
    } else {
      localStorage.removeItem("selectedBranch");
    }
    props.selectBranch(selectedBranch);
    setShow(!show);
  };

  return (
    <Dropdown
      className="branches-dropdown"
      drop="down"
      id="branch-dropdown"
      show={show}
      onToggle={(e: any) => onDropdownToggle(e)}
    >
      <Dropdown.Toggle
        variant=""
        id=""
        className={
          branchList.length === 1 ? "dropdown-icon hide-caret" : "dropdown-icon"
        }
      >
        <p title={branch.name}>
          {branch.name == "All branches" ? (
            <FormattedMessage id="global.all_branches" />
          ) : branch.name == "Multiple Branches" ? (
            <FormattedMessage id="global.multiple_branches" />
          ):(
            branch.name
          )}
        </p>
        <FontAwesomeIcon icon={faChevronDown} />
      </Dropdown.Toggle>
      <Dropdown.Menu className="branches-list" flip={false}>
        {branchList.length > 0
          ? branchList.map((branch: any, index: number) => {
              return (
                <p
                  key={index}
                  className="branch-name"
                  onClick={() => selectBranch(branch)}
                >
                  {branch.name == "All branches" ? (
                    <FormattedMessage id="global.all_branches" />
                  ) :branch.name == "Multiple Branches" ? (
                    <FormattedMessage id="global.multiple_branches" />
                  ) :(
                    branch.name
                  )}
                </p>
              );
            })
          : false}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const mapStateToProps = (state: any) => {
  let branches = state.branches;
  let company = state.company.company_details;
  let selectedBranch = state.company.selected_branch;

  return { branches, company, selectedBranch };
};

const mapDispatchToProps = {
  selectBranch: branchOperations.selectBranch,
  fetchDrivers: driverOperations.fetchDrivers,
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchesDropdown);
