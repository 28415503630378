import { filterOrderLogisticsPartners } from "../../helpers/orderFunctions";

export const logisticsConfigsInitialState = {
  logisticsPartnerAssigned: false,
  orderLogisticsPartners: [],
  showEmptyCell: false,
  skipInactiveLogisticsJobs: false
};

export const logisticsReducer = (
  state: typeof logisticsConfigsInitialState,
  action: any
) => {
  let logisticsEnabledOrderStatuses = ["new", "ready", "in_progress", "scheduled"];
  let logisticsEnabledOrderTypes = ["homeDelivery", "scheduled"];
  
  switch (action.type) {
    case "SET_LOGISTICS_DETAILS":
      let logisticsPartnerAssigned =
        action.order.logistics_job !== undefined &&
        action.order.logistics_job.status !== 3 &&
        logisticsEnabledOrderTypes.includes(action.orderType) &&
        logisticsEnabledOrderStatuses.includes(action.orderStatus);
      let cancelledJob =
        action.order.logistics_job !== undefined &&
        action.order.logistics_job.status === 3;
      let noRunningJobs = action.order.logistics_job === undefined;
      let skipInactiveLogisticsJobs = !(
        logisticsEnabledOrderTypes.includes(action.orderType) &&
        action.orderStatus === "in_progress" &&
        (cancelledJob || noRunningJobs)
      );
      return { ...state, logisticsPartnerAssigned, skipInactiveLogisticsJobs };
    case "SET_ORDER_LOGISTICS_PARTNERS":
      let orderLogisticsPartners = [];
      if (
        logisticsEnabledOrderTypes.includes(action.orderType) &&
        logisticsEnabledOrderStatuses.includes(action.orderStatus)
      ) {
        orderLogisticsPartners = filterOrderLogisticsPartners(
          action.order.location_id,
          action.logisticsPartners
        );
        if (
          action.orderType === "scheduled" &&
          action.orderStatus === "scheduled"
        ) {
          orderLogisticsPartners = orderLogisticsPartners.filter(
            (item: any) => {
              let partnerEnabledCountries =
                item?.configuration?.partner_config?.enabled_regions || [];
              let isScheduledOrdersSupported =
                item?.configuration?.partner_config
                  ?.scheduled_order_assignment || false;
              return (
                partnerEnabledCountries.includes(action.countryCode) &&
                isScheduledOrdersSupported
              );
            }
          );
        }
      }
      return { ...state, orderLogisticsPartners };
    case "SET_ASSIGNED_LOGISTICS_ORDER_PRESENCE":
      let showEmptyCell =
        logisticsEnabledOrderTypes.includes(action.orderType) &&
        logisticsEnabledOrderStatuses.includes(action.orderStatus) &&
        action.hasLogistics;
      return { ...state, showEmptyCell  };
    default:
      return state;
  }
};
