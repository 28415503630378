import { useRef, useEffect } from "react";
import { formatDecimal } from "./itemCalculations";
import { logisticErrors } from "./logisticErrors";

const parseJwt = (token: any) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};
const generateErrorMessage: any = (id: any) => {
  const locale = localStorage.getItem("locale");
  return id[locale];
};
const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const sortBranchesAlphabetically = (branches: any) => {
  let sortedBranches = branches.sort((first: any, second: any) => {
    return first.name.toLowerCase().localeCompare(second.name.toLowerCase());
  });
  return sortedBranches;
};

// Returns the ENV values passed to the root component by Rails
const getEnvValueFor = (envKey: string) => {
  // For better maintainability, we let the caller of this method to pass ENV Keys as
  // they are defined in the .env file (ALL CAPS - SNAKE CASE)
  // So envKey is in the format "SAPAAD_CORE_BASE_URL"

  let reFormattedKey = "ENV_" + envKey;
  // so now reFormattedKey is ENV_SAPAAD_CORE_BASE_URL

  reFormattedKey = reFormattedKey
    .toLocaleLowerCase()
    .replace(/(\_\w)/g, function (k) {
      return k[1].toUpperCase();
    });
  // First lower case all, then camel case.
  // now reFormattedKey is envSapaadCoreBaseUrl

  let envValue = document.getElementById("root")?.dataset[reFormattedKey];
  if (envValue === undefined) {
    console.error("[ENV] CANNOT FIND ENV FOR " + envKey);
  }

  return envValue;
};

const hardRefresh = () => {
  document
    .getElementById("root")
    ?.setAttribute("data-prevent-refresh", "false");
  window.location.reload();
};

const parseOrderSource = (orderSource: any) => {
  switch (orderSource) {
    case "zomato":
      return generateErrorMessage(logisticErrors.zomatoErr);
    case "talabat":
      return generateErrorMessage(logisticErrors.talabatErr);
    case "deliveroo":
      return generateErrorMessage(logisticErrors.deliverooErr);
    case "deliverect":
      return generateErrorMessage(logisticErrors.deliverectErr);
    case "uber_eats":
      return generateErrorMessage(logisticErrors.uberErr);
    case "foodpanda":
      return generateErrorMessage(logisticErrors.foodPandaErr);
    case "gpay_food":
      return "Gpay's systems are not accepting confirmation.";
    case "grab":
      return generateErrorMessage(logisticErrors.grabErr);
    default:
      return "";
  }
};

const generateAggregatorError = (orderSource: any) => {
  return (
    parseOrderSource(orderSource) +
    "." +
    generateErrorMessage(logisticErrors.conatctText) +
    " " +
    generateErrorMessage(logisticErrors.supportText) +
    "."
  );
};

const isAggregator = (orderSource: any) => {
  let sapaad_order_sources = ["sapaad_pos", "sapaad_online", "byod"];
  return !sapaad_order_sources.includes(orderSource);
};

const numberInputRegex = new RegExp(/^\d*\.?\d*$/);

const computeCashRounding = (value: any, rule: any, interval: any) => {
  if (value === 0) {
    return 0;
  }

  let roundingRule: any = rule;
  let roundingInterval: any = interval;
  let totalAmount: any = value;
  let unRoundedAmount: any = parseFloat(totalAmount);
  totalAmount = parseFloat(totalAmount);
  let roundedAmount: any = 0;
  let excess: any = 0;

  if (roundingRule == 1) {
    roundedAmount = totalAmount.roundDown(roundingInterval);
    excess = roundedAmount - parseFloat(totalAmount).toFixed(2);
  } else if (roundingRule == 2) {
    roundedAmount = parseFloat(
      Math.ceil((totalAmount as any) / roundingInterval) * roundingInterval
    ).toFixed(2);
    excess = roundedAmount - parseFloat(totalAmount).toFixed(2);
  } else if (roundingRule == 3) {
    roundedAmount = totalAmount.roundMidpoint(roundingInterval, "down");
    excess = roundedAmount - parseFloat(totalAmount).toFixed(2);
  } else if (roundingRule == 4) {
    roundedAmount = totalAmount.roundMidpoint(roundingInterval, "up");
    excess = roundedAmount - parseFloat(totalAmount).toFixed(2);
  }

  roundedAmount = parseFloat(roundedAmount).toFixed(2);
  let excessAmount = parseFloat(excess).toFixed(2);

  return { roundedAmount, excessAmount };
};

Number.prototype.roundMidpoint = function (interval, rule) {
  var modVal = parseFloat((this % interval).toFixed(2));
  if (rule == "up") {
    if (modVal < interval / 2) {
      return this - modVal;
    } else {
      return this + interval - modVal;
    }
  } else if ("down") {
    if (modVal > interval / 2) {
      return this + interval - modVal;
    } else {
      return this - modVal;
    }
  }
};

Number.prototype.roundDown = function (interval: any) {
  var returnValue = 0;
  var compute = parseFloat(this % interval).toFixed(2);
  if (compute == interval) {
    returnValue = this;
  } else {
    returnValue = this - compute;
  }
  return returnValue;
};

const generateCashRoundingString = (value: any, rule: any, interval: any) => {
  let totalAmount = value;
  let cashRoundedValue: any = computeCashRounding(totalAmount, rule, interval);
  let roundingRule = cashRoundedValue.excessAmount < 0 ? "down" : "up";
  let formattedAmountDisplay = formatDecimal(totalAmount);
  let roundedLabel =
    "Rounded " + roundingRule + " from " + formattedAmountDisplay;
  return roundedLabel;
};

const groupOrdersByDeliveryArea = (orders = []) => {
  return Object.entries(orders).map((d) => ({
    deliveryArea: d[0],
    orders: d[1],
  }));
};


export {
  parseJwt,
  usePrevious,
  sortBranchesAlphabetically,
  getEnvValueFor,
  hardRefresh,
  parseOrderSource,
  generateAggregatorError,
  isAggregator,
  numberInputRegex,
  computeCashRounding,
  generateCashRoundingString,
  generateErrorMessage,
  groupOrdersByDeliveryArea,
};
