import { useContext } from "react";
import { AudioContext } from "../context/audioContext";
import { audioContextType } from "../types/audioContextType";

const useNotifyAudioContext = () => {
  const context = useContext(AudioContext) as audioContextType;
  return context;
};

export { useNotifyAudioContext };
