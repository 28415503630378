import actions from "./actions";

const fetchLogisticPartners = actions.fetchLogisticPartners;
const resetLogisticPartners = actions.resetLogisticPartners;
const assignLogisticPartner = actions.assignLogisticPartner;
const logisticJobCancelled = actions.logisticJobCancelled;
const cancelLogisticJob = actions.cancelLogisticJob;
const logisticCancelFailed = actions.logisticCancelFailed;

export default {
  fetchLogisticPartners,
  resetLogisticPartners,
  assignLogisticPartner,
  logisticJobCancelled,
  cancelLogisticJob,
  logisticCancelFailed
};
