import * as types from "./types";

const fetchScheduledOrders = (location: any) => ({
  type: types.FETCH_SCHEDULED_ORDERS,
  payload: {
    request: {
      method: "get",
      url: location
        ? `/api/v1/orders/scheduled?location_id=${location.id}`
        : "/api/v1/orders/scheduled",
    },
  },
});

const removeScheduledOrder = (orderId:any) =>({
  type:types.REMOVE_SCHEDULED_ORDER,
  payload:orderId
})

export default { fetchScheduledOrders,removeScheduledOrder };
