import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { pickupOperations } from "../../state/features/pickup";
import TabNavigation from "../../components/tabs";
import PickupNewOrders from "../../components/pickupNewOrders";
import pickupWaitingForCollection from "../../components/pickupWaitingForCollection";
import pickupCollected from "../../components/pickupCollected";
import { branchOperations } from "../../state/features/branches";
import { useSelectedBranch } from "../../helpers/orderFunctions";
import pickupUnconfirmed from "../../components/pickupUnconfirmed";
import { FormattedMessage } from "react-intl";
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";

interface PickupsProps {
  company: any;
  branches: any;
  pickupOrders: any;
  fetchNewPickupOrders: any;
  fetchReadyPickupOrders: any;
  fetchCollectedPickupOrders: any;
  selectBranch: any;
  selectedBranch: any;
  currentTab: any;
  fetchPickupUnconfirmedOrders: any;
}

function Pickups(props: PickupsProps) {
  const [hideUnconfirmed, setHideUnconfirmed] = useState(true);
  useEffect(() => {
    if (Object.keys(props.selectedBranch).length > 0) {
      props
        .fetchPickupUnconfirmedOrders(useSelectedBranch(props.selectedBranch))
        .catch((err: AxiosErrorResponse) => console.log(err));
      props
        .fetchNewPickupOrders(useSelectedBranch(props.selectedBranch))
        .catch((err: AxiosErrorResponse) => console.log(err));
      props
        .fetchReadyPickupOrders(useSelectedBranch(props.selectedBranch))
        .catch((err: AxiosErrorResponse) => console.log(err));
      props
        .fetchCollectedPickupOrders(useSelectedBranch(props.selectedBranch))
        .catch((err: AxiosErrorResponse) => console.log(err));
    }
  }, [props.selectedBranch]);

  useEffect(() => {
    if (
      props.currentTab.length > 0 &&
      Object.keys(props.pickupOrders).length > 0 &&
      Object.keys(props.selectedBranch).length > 0
    ) {
      switch (props.currentTab) {
        case "pickup.unconfirmed":
          if (props.pickupOrders.unconfirmed_pickup_orders.refresh_required) {
            props
              .fetchPickupUnconfirmedOrders(
                useSelectedBranch(props.selectedBranch)
              )
              .catch((err: AxiosErrorResponse) => console.log(err));
          }
          break;
        case "pickup.new":
          if (props.pickupOrders.new_pickup_orders.refresh_required) {
            props
              .fetchNewPickupOrders(useSelectedBranch(props.selectedBranch))
              .catch((err: AxiosErrorResponse) => console.log(err));
          }
          break;
        case "pickup.ready":
          if (props.pickupOrders.ready_pickup_orders.refresh_required) {
            props
              .fetchReadyPickupOrders(useSelectedBranch(props.selectedBranch))
              .catch((err: AxiosErrorResponse) => console.log(err));
          }
          break;
        case "pickup.collected":
          if (props.pickupOrders.collected_pickup_orders.refresh_required) {
            props
              .fetchCollectedPickupOrders(
                useSelectedBranch(props.selectedBranch)
              )
              .catch((err: AxiosErrorResponse) => console.log(err));
          }
          break;
        default:
          "";
      }
    }
    if (props.company.user_permissions?.includes("weborder.manage")) {
      setHideUnconfirmed(false);
    } else if (props.company.user_permissions?.includes("all.manage")) {
      setHideUnconfirmed(false);
    } else {
      setHideUnconfirmed(true);
    }
  }, [
    props.currentTab,
    props.pickupOrders,
    props.selectedBranch,
    props.company,
  ]);

  const tabs = [
    {
      name: <FormattedMessage id="tab.unconfirmed" />,
      path: "/pickup/unconfirmed",
      orders_count: props.pickupOrders.unconfirmed_pickup_orders.count,
      is_loading: props.pickupOrders.unconfirmed_pickup_orders.is_loading,
      is_hidden: hideUnconfirmed,
    },
    {
      name: <FormattedMessage id="tab.new_order" />,
      path: "/pickup/new",
      orders_count: props.pickupOrders.new_pickup_orders.count,
      is_loading: props.pickupOrders.new_pickup_orders.is_loading,
      is_hidden: false,
    },
    {
      name: <FormattedMessage id="tab.waiting_for_collection" />,
      path: "/pickup/waiting-for-collection",
      orders_count: props.pickupOrders.ready_pickup_orders.count,
      is_loading: props.pickupOrders.ready_pickup_orders.is_loading,
      is_hidden: false,
    },
    {
      name: <FormattedMessage id="tab.collected" />,
      path: "/pickup/collected",
      orders_count: props.pickupOrders.collected_pickup_orders.count,
      is_loading: props.pickupOrders.collected_pickup_orders.is_loading,
      is_hidden: false,
    },
  ];

  return (
    <div>
      <TabNavigation tabInfo={tabs} />
      <Switch>
        <Route path={tabs[0].path} component={pickupUnconfirmed} />
        <Route path={tabs[1].path} component={PickupNewOrders} />
        <Route path={tabs[2].path} component={pickupWaitingForCollection} />
        <Route path={tabs[3].path} component={pickupCollected} />
      </Switch>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let pickupOrders = state.order.pickup;
  let branches = state.branches || [];
  let company = state.company.company_details;
  let selectedBranch = state.company?.selected_branch;
  let currentTab = state.currentTab;

  return { pickupOrders, branches, company, selectedBranch, currentTab };
};

const mapDispatchToProps = {
  fetchNewPickupOrders: pickupOperations.fetchNewPickupOrders,
  fetchReadyPickupOrders: pickupOperations.fetchReadyPickupOrders,
  fetchCollectedPickupOrders: pickupOperations.fetchCollectedPickupOrders,
  fetchPickupUnconfirmedOrders: pickupOperations.fetchPickupUnconfirmedOrders,
  selectBranch: branchOperations.selectBranch,
};

export default connect(mapStateToProps, mapDispatchToProps)(Pickups);
