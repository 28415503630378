
export const FETCH_NEW_PICKUP_ORDERS = "pickup/FETCH_NEW_PICKUP_ORDERS";
export const FETCH_NEW_PICKUP_ORDERS_SUCCESS = "pickup/FETCH_NEW_PICKUP_ORDERS_SUCCESS";
export const FETCH_NEW_PICKUP_ORDERS_FAIL = "pickup/FETCH_NEW_PICKUP_ORDERS_FAIL";
export const FETCH_READY_PICKUP_ORDERS = "pickup/FETCH_READY_ PICKUP_ORDERS";
export const FETCH_READY_PICKUP_ORDERS_SUCCESS = "pickup/FETCH_READY_ PICKUP_ORDERS_SUCCESS";
export const FETCH_READY_PICKUP_ORDERS_FAIL = "pickup/FETCH_READY_PICKUP_ORDERS_FAIL";
export const FETCH_COLLECTED_PICKUP_ORDERS = "pickup/FETCH_COLLECTED_ PICKUP_ORDERS";
export const FETCH_COLLECTED_PICKUP_ORDERS_SUCCESS = "pickup/FETCH_COLLECTED_ PICKUP_ORDERS_SUCCESS";
export const FETCH_COLLECTED_PICKUP_ORDERS_FAIL = "pickup/FETCH_COLLECTED_PICKUP_ORDERS_FAIL";
export const SET_PICKUP_ORDER_MARK_AS_READY = "pickup/SET_PICKUP_ORDER_MARK_AS_READY";
export const SET_PICKUP_ORDER_MARK_AS_READY_SUCCESS = "pickup/SET_PICKUP_ORDER_MARK_AS_READY_SUCCESS";
export const SET_PICKUP_ORDER_MARK_AS_READY_FAIL = "pickup/SET_PICKUP_ORDER_MARK_AS_READY_FAIL";
export const SET_ORDER_COLLECTED = "pickup/SET_ORDER_COLLECTED";
export const SET_ORDER_COLLECTED_SUCCESS = "pickup/SET_ORDER_COLLECTED_SUCCESS";
export const SET_ORDER_COLLECTED_FAIL = "pickup/SET_ORDER_COLLECTED_FAIL";
export const FETCH_UNCONFIRMED_PICKUP_ORDERS = "pickup/FETCH_UNCONFIRMED_PICKUP_ORDERS";
export const FETCH_UNCONFIRMED_PICKUP_ORDERS_SUCCESS = "pickup/FETCH_UNCONFIRMED_PICKUP_ORDERS_SUCCESS";
export const FETCH_UNCONFIRMED_PICKUP_ORDERS_FAIL = "pickup/FETCH_UNCONFIRMED_PICKUP_ORDERS_FAIL";
export const SET_UNCONFIRMED_PICKUP_ORDER_CONFIRM = "pickup/SET_UNCONFIRMED_PICKUP_ORDER_CONFIRM"
export const SET_UNCONFIRMED_PICKUP_ORDER_CONFIRM_SUCCESS = "pickup/SET_UNCONFIRMED_PICKUP_ORDER_CONFIRM_SUCCESS"
export const SET_UNCONFIRMED_PICKUP_ORDER_CONFIRM_FAIL = "pickup/SET_UNCONFIRMED_PICKUP_ORDER_CONFIRM_FAIL"
export const SET_UNCONFIRMED_PICKUP_ORDER_REJECT = "pickup/SET_UNCONFIRMED_PICKUP_ORDER_REJECT"
export const SET_UNCONFIRMED_PICKUP_ORDER_REJECT_SUCCESS = "pickup/SET_UNCONFIRMED_PICKUP_ORDER_REJECT_SUCCESS"
export const SET_UNCONFIRMED_PICKUP_ORDER_REJECT_FAIL = "pickup/SET_UNCONFIRMED_PICKUP_ORDER_REJECT_FAIL"
export const SET_COLLECTED_ORDERS_REFRESH_REQUIRED = "pickup/SET_COLLECTED_ORDERS_REFRESH_REQUIRED"
export const SET_UNCONFIRMED_PICKUP_ORDER_CONFIRM_WITH_PAYMENT = "pickup/SET_UNCONFIRMED_PICKUP_ORDER_CONFIRM_WITH_PAYMENT"
export const SET_UNCONFIRMED_PICKUP_ORDER_CONFIRM_WITH_PAYMENT_SUCCESS = "pickup/SET_UNCONFIRMED_PICKUP_ORDER_CONFIRM_WITH_PAYMENT_SUCCESS"
export const SET_UNCONFIRMED_PICKUP_ORDER_CONFIRM_WITH_PAYMENT_FAIL = "pickup/SET_UNCONFIRMED_PICKUP_ORDER_CONFIRM_WITH_PAYMENT_FAIL"
