import type Pusher from "pusher-js";
import { useContext } from "react";
import { PusherContext } from "../context/pusherContext";

const usePusher = () => {
  const context = useContext(PusherContext) as Pusher;
  return context;
};

export { usePusher };
