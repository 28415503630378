export const PUSHER_RECEIVED_ORDER_CREATED =
  "orderEvents/PUSHER_RECEIVED_ORDER_CREATED";
export const PUSHER_RECEIVED_ORDER_UPDATED =
  "orderEvents/PUSHER_RECEIVED_ORDER_UPDATED";
export const PUSHER_RECEIVED_ORDER_VOIDED =
  "orderEvents/PUSHER_RECEIVED_ORDER_VOIDED";
export const PUSHER_RECEIVED_ORDER_CONFIRMED =
  "orderEvents/PUSHER_RECEIVED_ORDER_CONFIRMED";
export const PUSHER_RECEIVED_ORDER_REJECTED =
  "orderEvents/PUSHER_RECEIVED_ORDER_REJECTED";
export const PUSHER_RECEIVED_ORDER_MARKED_AS_READY =
  "orderEvents/PUSHER_RECEIVED_ORDER_MARKED_AS_READY";
export const PUSHER_RECEIVED_PICKUP_ORDER_MARKED_AS_READY =
  "orderEvents/PUSHER_RECEIVED_PICKUP_ORDER_MARKED_AS_READY";
export const PUSHER_RECEIVED_RIDER_ASSIGNED =
  "orderEvents/PUSHER_RECEIVED_RIDER_ASSIGNED";
export const PUSHER_RECEIVED_ORDER_MARKED_AS_DELIVERED =
  "orderEvents/PUSHER_RECEIVED_ORDER_MARKED_AS_DELIVERED";
export const PUSHER_RECEIVED_ORDER_MARKED_AS_COLLECTED =
  "orderEvents/PUSHER_RECEIVED_ORDER_MARKED_AS_COLLECTED";
export const PUSHER_RECEIVED_ORDER_REPRINTED =
  "orderEvents/PUSHER_RECEIVED_ORDER_REPRINTED";
export const PUSHER_RECEIVED_CUSTOMER_ARRIVED =
  "orderEvents/PUSHER_RECEIVED_CUSTOMER_ARRIVED";
export const PUSHER_RECEIVED_ORDER_MARKED_AS_PICKED_UP =
  "orderEvents/PUSHER_RECEIVED_ORDER_MARKED_AS_PICKED_UP";
export const PUSHER_RECEIVED_RIDER_UNASSIGNED =
  "orderEvents/PUSHER_RECEIVED_RIDER_UNASSIGNED";
export const PUSHER_RECEIVED_TIP_COLLECTED =
  "orderEvents/PUSHER_RECEIVED_TIP_COLLECTED";
export const PUSHER_RECEIVED_COMPANY_RIDER_ASSIGNED =
  "orderEvents/PUSHER_RECEIVED_COMPANY_RIDER_ASSIGNED";
export const PUSHER_RECEIVED_PARTNER_RIDER_ASSIGNED =
  "orderEvents/PUSHER_RECEIVED_PARTNER_RIDER_ASSIGNED";
