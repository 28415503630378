import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getStatusTime } from "../../../helpers/orderFunctions";
import orderStatus from "../../../helpers/orderStatus";

interface iStatusHistoryProps {
  order: any;
}

function StatusHistory(props: iStatusHistoryProps) {
  const [order, setOrder] = useState({} as any);

  useEffect(() => {
    setOrder(props.order);
  }, [props.order]);

  return (
    <div className="order-status-history mb-3">
      <div className="order-history">
        <p>
          <FormattedMessage id="status_history" />
        </p>
        <div className="order-status-history-table">
          <div className="header">
            <div className="tableRow">
              <div className="header-item">
                <FormattedMessage id="status_history_status" />
              </div>
              <div className="header-item">
                <FormattedMessage id="status_history_staff" />
              </div>
              <div className="header-item">
                <FormattedMessage id="order.time" />
              </div>
            </div>
          </div>
          <div className="content-wrapper">
            {props.order.status_updates.length > 0
              ? props.order.status_updates.map((status: any, index: any) => {
                  if (status.event !== "rider_unassigned") {
                    return (
                      <div key={index} className="content-row">
                        <div className="content-item">
                          {
                            orderStatus[status.event][props.order.order_type]
                              .title
                          }
                        </div>
                        <div className="content-item">{status.staff_name}</div>
                        <div className="content-item">
                          {getStatusTime(status.time)}
                        </div>
                      </div>
                    );
                  }
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatusHistory;
