import React, { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getCurrentTabConfigs } from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

interface IOrderFiltersTab {
  currentTab: string;
  updateSelectedFilterOption?(selectedOptionId: number): void;
  onSearch?(searchTerm: string): void;
}

const OrderFiltersTab = ({
  currentTab,
  updateSelectedFilterOption,
  onSearch,
}: IOrderFiltersTab) => {
  const [selectedFilterOption, setSelectedFilterOption] = useState(0);

  const updateSelectedFilterOptionId = (option: number) => {
    setSelectedFilterOption(option);
    updateSelectedFilterOption(option);
  };

  const currentTabConfigs = useMemo(() => {
    let tabConfig = getCurrentTabConfigs(currentTab);
    let showFilterSection = tabConfig?.showFilterTab ?? false;
    let showSearchBar = tabConfig?.showSearch ?? false;
    let filterOptions = tabConfig?.filterOptions ?? [];
    return { showFilterSection, showSearchBar, filterOptions };
  }, [currentTab]);

  const onSearchChange = (e: any) => {
    onSearch(e.target.value);
  };

  return (
    <>
      <div className="table-filter-tab">
        {currentTabConfigs.showFilterSection ? (
          <div className="table-filter-tab">
            <p className="label">
              <FormattedMessage id="global.filter_by" />:
            </p>
            {currentTabConfigs.filterOptions.length
              ? currentTabConfigs.filterOptions.map(
                  (option: any, index: any) => {
                    return (
                      <div
                        key={index}
                        onClick={() => updateSelectedFilterOptionId(option.id)}
                        className={
                          selectedFilterOption === option.id
                            ? "filter-item active"
                            : "filter-item"
                        }
                      >
                        {option.name}
                      </div>
                    );
                  }
                )
              : null}
          </div>
        ) : (
          false
        )}
        {currentTabConfigs.showSearchBar ? (
          <div className="orders-search-wrapper">
            <div className="input-group">
              <div className="input-group-prepend m-auto">
                <FontAwesomeIcon icon={faSearch} />
              </div>
              <FormattedMessage id="search">
                {(placeholder: any) => (
                  <input
                    onChange={onSearchChange}
                    type="text"
                    className="orders-search"
                    placeholder={placeholder}
                    aria-label="Example text with button addon"
                    aria-describedby="button-addon1"
                  />
                )}
              </FormattedMessage>
            </div>
          </div>
        ) : (
          false
        )}
      </div>
    </>
  );
};

export default OrderFiltersTab;
