import * as types from "./types";

const fetchLogisticPartners = () => ({
  type: types.FETCH_LOGISTIC_PARTNERS,
  payload: {
    request: {
      method: "get",
      url: `/api/v1/logistics/partners`,
    },
  },
});

const resetLogisticPartners = () => ({
  type: types.RESET_LOGISTIC_PARTNERS,
  payload: [],
});

const assignLogisticPartner = (orderId: any, partnerId: any) => ({
  type: types.ASSIGN_LOGISTIC_PARTNER,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/logistics/jobs`,
      data: {
        order_id: orderId,
        partner_id: partnerId,
      },
    },
  },
});

const logisticJobCancelled = (data: any) => ({
  type: types.PUSHER_RECEIVED_LOGISTIC_JOB_CANCELLED,
  payload: data,
});

const cancelLogisticJob = (orderId: any) => ({
  type: types.CANCEL_LOGISTIC_JOB,
  payload: {
    request: {
      method: "delete",
      url: `/api/v1/logistics/jobs/${orderId}`,
    },
  },
});

const logisticCancelFailed = (data: any) => ({
  type: types.PUSHER_RECEIVED_LOGISTIC_CANCEL_FAILED,
  payload: data,
});


export default {
  fetchLogisticPartners,
  resetLogisticPartners,
  assignLogisticPartner,
  logisticJobCancelled,
  cancelLogisticJob,
  logisticCancelFailed
};
