import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import {
  addModifiersToItems,
  getBranchName,
  getLogisticName,
  getOrderScheduledForDate,
  getOrderTime,
  updateOrderTotalAmount,
} from "../../helpers/orderFunctions";
import OrderPopup from "../orderPopup";
import ElapsedTime from "../elapsedTime";
import { connect } from "react-redux";
import { formatDecimal } from "../../helpers/itemCalculations";
import PaymentPopUp from "../paymentPopup";
import orderImages from "../../helpers/orderImage";
import SapaadLogo from "../../images/sapaad-square.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamation,
  faExclamationTriangle,
  faShoppingBag,
} from "@fortawesome/free-solid-svg-icons";
import { scheduledOperations } from "../../state/features/scheduled";
import RadialTimeCount from "../radialTimeCount";
import SetDeliveryTimePopup from "../setDeliveryTimePopup";
import { logisticPartnersOperations } from "../../state/features/logisticPartners";
import DeliveryPartnerPopup from "../deliveryPartnerPopup";
import { getEnvValueFor } from "../../helpers/utils";
import Swal, { SweetAlertResult } from "sweetalert2";
import { logisticsConfigsInitialState, logisticsReducer } from "./utils";
import { ZOMATO_GO_PARTNER_UNIQUE_ID } from "../../helpers/constants";
import { FormattedMessage, useIntl } from "react-intl";
import OrderLogisticsControl from "./orderLogisticsControl";
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";
import RiderSelectionPopup from "../riderSelectionPopup";
import { homeDeliveryOperations } from "../../state/features/homeDelivery";
import OrderButtonGroup from "../orderButtonGroup";

interface IOrderProps {
  item: any;
  handleOrderState?: any;
  orderStatus?: any;
  companyDrivers?: any;
  currency?: any;
  orderType: any;
  dmCanRecordPayment?: any;
  branches?: any;
  disableButton?: boolean;
  removeScheduledOrder?: any;
  handleApprove?: any;
  handleReject?: any;
  autoRejectionSla: any;
  handleAutoReject?: any;
  defaultDeliveryEta?: any;
  hideDetails?: any;
  limboStateOrders?: any;
  logisticPartners?: any;
  assignLogisticPartner?: any;
  loyaltyDetails: any;
  showEmptyCell?: boolean;
  payments?: any;
  riderAppEnabled?: boolean;
  countryCode: string;
  assignDriver:any;
  selectedRider?: any;
  setSelectedRider?: any;
  newRiderSelection?: boolean;
}

function Order(props: IOrderProps) {
  const [order, setOrder] = useState({} as any);
  const [showDetails, setShowDetails] = useState(false);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [orderItems, setOrderItems] = useState([] as any);
  const [showDeliveryEtaPopup, setShowDeliveryEtaPopup] = useState(false);
  const [walkInOrderType, setWalkInOrderType] = useState(false);
  const [isLimboState, setIsLimboState] = useState(true);
  const [isZomatoDeliveryOrder, setIsZomatoDeliveryOrder] = useState(false);
  const [showDeliveryPartnerPopup, setShowDeliveryPartnerPopup] =
    useState(false);
  const [pendingPartnerAssign, setPendingPartnerAssign] = useState(false);
  const [logisticsConfigs, setLogisticsConfigs] = useReducer(
    logisticsReducer,
    logisticsConfigsInitialState
  );
  const [selectedOrderId, setSelectedOrderId] = useState(0);
  const [showPartnerRiderAssign, setShowPartnerRiderAssign] = useState(false);
  const partnerRiderAssignRef = useRef<any>(null);
  const intl = useIntl();
  const handleShowDetails = () => {
    setShowDetails(true);
  };
  const formattedMessage = (formatId: any) => {
    const translatedMessage = intl.formatMessage({ id: formatId });
    return translatedMessage;
  };
  useEffect(() => {
    setOrderItems(addModifiersToItems(props.item.order_extended_info.items));
    setOrder(props.item);
    setLogisticsConfigs({
      type: "SET_LOGISTICS_DETAILS",
      order: props.item,
      orderStatus: props.orderStatus,
      orderType: props.orderType,
    });
  }, [props.item]);

  useEffect(() => {
    if (props.limboStateOrders.length) {
      setIsLimboState(props.limboStateOrders.includes(props.item.order_id));
    } else {
      setIsLimboState(false);
    }
  }, [props.limboStateOrders.length, props.limboStateOrders]);

  const setOrderStatusText = useMemo(() => {
    if(props.orderStatus == "unconfirmed"){
      return <FormattedMessage id="global.view_details" />
    }
    else if (props.orderType == "homeDelivery") {
      switch (props.orderStatus) {
        case "new":
          return <FormattedMessage id="cta.mark_ready" />;
          case "ready":
            if (props.riderAppEnabled && props.item.rider_id) {
              return <FormattedMessage id="rider.order_collected_by_rider" />;
            } else if (
              !props.newRiderSelection &&
              !props.riderAppEnabled &&
              props.selectedRider &&
              props.selectedRider.assigned_locations.includes(
                props.item.location_id
              )
            ) {
              return <FormattedMessage id="cta.pickup" />;
            } else {
              return <FormattedMessage id="logistic.assign_rider" />;
            }
        case "in_progress":
          return <FormattedMessage id="cta.mark_delivered" />;
        default:
          return <FormattedMessage id="logistic.assign_rider" />;
      }
    } else if (props.orderType == "pickup") {
      switch (props.orderStatus) {
        case "new":
          return <FormattedMessage id="cta.mark_ready" />;
        case "ready":
          return <FormattedMessage id="tab.collected" />;
        case "in_progress":
          return <FormattedMessage id="tab.delivered" />;
        case "collected":
          return <FormattedMessage id="tab.delivered" />;
        default:
          return <FormattedMessage id="tab.delivered" />;
      }
    } else {
      return <FormattedMessage id="tab.delivered" />;
    }
  }, [
    props.orderType,
    props.orderStatus,
    props.newRiderSelection,
    props.riderAppEnabled,
    props.selectedRider,
    props.item.rider_id,
  ]);

  const getDisabledState = useMemo(() => {
    if (
      props.orderType == "homeDelivery" &&
      props.orderStatus == "ready" &&
      ((!props.riderAppEnabled &&
        !props.newRiderSelection &&
        props.selectedRider &&
        !props.selectedRider.assigned_locations.includes(
          props.item.location_id
        )) ||
        !props.selectedRider ||
        (props.riderAppEnabled && !props.item.rider_id) ||
        props.disableButton)
    ) {
      return true;
    }
    if (
      props.item.logistics_job &&
      props.item.logistics_job?.status !== 3 &&
      props.orderStatus == "ready"
    ) {
      return true;
    }
    return false
  }, [
    props.orderType,
    props.orderStatus,
    props.newRiderSelection,
    props.riderAppEnabled,
    props.selectedRider,
    props.item.rider_id,
    props.disableButton,
    props.item.logistics_job
  ]);

  function getOrderActions() {
    if(props.orderStatus == "unconfirmed"){
      return handleShowDetails()
    } else if(props.orderType == "homeDelivery"){
      switch (props.orderStatus) {
        case "new":
          return handleHomedeliveryOrderAction(props.item)
        case "ready":
          return handleHomedeliveryOrderAction(props.item)
        case "in_progress":
          return handleHomedeliveryOrderPayment(props.item)
        default : 
          return
      }
    } else if (props.orderType == "pickup") {
      switch (props.orderStatus) {
        case "new":
          return props.handleOrderState(props.item.order_id)
        case "ready":
          return checkPaymentStatus(props.item)
        default:
          return
      }
    } else return
  }

  const checkPaymentStatus = (order: any) => {
    let payments: any[] = order.order_extended_info.payments;
    let isTakeAwayOrder: boolean = order.order_extended_info.take_away;
    let dmCanRecordPayment: boolean = props.dmCanRecordPayment;
    // if there are more than one payment exists
    let generatedCashPayments = [];
    generatedCashPayments = createPaymentPayload();
    if (payments.length > 0) {
      if (payments.length === 1 && payments[0].name === "Loyalty") {
        if (isTakeAwayOrder) {
          setShowPaymentPopup(true);
        } else {
          if (dmCanRecordPayment) {
            setShowPaymentPopup(true);
          } else {
            props.handleOrderState(props.item.order_id, {
              payments: payments,
            });
          }
        }
      } else {
        if (dmCanRecordPayment || isTakeAwayOrder) {
          props.handleOrderState(props.item.order_id);
        } else {
          props.handleOrderState(props.item.order_id, {
            payments: payments,
          });
        }
      }
    } else {
      if (isTakeAwayOrder) {
        setShowPaymentPopup(true);
      } else {
        if (props.dmCanRecordPayment) {
          setShowPaymentPopup(true);
        } else {
          props.handleOrderState(props.item.order_id, {
            payments: generatedCashPayments,
          });
        }
      }
    }
  };

  const riderName = useMemo(() => {
    if (props.companyDrivers.length) {
      let rider = props.companyDrivers.find(
        (rider: any) => rider.id == props.item.rider_id
      );
      return rider?.name ?? "";
    }
    return "";
  }, [JSON.stringify(props.companyDrivers), props.item]);

  useEffect(() => {
    let timer: any;
    if (props.orderType === "scheduled") {
      let today: any = new Date();
      let scheduled_for = props.item.scheduled_for;
      let time_difference = scheduled_for - today;
      /**
       * ## Workaround fix for setTimeout code block
       * Browsers including Internet Explorer, Chrome, Safari, and Firefox
       * store the delay as a 32-bit signed integer internally.
       * This causes an integer overflow when using delays larger than 2,147,483,647 ms (about 24.8 days),
       * resulting in the timeout being executed immediately.
       *
       * Therefore we run the timer only for orders scheduled for less than 10 days
       */
      let tenth_day = new Date().setDate(today.getDate() + 10);
      let ten_days_difference = tenth_day - today.getTime();
      if (time_difference < ten_days_difference) {
        timer = setTimeout(() => {
          props.removeScheduledOrder(props.item.order_id);
        }, time_difference);
      }
    }
    if (
      props.item.order_type === "walk_in" &&
      props.item.order_extended_info.take_away
    ) {
      setWalkInOrderType(true);
    }
    return () => clearTimeout(timer);
  }, [props.item]);

  const handleDeliveryEtaConfirm = (orderId: any, eta: any) => {
    props.handleApprove(orderId, eta, props.item.order_source);
    if (showDetails) {
      setShowDetails(false);
    }
    setShowDeliveryEtaPopup(false);
  };

  useEffect(() => {
    if (props.hideDetails) {
      setShowDetails(false);
    }
  }, [props.hideDetails]);

  useEffect(() => {
    setLogisticsConfigs({
      type: "SET_ORDER_LOGISTICS_PARTNERS",
      order: props.item,
      logisticsPartners: props.logisticPartners,
      orderType: props.orderType,
      orderStatus: props.orderStatus,
      countryCode: props.countryCode,
    });
  }, [props.logisticPartners, props.item]);

  useEffect(() => {
    setLogisticsConfigs({
      type: "SET_ASSIGNED_LOGISTICS_ORDER_PRESENCE",
      hasLogistics: props.showEmptyCell,
      orderType: props.orderType,
      orderStatus: props.orderStatus,
    });
  }, [props.showEmptyCell, props.item]);

  useEffect(() => {
    let meta_note: string | undefined =
      order?.order_extended_info?.meta_note?.system_note;
    if (
      order.order_source === "zomato" &&
      meta_note?.includes("Zomato Delivery")
    ) {
      setIsZomatoDeliveryOrder(true);
    } else {
      setIsZomatoDeliveryOrder(false);
    }
    if (order.logistics_job) {
      setPendingPartnerAssign(false);
    }
  }, [order]);

  const handleHomedeliveryOrderAction = (order: any, riderId?: any) => {
    if (
      props.orderType === "homeDelivery" &&
      props.orderStatus === "ready" &&
      order.logistics_job &&
      order.logistics_job.status !== 3
    ) {
      let title: any = formattedMessage("logistic_pickup_assign_err");
      let text: any = formattedMessage("logistic_pickup_assign_err1");
      Swal.fire({
        icon: "warning",
        title: title,
        text: text,
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#3085D6",
        cancelButtonText: "No",
        cancelButtonColor: "#DD3333",
      }).then((result: SweetAlertResult<any>) => {
        if (result.isConfirmed) {
          if (riderId) {
            props
              .assignDriver(order.order_id, riderId)
              .catch((err: AxiosErrorResponse) => console.log(err));
          } else {
            props.handleOrderState(order.order_id);
          }
        }
      });
    } else {
      if (riderId && props.orderStatus === "ready") {
        props
          .assignDriver(order.order_id, riderId)
          .catch((err: AxiosErrorResponse) => console.log(err));
      } else if (order.rider_id && props.orderStatus === "ready") {
        props.handleOrderState(order.order_id, order.rider_id);
      } else {
        props.handleOrderState(order.order_id);
      }
    }
  };

  const handleHomedeliveryOrderPayment = (order: any) => {
    if (
      props.orderType === "homeDelivery" &&
      props.orderStatus === "in_progress" &&
      order.logistics_job &&
      order.logistics_job.status !== 3
    ) {
      let title: any = formattedMessage("logistic_delivered_err");
      let text: any = formattedMessage("logistic_delivered_err1");
      Swal.fire({
        icon: "warning",
        title: title,
        text: text,
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#3085D6",
        cancelButtonText: "No",
        cancelButtonColor: "#DD3333",
      }).then((result: SweetAlertResult<any>) => {
        if (result.isConfirmed) {
          checkPaymentStatus(order);
        }
      });
    } else {
      checkPaymentStatus(order);
    }
  };

  const handleDeliveryPartnerPopup = (order: any) => {
    if (
      "logistics_job" in order &&
      order.logistics_job.partner_unique_id === ZOMATO_GO_PARTNER_UNIQUE_ID
    ) {
      let text: any = formattedMessage("zomato_err");
      Swal.fire({
        icon: "info",
        text: text,
      });
    } else {
      if (!order.order_status?.includes("delivered")) {
        setShowDeliveryPartnerPopup(true);
      } else {
        if(order.logistics_job){
          setShowDeliveryPartnerPopup(true);
        } else {
          setShowDeliveryPartnerPopup(false);
        }
      } 
    }
  };

  const createPaymentPayload = () => {
    let cashPaymentType: any = props.payments.find(
      (payment: any) => payment.display_name == "Cash"
    );
    let paymentObject: any = new Object();
    paymentObject["name"] = cashPaymentType.display_name;
    paymentObject["id"] = cashPaymentType.id;
    paymentObject["amount"] = order.order_extended_info.total_amount;
    paymentObject["amount_collected"] = order.order_extended_info.total_amount;
    let mockPayments: any[] = [];
    mockPayments.push(paymentObject);
    return mockPayments;
  };

  const getPartnerRiderId = () =>{
    const partnerId = props.item.logistics_job.partner_id;
    const partner = props.logisticPartners.find((i: any) => i.id == partnerId);
    return partner.configuration.driver_id
  }

  useEffect(() => {
    setShowPartnerRiderAssign(false);
    clearTimeout(partnerRiderAssignRef.current);
    if (
      props.item.logistics_job &&
      props.item.order_status?.includes("ready")
    ) {
      const current_time = Date.now();
      if ((current_time - props.item.logistics_job.updated_at) / 60000 > 15) {
        setShowPartnerRiderAssign(true);
      } else {
        partnerRiderAssignRef.current = setTimeout(() => {
          setShowPartnerRiderAssign(true);
        }, props.item.logistics_job.updated_at + 900000 - current_time);
      }
    }
  }, [props.item.logistics_job]);

  return (
    <React.Fragment>
      {((props.orderType === "homeDelivery" &&
        props.orderStatus === "in_progress") ||
        (props.orderType === "pickup" && props.orderStatus === "ready") ||
        walkInOrderType) &&
      showPaymentPopup ? (
        <PaymentPopUp
          showModal={showPaymentPopup}
          setShowModal={setShowPaymentPopup}
          orderId={props.item.order_id}
          orderNo={props.item.order_extended_info.order_number}
          paymentAmount={formatDecimal(updateOrderTotalAmount(props.item))}
          paymentAction={props.handleOrderState}
          isTakeAway={props.item.order_extended_info.take_away}
          orderPayments={props.item.order_extended_info.payments}
          locationId={props.item.location_id}
        />
      ) : null}
      {props.orderType === "unconfirmed" ? (
        <SetDeliveryTimePopup
          show={showDeliveryEtaPopup}
          handleShow={setShowDeliveryEtaPopup}
          handleApprove={handleDeliveryEtaConfirm}
          orderId={props.item.order_id}
          defaultDeliveryEta={props.defaultDeliveryEta}
        />
      ) : null}
      {showDetails ? (
        <OrderPopup
          orderStatus={props.orderStatus}
          orderType={props.orderType}
          order={order}
          onClose={() => setShowDetails(false)}
          handleOrderApprove={props.handleApprove}
          handleOrderReject={(
            orderId: any,
            cancelReasonId: any,
            supervisorPassword: any
          ) => props.handleReject(orderId, cancelReasonId, supervisorPassword)}
          handleWalkinOrderApprove={setShowPaymentPopup}
        />
      ) : null}
      <tr ref={props.item?.ref}>
        <td>
          <div
            className={"icon " + props.item.order_source}
            style={{
              backgroundImage: `url(${
                props.item.order_source
                  ? orderImages[props.item.order_source]?.image
                  : SapaadLogo
              })`,
            }}
          ></div>
        </td>
        <td>
          <div className="order-number" onClick={handleShowDetails}>
            <p className="number">
              {props.item.order_extended_info.order_number}
              {!props.item.is_cod ? (
                <span className="order-payment-status">
                  <FontAwesomeIcon className="tick" icon={faCheckCircle} />
                  <FormattedMessage id="order.paid" />
                </span>
              ) : null}
            </p>
            {props.item.order_extended_info.foreign_order_no &&
            props.item.order_extended_info.foreign_order_no !== null ? (
              <p className="partnerId">
                <span>Partner Order No:</span>
                <span>{props.item.order_extended_info.foreign_order_no}</span>
              </p>
            ) : null}
            <div className="order-items">
              {orderItems.length
                ? orderItems.slice(0, 3).map((orderItem: any, index: any) => {
                    return (
                      <p key={index}>
                        {!orderItem.deleted &&
                          orderItem.qty_dec + "x " + orderItem.name}
                      </p>
                    );
                  })
                : null}
              {orderItems.length > 3 ? (
                <p>
                  {orderItems.length - 3}
                  <FormattedMessage id="order.more" />
                  ...
                </p>
              ) : null}
            </div>
          </div>
        </td>

        {props.orderType === "scheduled" ? (
          <td>
            <p className="order-time">
              {props.item.scheduled_for
                ? getOrderTime(props.item.scheduled_for)
                : ""}
            </p>
            <p className="order-date">
              {props.item.scheduled_for
                ? getOrderScheduledForDate(props.item.scheduled_for)
                : ""}
            </p>
          </td>
        ) : null}

        {props.orderStatus !== "collected" &&
        props.orderType !== "scheduled" ? (
          <td style={{ width: "150px" }}>
            {props.orderType !== "unconfirmed" ? (
              <p className="order-time">
                {getOrderTime(
                  props.item.scheduled_for
                    ? props.item.scheduled_for
                    : props.item.placed_at
                )}
              </p>
            ) : (
              <p className="order-time">{getOrderTime(props.item.placed_at)}</p>
            )}
          </td>
        ) : null}
        {props.orderType !== "scheduled" &&
        props.orderStatus !== "collected" ? (
          props.orderType !== "unconfirmed" ? (
            <td style={{ width: "150px" }}>
              <div className="elspsed-time order-time">
                <ElapsedTime
                  time={
                    props.item.scheduled_for
                      ? props.item.scheduled_for
                      : props.item.placed_at
                  }
                  orderId={props.item.order_id}
                />
              </div>
            </td>
          ) : null
        ) : null}
        {props.orderType === "pickup" ||
        props.orderType === "scheduled" ||
        props.orderType === "unconfirmed" ? (
          <td>
            {props.item.order_extended_info.customer.name ||
            props.item.order_extended_info.customer.telephone ? (
              <p className="elspsed-time order-time">
                {props.item.order_extended_info.customer.name}
                {" / "}
                {props.item.order_extended_info.customer.telephone}
              </p>
            ) : (
              "NA"
            )}
          </td>
        ) : null}
        {props.orderType === "pickup" ? (
          <td>
            <p className="elspsed-time order-time">
              {props.item.order_extended_info.user_name}
            </p>
          </td>
        ) : null}
        {props.orderType === "homeDelivery" &&
        props.orderStatus === "in_progress" ? (
          props.riderAppEnabled ? null : (
            <td>
              <p
                className={`order-time driver-name-container ${
                  !props.riderAppEnabled && props.item.rider_id && !riderName
                    ? "rider_not_found"
                    : ""
                }`}>
                {!props.riderAppEnabled && props.item.rider_id ? (
                  riderName ? (
                    riderName
                  ) : (
                    <>
                      <FontAwesomeIcon
                        className="warning-icon"
                        icon={faExclamationTriangle}
                      />
                      <FormattedMessage
                        id="rider.riderNotFound"
                        defaultMessage="Rider Not Found"
                      />
                    </>
                  )
                ) : (
                  "-"
                )}
              </p>
            </td>
          )
        ) : null}
        {props.orderType === "homeDelivery" ||
        props.orderType === "scheduled" ? (
          props.orderStatus === "ready" ||
          props.orderStatus === "in_progress" ? (
            <td>
              <div className="order-time delivery-address-container">
                {props.item.order_extended_info.delivery_address?.building ||
                  ""}
                {logisticsConfigs.logisticsPartnerAssigned ? (
                  <span>
                    <div
                      className="logistic-icon"
                      style={{
                        backgroundImage: `url(${
                          orderImages[
                            getLogisticName(
                              props.logisticPartners,
                              order.logistics_job.partner_id
                            )
                          ]?.image
                        })`,
                      }}
                    ></div>
                  </span>
                ) : null}
                {isZomatoDeliveryOrder ? (
                  <span>
                    <div
                      className="logistic-icon"
                      style={{
                        backgroundImage: `url(${orderImages["zomato-go"]?.image})`,
                      }}
                    ></div>
                  </span>
                ) : null}
              </div>
            </td>
          ) : (
            <td>
              {props.item.order_extended_info.take_away ? (
                <div className="delivery-area">
                  <p className="area-name">
                    {getBranchName(props.branches, props.item.location_id)}
                  </p>
                  <p className="store-pickup">
                    <FontAwesomeIcon icon={faShoppingBag} size="xs" />{" "}
                    <span>
                      <FormattedMessage id="order.store_pickup" />
                    </span>
                  </p>
                </div>
              ) : (
                <div className="delivery-area">
                  <div className="area-name">
                    {props.item.order_extended_info.delivery_address?.area ||
                      ""}
                    {logisticsConfigs.logisticsPartnerAssigned ? (
                      <span>
                        <div
                          className="logistic-icon"
                          style={{
                            backgroundImage: `url(${
                              orderImages[
                                getLogisticName(
                                  props.logisticPartners,
                                  order.logistics_job.partner_id
                                )
                              ]?.image
                            })`,
                          }}
                        ></div>
                      </span>
                    ) : null}
                    {isZomatoDeliveryOrder ? (
                      <span>
                        <div
                          className="logistic-icon"
                          style={{
                            backgroundImage: `url(${orderImages["zomato-go"]?.image})`,
                          }}
                        ></div>
                      </span>
                    ) : null}
                  </div>
                  <p className="area-details">
                    {props.item.order_extended_info.delivery_address
                      ?.room_number ? (
                      <span className="address-info">
                        {
                          props.item.order_extended_info.delivery_address
                            .room_number
                        }
                      </span>
                    ) : null}
                    {props.item.order_extended_info.delivery_address
                      ?.building ? (
                      <span className="address-info">
                        {
                          props.item.order_extended_info.delivery_address
                            .building
                        }
                      </span>
                    ) : null}
                    {props.item.order_extended_info.delivery_address
                      ?.land_mark ? (
                      <span className="address-info">
                        {
                          props.item.order_extended_info.delivery_address
                            .land_mark
                        }
                      </span>
                    ) : null}
                    {props.item.order_extended_info.delivery_address?.street ? (
                      <span className="address-info">
                        {props.item.order_extended_info.delivery_address.street}
                      </span>
                    ) : null}
                    {props.item.order_extended_info.delivery_address?.area ? (
                      <span className="address-info">
                        {props.item.order_extended_info.delivery_address.area}
                      </span>
                    ) : null}
                  </p>
                </div>
              )}
            </td>
          )
        ) : null}

        <td style={{ width: "120px" }}>
          <p className="amount order-time">
            {formatDecimal(updateOrderTotalAmount(props.item))} {props.currency}
          </p>
        </td>

        {props.orderType === "unconfirmed" ? (
          <td style={{ width: "150px" }}>
            <p className="amount order-time text-center">
              {!isLimboState &&
              props.item.order_extended_info.auto_rejection_sla > 0 &&
              props.item.order_type === "online" ? (
                <RadialTimeCount
                  auto_reject_in={
                    props.item.order_extended_info.auto_rejection_sla ||
                    props.autoRejectionSla
                  }
                  handleAutoReject={props.handleAutoReject}
                  orderId={props.item.order_id}
                  placedTime={props.item.placed_at}
                />
              ) : (
                <>--</>
              )}
            </p>
          </td>
        ) : null}

        {["scheduled", "homeDelivery"].includes(props.orderType) &&
        !order?.order_extended_info?.take_away &&
        logisticsConfigs.showEmptyCell ? (
          <td
            className={`text-center third-party-delivery-partner ${
              props.riderAppEnabled
                ? "rider-enableddisabledState"
                : "rider-disabledState"
            }`}>
            <OrderLogisticsControl
              order={order}
              isPartnerAssignmentPending={pendingPartnerAssign}
              logisticsConfigs={logisticsConfigs}
              onClickAction={() => {
                handleDeliveryPartnerPopup(order);
                setSelectedOrderId(order.order_id);
              }}
              isRiderAppEnabled={props.riderAppEnabled}
              riderName={riderName}
              orderType={props.orderType}
              orderStatus={props.orderStatus}
            />
            {props.item.order_status?.includes("ready") &&
            props.item.logistics_job &&
            props.item.logistics_job.status !== 3 &&
            showPartnerRiderAssign ? (
              <span
                className="rider-assign-link"
                onClick={() => {
                  handleHomedeliveryOrderAction(
                    props.item,
                    getPartnerRiderId()
                  );
                }}
              >
                Mark as collected
              </span>
            ) : null}
          </td>
        ) : !props.riderAppEnabled && logisticsConfigs.showEmptyCell ? (
          <td style={{ padding: 0 }} />
        ) : null}

        {props.orderType !== "scheduled" &&
        !(
          props.orderType == "homeDelivery" &&
          props.riderAppEnabled &&
          props.orderStatus == "ready"
        ) ? (
          <td style={{ width: "220px" }}>
            <div className="cta">
              {props.orderType === "homeDelivery" &&
              props.orderStatus == "ready" &&
              !props.riderAppEnabled &&
              props.newRiderSelection ? (
                <RiderSelectionPopup
                  orderType={props.orderType}
                  orderStatus={props.orderStatus}
                  isRiderAppEnabled={props.riderAppEnabled}
                  order={props.item}
                  assignRiderToOrder={handleHomedeliveryOrderAction}
                />
              ) : (
                <OrderButtonGroup
                  title={setOrderStatusText}
                  setOrderAction={() => getOrderActions()}
                  disable={getDisabledState}
                />
              )}
            </div>
          </td>
        ) : null}

        {order.order_type === "walk_in" &&
        props.loyaltyDetails &&
        props.loyaltyDetails.walkin ? (
          <form
            action={`${getEnvValueFor("SAPAAD_CORE_BASE_URL")}/walkin?order`}
            target={`walkinOrderPay-${order.order_id}`}
            method="post"
            id={`walkinOrderPay-${order.order_id}`}>
            <input type="hidden" name="order_id" value={props.item.order_id} />
          </form>
        ) : null}
      </tr>
      {showDeliveryPartnerPopup && order.order_id === selectedOrderId ? (
        <DeliveryPartnerPopup
          order={order}
          handleClose={() => setShowDeliveryPartnerPopup(false)}
          deliveryPartners={logisticsConfigs.orderLogisticsPartners}
          assignPartner={props.assignLogisticPartner}
          onPartnerAssign={setPendingPartnerAssign}
          companyDrivers={props.companyDrivers}
          riderAppEnabled={props.riderAppEnabled}
          orderType={props.orderType}
          handleOrder={() => handleHomedeliveryOrderAction(props.item)}
        />
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => {
  let companyDrivers = state.drivers.company_drivers || [];
  let currency = state.company.company_details.currency;
  let dmCanRecordPayment = state.company.company_details.dm_can_record_payment;
  let branches = state.branches;
  let autoRejectionSla =
    state.company.company_details.sapaad_online_details.auto_rejection_sla;
  let defaultDeliveryEta =
    state.company.company_details.sapaad_online_details.default_delivery_eta;
  let limboStateOrders = state.limboStateOrders;
  let logisticPartners = state.logisticPartners;
  let loyaltyDetails = state.company.company_details.loyalty_details;
  let payments = state.payments || [];
  let riderAppEnabled = state.company.company_details.rider_app_enabled;
  let countryCode = state.company.company_details.country;
  return {
    companyDrivers,
    currency,
    dmCanRecordPayment,
    branches,
    autoRejectionSla,
    defaultDeliveryEta,
    limboStateOrders,
    logisticPartners,
    loyaltyDetails,
    payments,
    riderAppEnabled,
    countryCode,
  };
};

const mapDispatchToProps = {
  removeScheduledOrder: scheduledOperations.removeScheduledOrder,
  assignLogisticPartner: logisticPartnersOperations.assignLogisticPartner,
  assignDriver: homeDeliveryOperations.assignDriver,
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);