import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { FormattedMessage , useIntl} from "react-intl";
import { connect } from "react-redux";
import { getStatusTime } from "../../../helpers/orderFunctions";
import { getEnvValueFor } from "../../../helpers/utils";

interface iCustomerDetailsProps {
  order: any;
  branches?: any;
}

function CustomerDetails(props: iCustomerDetailsProps) {
  const [branches, setBranches] = useState([] as any);

  useEffect(() => {
    setBranches(props.branches);
  }, [props.branches]);

  const getBranchName = (locationId: any) => {
    let branchName = "";
    branches.find((branch: any) => {
      if (branch.id == locationId) {
        branchName = branch.name;
      }
    });
    return branchName;
  };

  const formattedMessage = (formatId: any) => {
    const intl = useIntl();
    const translatedMessage = intl.formatMessage({ id: formatId })
    return translatedMessage;
  };

  return (
    <div className="customer-details">
      {!props.order.order_extended_info.take_away ? (
        <div className="delivery-address">
          {props.order.order_extended_info.delivery_address.room_number ? (
            <span className="address-info">
              {props.order.order_extended_info.delivery_address.room_number}
            </span>
          ) : null}
          {props.order.order_extended_info.delivery_address.building ? (
            <span className="address-info">
              {props.order.order_extended_info.delivery_address.building}
            </span>
          ) : null}
          {props.order.order_extended_info.delivery_address.land_mark ? (
            <span className="address-info">
              {props.order.order_extended_info.delivery_address.land_mark}
            </span>
          ) : null}
          {props.order.order_extended_info.delivery_address.street ? (
            <span className="address-info">
              {props.order.order_extended_info.delivery_address.street}
            </span>
          ) : null}
          {props.order.order_extended_info.take_away ? (
            props.order.location_id ? (
              <span className="address-info">
                {getBranchName(props.order.location_id) || ""}
              </span>
            ) : null
          ) : null}
        </div>
      ) : null}
      <div className="customer-info mb-0">
        {props.order.scheduled_for > props.order.placed_at ? (
          <div>
            <p className="label">
              <FormattedMessage id="order_popup_scheduled_on" />:
            </p>
            : {getStatusTime(props.order.placed_at)}
          </div>
        ) : null}

        {props.order.order_extended_info.customer.name ? (
          <div>
            <p className="label">
              <FormattedMessage id="order_popup_customer_name" />
            </p>
            :{" "}
            <p className="customer-name">
              <span className="name">{" "}{props.order.order_extended_info.customer.name}</span>
              <span>
                <a
                  href={`${getEnvValueFor(
                    "SAPAAD_CORE_BASE_URL"
                  )}/callcenter?customer_id=${
                    props.order.order_extended_info.customer.id
                  }`}
                  target="_blank"
                  title={formattedMessage("order_popup_pastorders")}
                >
                  <FontAwesomeIcon
                    className="past-orders-link"
                    icon={faHistory}
                  />
                </a>
              </span>
            </p>
          </div>
        ) : null}
        {props.order.order_extended_info.customer.telephone ? (
          <div>
            <p className="label">
              <FormattedMessage id="order_popup_contact_no" />
            </p>
            : {props.order.order_extended_info.customer.telephone}
          </div>
        ) : null}
        {props.order.order_extended_info.customer.alt_telephone_number ? (
          <div>
            <p className="label">
              <FormattedMessage id="order_popup_alt_no" />
            </p>
            : {props.order.order_extended_info.customer.alt_telephone_number}
          </div>
        ) : null}
        {props.order.location_id ? (
          <div>
            <p className="label">
              {props.order.order_extended_info.take_away ? (
                <FormattedMessage id="order_popup_pickup_location" />
              ) : (
                <FormattedMessage id="order_popup_branch" />
              )}
            </p>
            : {getBranchName(props.order.location_id) || ""}
          </div>
        ) : null}
        {props.order.order_extended_info.delivery_address.area ? (
          <div>
            <p className="label">
              <FormattedMessage id="order.delivery_area" />
            </p>
            : {props.order.order_extended_info.delivery_address.area}
          </div>
        ) : null}
      </div>
      {props.order.order_extended_info.notes ? (
        <div className="order-notes pt-0">
          <label>
            <FormattedMessage id="order_popup_order_notes" />:
          </label>
          <p className="note">{props.order.order_extended_info.notes}</p>
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let branches = state.branches;
  return { branches };
};

export default connect(mapStateToProps)(CustomerDetails);
