import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { connect } from "react-redux";
import { usePrevious } from "../../helpers/utils";

interface iTabBadgeProps {
  count: any;
  is_loading: boolean;
}

function TabBadge(props: iTabBadgeProps) {
  const count: any = useRef(null);
  const previousCount: any = usePrevious(props.count);
  const previousLoadingState: any = usePrevious(props.is_loading);

  useEffect(() => {
    if (
      (previousLoadingState === false || previousLoadingState === undefined) &&
      props.count > previousCount
    ) {
      count.current.classList.add("bounce");
      count.current.addEventListener(
        "webkitAnimationEnd",
        function () {
          count.current.classList.remove("bounce");
        },
        false
      );
    }
  }, [props.count]);

  return (
    <span ref={count} className={"badge badge-info"}>
      <span className="active">{props.count}</span>
      <span className="previous">{previousCount}</span>
    </span>
  );
}

export default TabBadge;
