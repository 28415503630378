import React, { useEffect, useState } from "react";
import Header from "../header";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import HomeDeliveries from "../../containers/homeDeliveries";
import Pickups from "../../containers/pickups";
import Scheduled from "../../containers/scheduled";
import LoginError from "../../containers/loginError";
import { connect } from "react-redux";
import NoInternet from "../noInternet";
import Swal from "sweetalert2";
import DmmsDisabled from "../dmmsDisabled";
import { hardRefresh } from "../../helpers/utils";

interface IAppProps {
  pusher: any;
  error: any;
  selectedBranch?: any;
}

function App(props: IAppProps) {
  const [showOfflineOverlay, setShowOfflineOverlay] = useState(false);
  const [loginErrorRedirect, setLoginErrorRedirect] = useState(false);
  const [isDmmsDisabled, setIsDmmsDisabled] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (props.error.showError) {
      switch (props.error.errorType) {
        case "noInternet":
          setShowOfflineOverlay(true);
          break;
        case "generic":
          Swal.fire(props.error.errorMessage).then((res: any) => {
            hardRefresh();
          });
          break;
        case "jwtRefreshFailed":
          history.push("/login-error");
          break;
        default:
          Swal.fire(props.error.errorMessage);
      }
    } else {
      setShowOfflineOverlay(false);
      setLoginErrorRedirect(false);
    }
  }, [props.error]);

  useEffect(() => {
    if (Object.keys(props.selectedBranch).length > 0) {
      if (
        props.selectedBranch.name === "All branches" ||
        props.selectedBranch.name === "Multiple Branches"
      ) {
        setIsDmmsDisabled(false);
      } else if (!props.selectedBranch.delivery_enabled) {
        setIsDmmsDisabled(true);
      } else {
        setIsDmmsDisabled(false);
      }
    }
  }, [props.selectedBranch]);

  return (
    <>
      {showOfflineOverlay && <NoInternet />}
      {loginErrorRedirect ? (
        <div>
          <Redirect to="/login-error" />
        </div>
      ) : (
        <div className="container-fluid">
          <Header pusher={props.pusher} />
          {isDmmsDisabled ? (
            <DmmsDisabled branchName={props.selectedBranch.name} />
          ) : (
            <Switch>
              <Route path="/home-delivery" component={HomeDeliveries} />
              <Route path="/pickup" component={Pickups} />
              <Route exact path="/scheduled" component={Scheduled} />
              <Redirect to="/home-delivery/new" />
            </Switch>
          )}
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state: any) => {
  let error = state.error;
  let selectedBranch = state.company.selected_branch;
  return { error, selectedBranch };
};

export default connect(mapStateToProps)(App);
