export const FETCH_NEW_ORDERS = "home_delivery/FETCH_NEW_ORDERS";
export const FETCH_NEW_ORDERS_SUCCESS =
  "home_delivery/FETCH_NEW_ORDERS_SUCCESS";
export const FETCH_NEW_ORDERS_FAIL = "home_delivery/FETCH_NEW_ORDERS_FAIL";
export const FETCH_UNCONFIRMED_ORDERS =
  "home_delivery/FETCH_UNCONFIRMED_ORDERS";
export const FETCH_UNCONFIRMED_ORDERS_SUCCESS =
  "home_delivery/FETCH_UNCONFIRMED_ORDERS_SUCCESS";
export const FETCH_UNCONFIRMED_ORDERS_FAIL =
  "home_delivery/FETCH_UNCONFIRMED_ORDERS_FAIL";
export const FETCH_READY_ORDERS = "home_delivery/FETCH_READY_ORDERS";
export const FETCH_READY_ORDERS_SUCCESS =
  "home_delivery/FETCH_READY_ORDERS_SUCCESS";
export const FETCH_READY_ORDERS_FAIL = "home_delivery/FETCH_READY_ORDERS_FAIL";
export const FETCH_IN_PROGRESS_ORDERS =
  "home_delivery/FETCH_IN_PROGRESS_ORDERS";
export const FETCH_IN_PROGRESS_ORDERS_SUCCESS =
  "home_delivery/FETCH_IN_PROGRESS_ORDERS_SUCCESS";
export const FETCH_IN_PROGRESS_ORDERS_FAIL =
  "home_delivery/FETCH_IN_PROGRESS_ORDERS_FAIL";
export const FETCH_RIDER_STATS = "home_delivery/FETCH_RIDER_STATS";
export const FETCH_RIDER_STATS_SUCCESS =
  "home_delivery/FETCH_RIDER_STATS_SUCCESS";
export const FETCH_RIDER_STATS_FAIL = "home_delivery/FETCH_RIDER_STATS_FAIL";
export const FETCH_RIDER_ORDERS = "home_delivery/FETCH_RIDER_ORDERS";
export const FETCH_RIDER_ORDERS_SUCCESS =
  "home_delivery/FETCH_RIDER_ORDERS_SUCCESS";
export const FETCH_RIDER_ORDERS_FAIL = "home_delivery/FETCH_RIDER_ORDERS_FAIL";
export const RESET_DELIVERED_DRIVER_ORDER_DETAILS =
  "home_delivery/RESET_DELIVERED_DRIVER_ORDER_DETAILS";
export const SET_ORDER_MARK_AS_READY = "home_delivery/SET_ORDER_MARK_AS_READY";
export const SET_ORDER_MARK_AS_READY_SUCCESS =
  "home_delivery/SET_ORDER_MARK_AS_READY_SUCCESS";
export const SET_ORDER_MARK_AS_READY_FAIL =
  "home_delivery/SET_ORDER_MARK_AS_READY_FAIL";
export const SET_ORDER_ASSIGN_RIDER = "home_delivery/SET_ORDER_ASSIGN_RIDER";
export const SET_ORDER_ASSIGN_RIDER_SUCCESS =
  "home_delivery/SET_ORDER_ASSIGN_RIDER_SUCCESS";
export const SET_ORDER_ASSIGN_RIDER_FAIL =
  "home_delivery/SET_ORDER_ASSIGN_RIDER_FAIL";
export const SET_ORDER_MARK_AS_DELIVERED =
  "home_delivery/SET_ORDER_MARK_AS_DELIVERED";
export const SET_ORDER_MARK_AS_DELIVERED_SUCCESS =
  "home_delivery/SET_ORDER_MARK_AS_DELIVERED_SUCCESS";
export const SET_ORDER_MARK_AS_DELIVERED_FAIL =
  "home_delivery/SET_ORDER_MARK_AS_DELIVERED_FAIL";
export const SET_DELIVERED_ORDERS_REFRESH_REQUIRED =
  "home_delivery/SET_DELIVERED_ORDERS_REFRESH_REQUIRED";
export const SET_UNCONFIRMED_ORDER_CONFIRM =
  "home_delivery/SET_UNCONFIRMED_ORDER_CONFIRM";
export const SET_UNCONFIRMED_ORDER_CONFIRM_SUCCESS =
  "home_delivery/SET_UNCONFIRMED_ORDER_CONFIRM_SUCCESS";
export const SET_UNCONFIRMED_ORDER_CONFIRM_FAIL =
  "home_delivery/SET_UNCONFIRMED_ORDER_CONFIRM_FAIL";
export const SET_UNCONFIRMED_ORDER_REJECT =
  "home_delivery/SET_UNCONFIRMED_ORDER_REJECT";
export const SET_UNCONFIRMED_ORDER_REJECT_SUCCESS =
  "home_delivery/SET_UNCONFIRMED_ORDER_REJECT_SUCCESS";
export const SET_UNCONFIRMED_ORDER_REJECT_FAIL =
  "home_delivery/SET_UNCONFIRMED_ORDER_REJECT_FAIL";
export const SET_ORDER_MARK_AS_PICKUP =
  "home_delivery/SET_ORDER_MARK_AS_PICKUP";
export const SET_ORDER_MARK_AS_PICKUP_SUCCESS =
  "home_delivery/SET_ORDER_MARK_AS_PICKUP_SUCCESS";
export const SET_ORDER_MARK_AS_PICKUP_FAIL =
  "home_delivery/SET_ORDER_MARK_AS_PICKUP_FAIL";
