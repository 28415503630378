import * as types from "./types";

const reprintsReducer = ( state = [], action: any ) => {
  switch( action.type ) {
    case types.FETCH_REPRINTS_SUCCESS:
      return action.payload.data || [];
    case types.RESET_REPRINTS:
      return action.payload || []
    default: return state;
  }
};

const reducer = reprintsReducer;

export default reducer;
