import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import KeyboardReact, { SimpleKeyboard } from "react-simple-keyboard";
import { numberInputRegex } from "../../helpers/utils";

interface IKeypadProps {
  inputValue: any;
  setInputValue: Dispatch<SetStateAction<string>>;
  setKeyboardRef: (ref: simpleKeyboard) => void;
  setShowKeypad: (value: boolean) => void;
  paymentAction: () => void;
  isPaymentActionDisabled: boolean;
  showPaymentBoxCCInput: boolean;
  refCodeInputValue: string;
  setRefCodeInputValue: Dispatch<SetStateAction<string>>;
  showPaymentBreakDown: boolean;
}

interface simpleKeyboard {
  current: SimpleKeyboard;
}

function Keypad(props: IKeypadProps) {
  const intl = useIntl();
  const formattedMessage = (formatId: any) => {
    const translatedMessage = intl.formatMessage({ id: formatId });
    return translatedMessage;
  };

  const keyMessages = {
    keypadClear: formattedMessage("keypad_clear"),
    keypadDelete: formattedMessage("keypad_delete"),
    keypadClose: formattedMessage("keypad_close"),
    keypadPay: formattedMessage("keypad_pay"),
  };
  const [layout, setLayout] = useState({
    num: [
      "7 8 9 {bksp}",
      "4 5 6 {clearInput}",
      "1 2 3 {close}",
      "0 {dot} {void} {payNow}",
    ],
  });

  const keyboard = useRef() as simpleKeyboard;

  useEffect(() => {
    props.setKeyboardRef(keyboard);
    keyboard.current.setInput(getInputValue());
  }, []);

  useEffect(() => {
    keyboard.current.setInput(getInputValue());
  }, [props.showPaymentBoxCCInput]);

  const getInputValue = () => {
    return props.showPaymentBoxCCInput
      ? props.refCodeInputValue
      : props.inputValue;
  };

  const onKeyPress = (button: string) => {
    // map button press events
    let currentValue = keyboard.current!.getInput() as string;
    switch (button) {
      case "{dot}":
        if (numberInputRegex.test(currentValue)) {
          if (!currentValue.includes(".")) {
            currentValue += ".";
            keyboard.current!.setInput(currentValue);
          }
        }
        break;
      case "{close}":
        props.setShowKeypad(false);
        break;
      case "{clearInput}":
        keyboard.current!.setInput("");
        break;
      case "{payNow}":
        if (!props.isPaymentActionDisabled) {
          props.paymentAction();
        }
        break;
      default:
        return;
    }
  };
  const onChange = (input: string) => {
    if (props.showPaymentBoxCCInput) {
      props.setRefCodeInputValue(input);
    } else {
      props.setInputValue(input);
    }
  };
  return (
    <KeyboardReact
      keyboardRef={(r: any) => (keyboard.current = r)}
      layoutName="num"
      layout={layout}
      onChange={onChange}
      onKeyPress={onKeyPress}
      display={{
        "{clearInput}": keyMessages.keypadClear,
        "{bksp}": keyMessages.keypadDelete,
        "{void}": " ",
        "{close}": keyMessages.keypadClose,
        "{payNow}": keyMessages.keypadPay,
        "{dot}": ".",
      }}
      theme={
        props.showPaymentBoxCCInput
          ? `hg-theme-default cc-input-position ${
              props.showPaymentBreakDown ? "with-breakdown" : ""
            }`
          : "hg-theme-default default-position"
      }
      buttonTheme={[
        {
          class: "action-btn",
          buttons: "{clearInput} {bksp} {void} {close} {payNow}",
        },
        {
          class: "empty-btn",
          buttons: "{void}",
        },
        {
          class: "number-btn",
          buttons: "0 1 2 3 4 5 6 7 8 9 {dot}",
        },
        {
          class: "pay-btn",
          buttons: "{payNow}",
        },
      ]}
    />
  );
}

export default Keypad;
