import React from "react";
import { FormattedMessage } from "react-intl";
import { getEnvValueFor } from "../../helpers/utils";

interface iDmmsDisabledProps {
  branchName: string;
}

function DmmsDisabled(props: iDmmsDisabledProps) {
  return (
    <div className="dmms-disabled container">
      <h1 className="head">
        <FormattedMessage id="dm_disabled" />
      </h1>
      <p className="message">
        <FormattedMessage
          id="dm_disabled_message1"
          values={{ branchname: props.branchName }}
        />{" "}
        . <FormattedMessage id="dm_disabled_message2" />:{" "}
      </p>
      <ol className="info-list">
        <li className="info">
          <FormattedMessage id="dm_disabled_message3" /> &gt;{" "}
          <a
            href={`${getEnvValueFor("SAPAAD_CORE_BASE_URL")}/setup_locations`}
            className="link"
          >
            <FormattedMessage id="dm_disabled_message4" />
          </a>
        </li>
        <li className="info">
          <FormattedMessage id="dm_disabled_message5" />
        </li>
        <li className="info">
          <FormattedMessage id="dm_disabled_message6" />
        </li>
      </ol>
    </div>
  );
}

export default DmmsDisabled;
