import * as types from "./types";

const currentTabReducer = (state: any="", action: any) => {
  switch(action.type) {
    case types.SET_CURRENT_TAB:
      return action.payload || "";
    default: return state
  }
}

const reducer = currentTabReducer;

export default reducer;
