import React from "react";
import { FormattedMessage } from "react-intl";
import { getWhatModifiedString } from "../../../helpers/modificationString";
import { getStatusTime } from "../../../helpers/orderFunctions";

interface iModificationHistoryProps {
  order: any;
}

function ModificationHistory(props: iModificationHistoryProps) {
  const getModificationHistory = (items: any) => {
    let modifications: any = [];
    items.filter((item: any) => {
      if (item.modification_history.length) {
        item.modification_history = item.modification_history.map(
          (modification: any) => {
            modification.item_name = item.name;
            return modification;
          }
        );
        modifications = modifications.concat(item.modification_history);
      }
    });
    let modification_history;
    if (modifications.length) {
      modification_history = modifications.map((item: any, index: any) => {
        return (
          <div key={index} className="content-row">
            <div className="content-item">{item.item_name}</div>
            <div className="content-item">
              {getWhatModifiedString(item.item_name, item)}
            </div>
            <div className="content-item">
              {item.staff_name ? item.staff_name : ""}
            </div>
            <div className="content-item">{getStatusTime(item.created_at)}</div>
            <div className="content-item">{item.reason}</div>
          </div>
        );
      });
    } else {
      modification_history = (
        <div className="no-modifications">
          <FormattedMessage id="order_popup_no_modifications" />
        </div>
      );
    }
    return modification_history;
  };

  return (
    <div className="order-status-history">
      <div className="order-history">
        <p>
          <FormattedMessage id="order_popup_modified_history" />
        </p>
        <div className="order-status-history-table">
          <div className="header">
            <div className="tableRow">
              <div className="header-item">
                <FormattedMessage id="order_popup_modified_item" />
              </div>
              <div className="header-item">
                <FormattedMessage id="order_popup_what_modified" />
              </div>
              <div className="header-item">
                <FormattedMessage id="order_popup_who_modified" />
              </div>
              <div className="header-item">
                <FormattedMessage id="order.time" />
              </div>
              <div className="header-item">
                <FormattedMessage id="order_popup_modified_reason" />
              </div>
            </div>
          </div>
          {props.order.event === "order_updated" ||
          props.order.event === "scheduled_order_fired" ? (
            <div className="content-wrapper">
              {props.order.order_extended_info.items.length > 0
                ? getModificationHistory(props.order.order_extended_info.items)
                : null}
            </div>
          ) : (
            <div className="no-modifications">
              <FormattedMessage id="order_popup_no_modifications" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModificationHistory;
