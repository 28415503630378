import React from "react";

function MapSvg() {
  return (
    <div className="map-svg-container">
      <svg
        height="511pt"
        viewBox="0 1 511.99995 511"
        width="511pt"
        xmlns="http://www.w3.org/2000/svg"
        style={{ width: "30px", height: "30px" }}
      >
        <path d="m128.378906 272.066406h-22.960937c-6.023438 0-11.460938 3.585938-13.84375 9.109375l-63.5625 148.003907 150.953125-77.097657c-18.070313-25.003906-35.949219-52.582031-50.585938-80.015625zm0 0" />
        <path d="m420.421875 281.175781c-2.378906-5.523437-7.820313-9.109375-13.839844-9.109375h-22.960937c-27.640625 51.796875-67.910156 105.324219-93.527344 134.773438-18.015625 20.664062-50.195312 20.640625-68.183594 0-1.839844-2.113282-11.445312-13.234375-24.675781-30.335938l-27.214844 13.90625 102.945313 102.746094 195.0625-101.125zm0 0" />
        <path d="m9.441406 472.433594-8.210937 19.121094c-4.25 9.894531 3.03125 20.945312 13.839843 20.945312h221.050782c1.382812-1.027344 1.398437-.964844 8.820312-4.8125l-103.097656-102.882812zm0 0" />
        <path d="m510.769531 491.554688-30.84375-71.820313-178.9375 92.765625h195.941407c10.785156 0 18.097656-11.03125 13.839843-20.945312zm0 0" />
        <path d="m271.058594 135.824219c0-8.289063-6.757813-15.027344-15.058594-15.027344-8.304688 0-15.058594 6.738281-15.058594 15.027344 0 8.285156 6.753906 15.027343 15.058594 15.027343 8.300781 0 15.058594-6.742187 15.058594-15.027343zm0 0" />
        <path d="m267.363281 386.117188c5.070313-5.820313 124.160157-143.578126 124.160157-238.273438 0-195.265625-271.046876-197.648438-271.046876 0 0 94.695312 119.089844 232.453125 124.160157 238.273438 6.003906 6.886718 16.734375 6.878906 22.726562 0zm-56.539062-250.292969c0-24.859375 20.265625-45.082031 45.175781-45.082031 24.90625 0 45.171875 20.222656 45.171875 45.082031 0 24.855469-20.265625 45.078125-45.171875 45.078125-24.910156 0-45.175781-20.222656-45.175781-45.078125zm0 0" />
      </svg>
    </div>
  );
}

export default MapSvg;
