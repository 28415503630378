import actions from "./actions";

const fetchNewPickupOrders = actions.fetchNewPickupOrders;
const fetchReadyPickupOrders = actions.fetchReadyPickupOrders;
const fetchCollectedPickupOrders = actions.fetchCollectedPickupOrders;
const markOrderReady = actions.markOrderReady;
const markOrderCollected = actions.markOrderCollected;
const fetchPickupUnconfirmedOrders = actions.fetchPickupUnconfirmedOrders;
const markPickupUnconfirmedOrderConfirm =
  actions.markPickupUnconfirmedOrderConfirm;
const markPickupUnconfirmedOrderReject =
  actions.markPickupUnconfirmedOrderReject;
const setCollectedRefreshRequired = actions.setCollectedRefreshRequired;
const markPickupUnconfirmedOrderRejectWithReason =
  actions.markPickupUnconfirmedOrderRejectWithReason;
const markPickupOrderConfirmedWithPayment = actions.markPickupOrderConfirmedWithPayment;
  
export default {
  fetchNewPickupOrders,
  fetchReadyPickupOrders,
  fetchCollectedPickupOrders,
  markOrderReady,
  markOrderCollected,
  fetchPickupUnconfirmedOrders,
  markPickupUnconfirmedOrderConfirm,
  markPickupUnconfirmedOrderReject,
  setCollectedRefreshRequired,
  markPickupUnconfirmedOrderRejectWithReason,
  markPickupOrderConfirmedWithPayment,
};
