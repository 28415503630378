import React from "react";
import { FormattedMessage } from "react-intl";
import { hardRefresh } from "../../helpers/utils";

function NoInternet() {
  return (
    <div className="no-internet-overlay-container">
      <svg
        id="_x31__px"
        className="icon"
        height="512"
        viewBox="0 0 24 24"
        width="512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m22.5 13h-11c-.827 0-1.5-.673-1.5-1.5 0-.293.081-.568.234-.799l5.489-8.982c.259-.441.751-.719 1.277-.719s1.018.278 1.28.727l5.496 8.992c.143.213.224.488.224.781 0 .827-.673 1.5-1.5 1.5zm-5.5-11c-.174 0-.334.089-.419.232l-5.504 9.008c-.055.082-.077.166-.077.26 0 .275.225.5.5.5h11c.275 0 .5-.225.5-.5 0-.094-.022-.178-.066-.242l-5.511-9.018c-.089-.151-.249-.24-.423-.24z" />
        <path d="m17 9c-.276 0-.5-.224-.5-.5v-4c0-.276.224-.5.5-.5s.5.224.5.5v4c0 .276-.224.5-.5.5z" />
        <circle cx="17" cy="10.5" r=".5" />
        <path d="m2.88 13c-.128 0-.256-.049-.354-.146l-1.939-1.94c-.373-.372-.587-.888-.587-1.414s.214-1.042.588-1.414l3.499-3.499c.742-.746 2.083-.747 2.827.001l1.939 1.938c.195.195.195.512 0 .707s-.512.195-.707 0l-1.94-1.939c-.373-.375-1.04-.374-1.411-.001l-3.501 3.501c-.188.186-.294.443-.294.706s.106.52.293.705l1.94 1.941c.195.195.195.512 0 .707-.097.098-.225.147-.353.147z" />
        <path d="m11 20c-.404 0-.782-.155-1.063-.437l-1.367-1.366-1.925-.21c-.552-.049-1.088-.303-1.499-.714l-2.71-2.71c-.281-.281-.436-.659-.436-1.063s.155-.782.437-1.063l6-6c.563-.563 1.564-.563 2.127 0l.41.41c.195.195.195.512 0 .707s-.512.195-.707 0l-.41-.41c-.186-.186-.527-.186-.713 0l-6 6c-.093.092-.144.218-.144.356s.051.264.144.356l2.71 2.71c.242.242.566.396.891.426l2.11.23c.113.013.219.063.299.144l1.49 1.49c.186.186.527.186.713 0l3.5-3.5c.092-.092.143-.218.143-.356 0-.09-.021-.167-.061-.229-.149-.232-.082-.542.15-.691.231-.15.541-.082.691.15.144.222.22.489.22.77 0 .404-.155.782-.437 1.063l-3.5 3.5c-.281.282-.659.437-1.063.437z" />
        <path d="m19.5 22c-.128 0-.256-.049-.354-.146l-5-5c-.195-.195-.195-.512 0-.707s.512-.195.707 0l5 5c.195.195.195.512 0 .707-.097.097-.225.146-.353.146z" />
        <path d="m17.5 24c-.128 0-.256-.049-.354-.146l-5-5c-.195-.195-.195-.512 0-.707s.512-.195.707 0l5 5c.195.195.195.512 0 .707-.097.097-.225.146-.353.146z" />
        <path d="m3.5 10c-.128 0-.256-.049-.354-.146l-1.81-1.81c-.195-.195-.195-.512 0-.707s.512-.195.707 0l1.81 1.81c.195.195.195.512 0 .707-.097.097-.225.146-.353.146z" />
        <path d="m5.5 8c-.128 0-.256-.049-.354-.146l-1.81-1.81c-.195-.195-.195-.512 0-.707s.512-.195.707 0l1.81 1.81c.195.195.195.512 0 .707-.097.097-.225.146-.353.146z" />
      </svg>
      <p className="title">
        <FormattedMessage id="network_error3" />
      </p>
      <div className="message">
        <span>
          <FormattedMessage id="network_error" />
        </span>
        <span>
          <FormattedMessage id="network_error1" />
        </span>
      </div>
      <button className="btn btn-cta" onClick={() => hardRefresh()}>
        <FormattedMessage id="network_error2" />
      </button>
    </div>
  );
}

export default NoInternet;
