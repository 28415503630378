import actions from "./actions";

const fetchNewOrders = actions.fetchNewOrders;
const fetchInProgressOrders = actions.fetchInProgressOrders;
const fetchReadyOrders = actions.fetchReadyOrders;
const fetchUnconfirmedOrders = actions.fetchUnconfirmedOrders;
const fetchDeliveredOrders = actions.fetchDeliveredOrders;
const markOrderReady = actions.markOrderReady;
const assignDriver = actions.assignDriver;
const markOrderDelivered = actions.markOrderDelivered;
const fetchDeliveredDriverOrderDetails =
  actions.fetchDeliveredDriverOrderDetails;
const resetDeliveredDriverOrderDetails =
  actions.resetDeliveredDriverOrderDetails;
const setDeliveredRefreshRequired = actions.setDeliveredRefreshRequired;
const markOrderConfirm = actions.markOrderConfirm;
const markOrderReject = actions.markOrderReject;
const markOrderConfirmWithEta = actions.markOrderConfirmWithEta;
const markOrderRejectWithReason = actions.markOrderRejectWithReason;
const markOrderPickedUp = actions.markOrderPickedUp;

export default {
  fetchNewOrders,
  fetchInProgressOrders,
  fetchReadyOrders,
  fetchUnconfirmedOrders,
  fetchDeliveredOrders,
  markOrderReady,
  assignDriver,
  markOrderDelivered,
  fetchDeliveredDriverOrderDetails,
  resetDeliveredDriverOrderDetails,
  setDeliveredRefreshRequired,
  markOrderConfirm,
  markOrderReject,
  markOrderConfirmWithEta,
  markOrderRejectWithReason,
  markOrderPickedUp,
};
