import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import ModalCloseIcon from "../modalCloseIcon";
import logisticOrderStatus from "../../helpers/logisticOrderStatus";
import LogisticDetailsPopup from "../logisticDetailsPopup";
import { connect } from "react-redux";
import { logisticPartnersOperations } from "../../state/features/logisticPartners";
import { FormattedMessage } from "react-intl";
import RiderTracking from "../riderTracking";
import { driverOperations } from "../../state/features/drivers";
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";
import RidersRoster from "./ridersRoster";
import { Button } from "../throttleButton";
import { useDebouncer } from "../../hooks/useDebouncer";

interface iDeliveryPartnerPopupProps {
  order: any;
  handleClose: any;
  deliveryPartners: any[];
  assignPartner: any;
  onPartnerAssign: any;
  cancelLogisticJob?: any;
  companyDrivers?: any;
  assignNewOrderRider?: any;
  assignReadyOrderRiderWithoutStateChange?: any;
  riderAppEnabled?: boolean;
  unassignReadyOrderRider?: any;
  unassignNewOrderRider?: any;
  orderType: string;
  handleOrder: any;
}

function DeliveryPartnerPopup(props: iDeliveryPartnerPopupProps) {
  const [show, setShow] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const [showThirdPartyDetails, setShowThirdPartyDetails] = useState(false);
  const [partnerDrivers, setPartnerDrivers] = useState([] as any);
  const [trackingUrl, setTrackingUrl] = useState("");
  const [selectedDriver, setSelectedDriver] = useState({} as any);
  const [showOwnDriverStatus, setShowOwnDriverStatus] = useState(false);
  const [companyDrivers, setCompanyDrivers] = useState([] as any);

  const { debounceFn } = useDebouncer({ interval: 3000 });

  const handleClose = () => {
    setShow(false);
    props.handleClose();
  };

  useEffect(() => {
    setShowLoading(false);
  }, []);

  const findDriver = (partner: any) => {
    props.onPartnerAssign(true);
    props
      .assignPartner(props.order.order_id, parseInt(partner.id))
      .then((res: any) => {
        props.handleClose();
      })
      .catch((err: AxiosErrorResponse) => console.log(err));
  };

  useEffect(() => {
    // Close popup when order has delivered status
    if (
      props.order.logistics_job !== undefined &&
      props.order.logistics_job?.status === 6
    ) {
      handleClose();
    }
    if (
      props.order.logistics_job !== undefined &&
      props.order.logistics_job?.metadata !== undefined
    ) {
      setTrackingUrl(props.order.logistics_job.metadata.track_url || "");
    }
    if (props.riderAppEnabled && props.order.rider_id) {
      if (props.companyDrivers) {
        let assignedRider = props.companyDrivers.find(
          (driver: any) => driver.id === props.order.rider_id
        );
        setSelectedDriver(assignedRider);
        setShowOwnDriverStatus(true);
      }
    }
  }, [props.order]);

  useEffect(() => {
    setPartnerDrivers(props.deliveryPartners);
  }, [props.deliveryPartners.length]);

  const handleCancelPickup = () => {
    props
      .cancelLogisticJob(props.order.order_id)
      .then((res: any) => {
        handleClose();
      })
      .catch((err: AxiosErrorResponse) => console.log(err));
  };

  const handleDriverSelect = (driver: any, type: string) => {
    if (type !== "own") {
      findDriver(driver);
    } else {
      setSelectedDriver(driver);
    }
  };

  const handleRiderAssign = (driver: any) => {
    if (props.order.order_status.includes("ready")) {
      props
        .assignReadyOrderRiderWithoutStateChange(
          props.order.order_id,
          driver.id
        )
        .then((res: any) => {
          if (!res.error) {
            handleClose();
          }
        })
        .catch((err: AxiosErrorResponse) => console.log(err));
    } else {
      props
        .assignNewOrderRider(props.order.order_id, driver.id)
        .then((res: any) => {
          if (!res.error) {
            handleClose();
          }
        })
        .catch((err: AxiosErrorResponse) => console.log(err));
    }
  };

  useEffect(() => {
    let drivers = props.companyDrivers.filter((driver: any) =>
      driver.assigned_locations.includes(props.order.location_id)
    );
    setCompanyDrivers(drivers);
  }, [props.companyDrivers, props.order]);

  const showCompanyRiders = useMemo(() => {
    return (
      props.order.order_status.includes("ready") ||
      (props.riderAppEnabled && props.orderType !== "scheduled")
    );
  }, [props.riderAppEnabled, props.order, props.orderType]);

  const handleRiderUnassign = () => {
    if (props.order.order_status.includes("ready")) {
      props
        .unassignReadyOrderRider(props.order.order_id)
        .then((res: any) => {
          if (!res.error) {
            handleClose();
          }
        })
        .catch((err: AxiosErrorResponse) => console.log(err));
    } else {
      props
        .unassignNewOrderRider(props.order.order_id)
        .then((res: any) => {
          if (!res.error) {
            handleClose();
          }
        })
        .catch((err: AxiosErrorResponse) => console.log(err));
    }
  };

  return (
    <>
      <Modal
        show={show}
        dialogClassName={`custom curved order-popup-wrapper ${
          props.riderAppEnabled
            ? "third-party-delivery-modal"
            : "third-party-delivery-modal-small"
        }`}
        onHide={handleClose}
        centered>
        <ModalCloseIcon onClick={handleClose} />
        <div style={{ minHeight: "350px", padding: "1rem" }}>
          {showLoading ? (
            <div>
              <FormattedMessage id="logistic.please_wait" />
            </div>
          ) : (
            <>
              {props.order.logistics_job &&
              props.order.logistics_job.status != 3 ? (
                <div
                  className={
                    logisticOrderStatus[props.order.logistics_job.status]
                      .class_name
                  }
                  style={{ display: "block" }}>
                  <h2>
                    {
                      logisticOrderStatus[props.order.logistics_job.status]
                        .popup_title
                    }
                    {
                      logisticOrderStatus[props.order.logistics_job.status]
                        .concatString
                    }
                  </h2>
                  <p>
                    {
                      logisticOrderStatus[props.order.logistics_job.status]
                        .popup_text
                    }
                  </p>
                  <img
                    src={
                      logisticOrderStatus[props.order.logistics_job.status]
                        .popup_image
                    }
                    className="mt-2"
                  />
                  {props.order.logistics_job.metadata.track_url ? (
                    <div className="button-group-flex">
                      <button
                        className="btn btn-block btn-primary mt-2"
                        onClick={() => setShowThirdPartyDetails(true)}>
                        <FormattedMessage id="logistic_details" />
                      </button>
                    </div>
                  ) : (
                    <div className="button-group-flex">
                      <Button
                        className="btn btn-block btn-danger mt-2"
                        onClick={() => handleCancelPickup()}>
                        <FormattedMessage id="logistic_cancel" />
                      </Button>
                    </div>
                  )}
                </div>
              ) : props.riderAppEnabled ? (
                <div className="partner-selection">
                  {showOwnDriverStatus ? (
                    <div>
                      <p>
                        <FormattedMessage id="own.driver" />
                      </p>
                      <h2>{selectedDriver?.name ?? ""}</h2>
                      <RiderTracking
                        order={props.order}
                        locationId={props.order.location_id}
                        riderDetails={selectedDriver}
                      />
                    </div>
                  ) : (
                    <>
                      <RidersRoster
                        companyRiders={companyDrivers}
                        partnerRiders={partnerDrivers}
                        showCompanyRiders={showCompanyRiders}
                        onCompanyRiderSelect={(rider) => {
                          handleDriverSelect(rider, "own");
                        }}
                        onShowRiderTracking={(rider) => {
                          handleDriverSelect(rider, "own");
                          setShowOwnDriverStatus(true);
                        }}
                        onPartnerRiderSelect={(partnerRider) => {
                          handleDriverSelect(partnerRider, "logistics");
                        }}
                        assignCompanyRider={(rider) => handleRiderAssign(rider)}
                      />
                    </>
                  )}
                  <div className="footer">
                    {showOwnDriverStatus &&
                      !props.order.order_status?.includes("in_progress") &&
                      (props.order.rider_id ? (
                        <>
                          {props.order.order_status?.includes("ready") ? (
                            <Button
                              onClick={() => props.handleOrder()}
                              className="dropdown-group-btn btn light-green popup-footer-btn">
                              <FormattedMessage id="rider.order_collected_by_rider" />
                            </Button>
                          ) : null}
                          <Button
                            onClick={handleRiderUnassign}
                            className={`dropdown-group-btn show-map-button btn ${
                              props.order.order_status?.includes("ready")
                                ? "popup-footer-btn"
                                : ""
                            }`}>
                            <FormattedMessage id="order.unassign_rider" />
                          </Button>
                        </>
                      ) : (
                        <button
                          onClick={() => setShowOwnDriverStatus(false)}
                          className="dropdown-group-btn light-green btn px-5">
                          <FormattedMessage id="back" />
                        </button>
                      ))}
                  </div>
                </div>
              ) : (
                <div className="partner-selection">
                  <h2>
                    <FormattedMessage id="logistic_select_rider" />
                  </h2>
                  <p>
                    <FormattedMessage id="logistic_select_rider_message" />
                  </p>
                  <ul>
                    {partnerDrivers.length > 0 ? (
                      <>
                        {partnerDrivers.map((partner: any, index: number) => {
                          return (
                            <li
                              key={index}
                              onClick={() => debounceFn(findDriver(partner))}>
                              {partner.name}
                            </li>
                          );
                        })}
                      </>
                    ) : (
                      <li>
                        <FormattedMessage id="logistic_no_partners" />
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </>
          )}
        </div>
      </Modal>
      {showThirdPartyDetails && props.order.logistics_job ? (
        <LogisticDetailsPopup
          handleClose={() => setShowThirdPartyDetails(false)}
          trackUrl={trackingUrl}
          logisticStatus={props.order.logistics_job.status}
          orderId={props.order.order_id}
          onCancel={handleClose}
        />
      ) : null}
    </>
  );
}

const mapDispatchToProps = {
  cancelLogisticJob: logisticPartnersOperations.cancelLogisticJob,
  assignNewOrderRider: driverOperations.assignNewOrderRider,
  assignReadyOrderRiderWithoutStateChange:
    driverOperations.assignReadyOrderRiderWithoutStateChange,
  unassignNewOrderRider: driverOperations.unassignNewOrderRider,
  unassignReadyOrderRider: driverOperations.unassignReadyOrderRider,
};

export default connect(null, mapDispatchToProps)(DeliveryPartnerPopup);
