import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import {
  getLogisticsVisibilityConfig,
  groupOrders,
} from "../../helpers/orderFunctions";
import { currentTabOperations } from "../../state/features/currentTab";
import { homeDeliveryOperations } from "../../state/features/homeDelivery";
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";
import NoOrders from "../noOrders";
import Order from "../order";
import { AnimateOrderTransitions } from "../animateOrderTransitions";
import { AnimateTableTransition } from "../animateTableTransitions";
import { AnimateDeliveryAreaTransition } from "../animateDeliveryAreaTransitions";
import { groupOrdersByDeliveryArea } from "../../helpers/utils";
import { useAppStateSelector } from "../../hooks/useAppStateSelector";
import { useEntryTransitionConfig } from "../../hooks/useEntryTransitionConfig";
import OrderFiltersTab from "../orderFilters";
import { ORDER_STATUS } from "../../types/orderStatus";
import useOrderFilter from "../../hooks/useOrderFilter";
interface INewOrdersProps {
  markOrderReady: any;
  newOrders: any;
  company: any;
  setCurrentTab: any;
  logisticsPartners: any[];
  riderAppEnabled?: boolean;
}

function NewOrders(props: INewOrdersProps) {
  const [orderReady, markOrderReady] = useState(false);
  const [selecteOrderId, setSelectedOrderId] = useState(0);
  const [disableButton, setDisable] = useState(false);
  const { enableEntryTransition } = useEntryTransitionConfig();

  const { remove_grouping_by_delivery_area } = useAppStateSelector(
    (state) => state.company.company_details
  );
  const { filteredOrders, updateFilterId} = useOrderFilter(props.newOrders);

  useEffect(() => {
    props.setCurrentTab("homeDelivery.new");
  }, []);

  useEffect(() => {
    markOrderReady(false);
  }, [orderReady]);

  const filterdNewOrders = useMemo(() => {
    return groupOrders(filteredOrders)
  }, [filteredOrders]);

  const logisticsControlColumn = useMemo(
    () =>
      getLogisticsVisibilityConfig(
        props.newOrders,
        props.logisticsPartners,
        props.riderAppEnabled
      ),
    [
      JSON.stringify(props.newOrders),
      props.logisticsPartners,
      props.riderAppEnabled,
    ]
  );

  const setOrderReady = (orderId: any) => {
    setDisable(true);
    setSelectedOrderId(orderId);
    props
      .markOrderReady(orderId)
      .then(() => {
        setDisable(false);
      })
      .catch((err: AxiosErrorResponse) => {
        console.log(err);
        setDisable(false);
      });
    markOrderReady(true);
  };

  const hasOrders = useMemo(
    () => Object.keys(filterdNewOrders).length > 0,
    [JSON.stringify(filterdNewOrders)]
  );

  const ungroupedFilteredOrders = useMemo(() => {
    //for filtered orders without grouping by area
    let ordersArray:any[] = [];
    if(filterdNewOrders) {
      ordersArray = [].concat(...Object.values(filterdNewOrders));
    }
    return ordersArray;
  },[filterdNewOrders]);

  const ordersByDeliveryArea = groupOrdersByDeliveryArea(filterdNewOrders);

  return (
    <div className="new-orders">
      <OrderFiltersTab
        currentTab={ORDER_STATUS.DELIVERY_NEW}
        updateSelectedFilterOption={updateFilterId}
      />
      <AnimateTableTransition
        isVisited={enableEntryTransition}
        show={hasOrders}
        component={({ ref }) => (
          <table
            className="orders-table"
            cellPadding="0"
            cellSpacing="0"
            ref={ref}>
            <thead>
              <tr>
                <th>{""}</th>
                <th style={{ width: "180px" }}>
                  <FormattedMessage id="order.no" />.
                </th>
                <th className="orderTakenLabel">
                  <FormattedMessage id="order.taken_at" />
                </th>
                <th>
                  <FormattedMessage id="order.elapsed_time" />
                </th>
                <th className="text-left">
                  <FormattedMessage id="order.delivery_area" />
                </th>
                <th>
                  <FormattedMessage id="order.amount" />
                </th>
                {logisticsControlColumn.show ? (
                  props.riderAppEnabled ? (
                    <th className="text-center">
                      <FormattedMessage id="order.riders" />
                    </th>
                  ) : (
                    <th className="text-center">
                      <FormattedMessage id="order.partner_drivers" />
                    </th>
                  )
                ) : null}
                <th className="text-right">{""}</th>
              </tr>
            </thead>
            {remove_grouping_by_delivery_area ? (
              <tbody className="orders-list-table-body">
                <AnimateOrderTransitions
                  enableEntryTransition={enableEntryTransition}
                  orders={ungroupedFilteredOrders}
                  component={({ item: order }) => (
                    <Order
                      orderType="homeDelivery"
                      orderStatus="new"
                      key={order.order_id}
                      disableButton={
                        order.order_id === selecteOrderId && disableButton
                      }
                      handleOrderState={(orderId: any) =>
                        setOrderReady(orderId)
                      }
                      item={order}
                      showEmptyCell={logisticsControlColumn.show}
                    />
                  )}
                />
              </tbody>
            ) : (
              <>
                {ordersByDeliveryArea.map((item: any) => {
                  return (
                    <AnimateDeliveryAreaTransition
                      key={item.deliveryArea}
                      show={Boolean(item.orders.length)}
                      component={({ areaRef }) => (
                        <tbody
                          key={item.deliveryArea}
                          ref={areaRef}
                          className="orders-list-table-body"
                        >
                          <tr className="location-header">
                            <td colSpan={8}>
                              {item.deliveryArea == "null"
                                ? "N/A"
                                : item.deliveryArea}
                            </td>
                          </tr>
                          <AnimateOrderTransitions
                            enableEntryTransition={enableEntryTransition}
                            orders={item.orders}
                            component={({ item: order }) => (
                              <Order
                                orderType="homeDelivery"
                                orderStatus="new"
                                key={order.order_id}
                                disableButton={
                                  order.order_id === selecteOrderId &&
                                  disableButton
                                }
                                handleOrderState={(orderId: any) =>
                                  setOrderReady(orderId)
                                }
                                item={order}
                                showEmptyCell={logisticsControlColumn.show}
                              />
                            )}
                          />
                        </tbody>
                      )}
                    />
                  );
                })}
              </>
            )}
          </table>
        )}
      />
      {!hasOrders ? <NoOrders /> : null}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let newOrders = state.order.home_delivery.new_orders.orders || [];
  let company = state.company.company_details;
  let logisticsPartners = state.logisticPartners;
  let riderAppEnabled = state.company.company_details.rider_app_enabled;

  return {
    newOrders,
    company,
    logisticsPartners,
    riderAppEnabled,
  };
};

const mapDispatchToProps = {
  markOrderReady: homeDeliveryOperations.markOrderReady,
  setCurrentTab: currentTabOperations.setCurrentTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewOrders);
