import React, { useRef } from "react";

interface ThrottleButtonProps {
  onClick?: (event?: any) => any;
  className?: string;
  style?: any;
  disabled?: boolean;
  children?: React.ReactNode;
}

export function Button({
  onClick = () => {},
  className = "",
  style = {},
  disabled = false,
  children,
}: ThrottleButtonProps) {
  const debouncedClick = useRef(false);

  const handleClick = (e: any) => {
    if (!debouncedClick.current) {
      onClick(e);
      debouncedClick.current = true;
      setTimeout(() => {
        debouncedClick.current = false;
      }, 3000);
    }
  };

  return (
    <button
      className={className}
      onClick={handleClick}
      style={style}
      disabled={disabled}>
      {children}
    </button>
  );
}
