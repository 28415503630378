import noMap from "../../images/no-map.svg";
import { ErrorBoundary } from "react-error-boundary";
import React from "react";

const FallbackUiOnError = () => {
  return (
    <div className="error-fallback-ui">
      <div className="container">
        <div className="icon">
          <img src={noMap} alt="no-map" />
        </div>
        <div className="text-area">
          <p className="heading">Oops! Unable to load map</p>
          <p className="text">Please try again by reloading the page</p>
        </div>
      </div>
    </div>
  );
};

const MapErrorBoundary = ({ children }:any) => {
  return (
    <ErrorBoundary FallbackComponent={() => <FallbackUiOnError />}>
      {children}
    </ErrorBoundary>
  );
};

export default MapErrorBoundary;
