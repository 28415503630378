import { useLayoutEffect, useState } from "react";

const useEntryTransitionConfig = () => {
  const [enableEntryTransition, setEnableEntryTransition] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      setEnableEntryTransition(true);
    }, 1000);
  }, []);

  return { enableEntryTransition };
};

export { useEntryTransitionConfig };
