import * as types from "./types";

const fetchDrivers = (companyId: number) => ({
  type: types.FETCH_DRIVERS,
  payload: {
    request: {
      method: "get",
      url: `/api/v1/logistics/riders?company_id=${companyId}`,
    },
  },
});

const fetchRiderCoordinates = (driverId: any) => ({
  type: types.FETCH_RIDER_COORDINATES,
  payload: {
    request: {
      method: "get",
      url: `/api/v1/riders/${driverId}/geo-coordinates`,
    },
  },
});

const assignNewOrderRider = (orderId: any, driverId: any) => ({
  type: types.ASSIGN_NEW_RIDER,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/new/${orderId}/assign-rider?rider_id=${driverId}`,
    },
  },
  param: { order_id: orderId },
});

const assignReadyOrderRiderWithoutStateChange = (
  orderId: any,
  driverId: any
) => ({
  type: types.ASSIGN_READY_ORDER_RIDER,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/ready/${orderId}/assign-rider-without-state-change?rider_id=${driverId}`,
    },
  },
  param: { order_id: orderId },
});

const unassignNewOrderRider = (orderId: any, driverId: any) => ({
  type: types.UNASSIGN_NEW_ORDER_RIDER,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/new/${orderId}/unassign-rider`,
    },
  },
  param: { order_id: orderId },
});

const unassignReadyOrderRider = (orderId: any, driverId: any) => ({
  type: types.UNASSIGN_READY_ORDER_RIDER,
  payload: {
    request: {
      method: "post",
      url: `/api/v1/orders/new/${orderId}/unassign-rider`,
    },
  },
  param: { order_id: orderId },
});

export default {
  fetchDrivers,
  fetchRiderCoordinates,
  assignNewOrderRider,
  assignReadyOrderRiderWithoutStateChange,
  unassignNewOrderRider,
  unassignReadyOrderRider,
};
