import actions from "./actions";

const fetchDrivers = actions.fetchDrivers;
const fetchRiderCoordinates = actions.fetchRiderCoordinates;
const assignNewOrderRider = actions.assignNewOrderRider;
const assignReadyOrderRiderWithoutStateChange =
  actions.assignReadyOrderRiderWithoutStateChange;
const unassignNewOrderRider = actions.unassignNewOrderRider;
const unassignReadyOrderRider = actions.unassignReadyOrderRider;

export default {
  fetchDrivers,
  fetchRiderCoordinates,
  assignNewOrderRider,
  assignReadyOrderRiderWithoutStateChange,
  unassignNewOrderRider,
  unassignReadyOrderRider,
};
