export const FETCH_LOGISTIC_PARTNERS =
  "logisticPartners/FETCH_LOGISTIC_PARTNERS";
export const FETCH_LOGISTIC_PARTNERS_SUCCESS =
  "logisticPartners/FETCH_LOGISTIC_PARTNERS_SUCCESS";
export const FETCH_LOGISTIC_PARTNERS_FAIL =
  "logisticPartners/FETCH_LOGISTIC_PARTNERS_FAIL";
export const RESET_LOGISTIC_PARTNERS =
  "logisticPartners/RESET_LOGISTIC_PARTNERS";
export const ASSIGN_LOGISTIC_PARTNER =
  "logisticPartners/ASSIGN_LOGISTIC_PARTNER";
export const ASSIGN_LOGISTIC_PARTNER_SUCCESS =
  "logisticPartners/ASSIGN_LOGISTIC_PARTNER_SUCCESS";
export const ASSIGN_LOGISTIC_PARTNER_FAIL =
  "logisticPartners/ASSIGN_LOGISTIC_PARTNER_FAIL";
export const PUSHER_RECEIVED_LOGISTIC_JOB_CANCELLED =
  "logisticPartners/PUSHER_RECEIVED_LOGISTIC_JOB_CANCELLED";
export const CANCEL_LOGISTIC_JOB = "logisticPartners/CANCEL_LOGISTIC_JOB";
export const CANCEL_LOGISTIC_JOB_SUCCESS =
  "logisticPartners/CANCEL_LOGISTIC_JOB_SUCCESS";
export const CANCEL_LOGISTIC_JOB_FAIL =
  "logisticPartners/CANCEL_LOGISTIC_JOB_FAIL";
  export const PUSHER_RECEIVED_LOGISTIC_CANCEL_FAILED =
  "logisticPartners/PUSHER_RECEIVED_LOGISTIC_CANCEL_FAILED";
