import { combineReducers } from "redux"
import homeDeliveryReducer from "../homeDelivery/index"
import pickUpReducer from "../pickup/index"
import scheduledReducer from '../scheduled/index'

const reducer = combineReducers({
  home_delivery: homeDeliveryReducer,
  pickup: pickUpReducer,
  scheduled:scheduledReducer
});

export default reducer;
