import * as types from "./types";

const fetchOrder = (orderId: any) => ({
  type: types.FETCH_ORDER,
  payload: {
    request: {
      method: "get",
      url: `/api/v1/orders/${orderId}`,
    },
  },
  param: { order_id: orderId },
});

const removeExpiredOrder = (orderId: any) => ({
  type: types.REMOVE_EXPIRED_ORDER,
  payload: orderId,
});

export default {
  fetchOrder,
  removeExpiredOrder,
};
