import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { logisticPartnersOperations } from "../../state/features/logisticPartners";
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";
import ModalCloseIcon from "../modalCloseIcon";
import OrderButtonGroup from "../orderButtonGroup";

interface iLogisticDetailsPopupProps {
  handleClose: any;
  orderId: any;
  trackUrl: any;
  cancelLogisticJob: any;
  onCancel: any;
  logisticStatus: any;
}

function LogisticDetailsPopup(props: iLogisticDetailsPopupProps) {
  const [show, setShow] = useState(true);
  const [showCancelPickup, setShowCancelPickup] = useState(true);
  const handleClose = () => {
    setShow(false);
    props.handleClose();
  };
  const setCancelCtaText = () => {
    return <FormattedMessage id="logistic_cancel" />;
  }
  const handleCancelPickup = () => {
    props
      .cancelLogisticJob(props.orderId)
      .then((res: any) => {
        props.onCancel();
        handleClose();
      })
      .catch((err: AxiosErrorResponse) => console.log(err));
  };

  useEffect(() => {
    if (props.logisticStatus == 5 || props.logisticStatus == 6) {
      setShowCancelPickup(false);
    }
  }, [props.logisticStatus]);

  return (
    <Modal
      show={show}
      dialogClassName="custom curved order-popup-wrapper"
      onHide={handleClose}
      centered
    >
      <ModalCloseIcon onClick={handleClose} />
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="logistic_rider_details" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe
          scrolling={"no"}
          style={{ width: "100%", height: "400px" }}
          src={props.trackUrl}
          frameBorder="no"
          allowTransparency={true}
        ></iframe>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "space-between" }}>
        <button
          className="btn btn-outline-secondary px-5 py-3"
          style={{ position: "relative" }}
          onClick={handleClose}
        >
          <FormattedMessage id="keypad_close" />
        </button>
        {showCancelPickup ? (
          <OrderButtonGroup
            title={setCancelCtaText()}
            setOrderAction={handleCancelPickup}
            disable={false}
          />
        ) : null}
      </Modal.Footer>
    </Modal>
  );
}

const mapDispatchToProps = {
  cancelLogisticJob: logisticPartnersOperations.cancelLogisticJob,
};

export default connect(null, mapDispatchToProps)(LogisticDetailsPopup);
