function _setToken(tokenObj: any) {
  localStorage.setItem("jwt", tokenObj.jwt);
}
function _getAccessToken() {
  return localStorage.getItem("jwt");
}
function _getRefreshToken() {
  return localStorage.getItem("rt");
}
function _clearToken() {
  localStorage.removeItem("jwt");
  localStorage.removeItem("rt");
}

function loadState() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    console.debug(`[APP] Unable to fetch state from localStorage`);
    return undefined;
  }
}

function saveState(state: any) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (error) {
    console.debug(`[APP] Failed to persist state to localStorage`);
  }
}

export {
  _setToken,
  _getAccessToken,
  _getRefreshToken,
  _clearToken,
  loadState,
  saveState,
};
