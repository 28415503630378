import { removeLoyaltyPayment } from "../../../helpers/orderFunctions";
import * as types from "./types";

const paymentsReducer = (state = [], action: any) => {
  switch (action.type) {
    case types.FETCH_PAYMENTS_SUCCESS:
      let updatedPayments: any = removeLoyaltyPayment(action.payload?.data);
      return updatedPayments || [];

    default:
      return state;
  }
};

const reducer = paymentsReducer;

export default reducer;
