import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import * as Sentry from "@sentry/react";
import BranchesDropdown from "../branchesDropdown";
import OrderNotification from "../orderNotification";
import DarkMode from "../darkMode";
import Loader from "../loader";
import MainNav from "../mainNav";
import { orderEventsOperations } from "../../state/features/orderEvents";
import { companyConfigurations } from "../../state/features/company";
import { paymentsOperations } from "../../state/features/payments";
import { driverOperations } from "../../state/features/drivers";
import { orderOperations } from "../../state/features/order";
import { branchOperations } from "../../state/features/branches";
import { homeDeliveryOperations } from "../../state/features/homeDelivery";
import { scheduledOperations } from "../../state/features/scheduled";
import { useSelectedBranch } from "../../helpers/orderFunctions";
import { hardRefresh } from "../../helpers/utils";
import { pickupOperations } from "../../state/features/pickup";
import { useHistory } from "react-router";
import { notificationOperations } from "../../state/features/notifications";
import { logisticPartnersOperations } from "../../state/features/logisticPartners";
import { printSettingsOperations } from "../../state/features/printSettings";
import { riderOperations } from "../../state/features/riderProximity";
import { LOGISTICS_PARTNER_ORDER_CANCELLED_STATUS } from "../../helpers/constants";
import { useNotifyAudioContext } from "../../hooks/useNotifyAudioContext";
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";

interface IHeaderProps {
  loader?: any;
  selectedBranch?: any;
  branches?: any;
  company: any;
  orderMarkedReadyEvent: any;
  orderPickUpMarkedReady: any;
  orderRiderAssigned: any;
  orderMarkedDelivered: any;
  orderMarkedCollected: any;
  orderConfirmed: any;
  orderRejected: any;
  orderCreated: any;
  orderUpdated: any;
  orderVoided: any;
  payments: any;
  fetchCompany: any;
  fetchPayments: any;
  fetchDrivers: any;
  fetchOrder: any;
  fetchBranches: any;
  selectBranch: any;
  pusher: any;
  setDeliveredRefreshRequired: any;
  currentTab: any;
  fetchScheduledOrders?: any;
  orderMarkedReprinted?: any;
  setCollectedRefreshRequired?: any;
  clearHomeDeliveryNotifications?: any;
  clearPickupNotifications?: any;
  logisticJobCancelled: any;
  logisticCancelFailed?: any;
  fetchPrintSettings: any;
  fetchLogisticPartners: any;
  customerArrived: any;
  orderMarkedPickedUp: any;
  orderRiderUnassigned: any;
  orderTipCollected: any;
  orderCompanyRiderAssigned: any;
  orderPartnerRiderAssigned: any;
  notifications: any;
}

function Header(props: IHeaderProps) {
  let history = useHistory();
  const [hideUnconfirmed, setHideUnconfirmed] = useState(true);

  const {
    playNewOrderAudio,
    playUnconfirmedOrderAudio,
    cancelUnconfirmedAudio,
  } = useNotifyAudioContext();

  const createNotifierService = ({
    channels,
    events,
    orderTypes = [],
    orderStatuses = [],
  }: {
    channels: string[];
    events: string[];
    orderTypes?: string[];
    orderStatuses?: string[];
  }) => {
    const notifierPluginEvent = new CustomEvent("dmms-plugin-events-pusher", {
      detail: { channels, events, orderTypes, orderStatuses },
    });
    dispatchEvent(notifierPluginEvent);
  };

  useEffect(() => {
    if (localStorage.jwt !== undefined && localStorage.rt !== undefined) {
      props
        .fetchCompany()
        .then((res: any) => {
          if (!res.error) {
            props
              .fetchBranches()
              .catch((err: AxiosErrorResponse) => console.log(err));
            props
              .fetchPayments()
              .catch((err: AxiosErrorResponse) => console.log(err));
            props
              .fetchPrintSettings()
              .catch((err: AxiosErrorResponse) => console.log(err));
            props
              .fetchLogisticPartners()
              .catch((err: AxiosErrorResponse) => console.log(err));
          }
        })
        .catch((err: AxiosErrorResponse) => console.log(err));
    } else {
      console.debug(`[JWT] JWT/RT missing in localStorage`);
      history.push("/login-error");
    }
  }, []);

  // Set context for Sentry
  useEffect(() => {
    // Sets User to the Sentry request context
    Sentry.setUser({
      id: props.company.user_id,
      username: props.company.user_name,
      is_admin: props.company.admin,
    });

    // Set general contexts
    Sentry.setContext("company_details", {
      company_id: props.company.company_id,
      company_name: props.company.company_name,
      country: props.company.country,
      currency: props.company.currency,
      business_end_time: props.company.business_end_time,
    });

    // Sets Sentry tags
    Sentry.setTag("company_id", props.company.company_id);
    Sentry.setTag("country", props.company.country);
    Sentry.setTag("user",props.company.user_id);
  }, [props.company]);

  useEffect(() => {
    let today: any = new Date();
    today.setUTCHours(0, 0, 0, 0);
    let business_end_time = props.company.business_end_time;
    let today_business_end_time = today / 1000 + business_end_time;
    let current_UTC_Time = Math.floor(new Date().getTime() / 1000);
    let timer: any;
    if (today_business_end_time >= current_UTC_Time) {
      let time_difference = today_business_end_time - current_UTC_Time;
      time_difference *= 1000;
      timer = setTimeout(() => {
        handleBusinessEndReload();
      }, time_difference);
    }
    return () => clearTimeout(timer);
  }, [props.company]);

  const handleBusinessEndReload = () => {
    if (
      window.location.pathname.split("/").pop() === "delivered" ||
      window.location.pathname.split("/").pop() === "collected"
    ) {
      hardRefresh();
    } else {
      props.setDeliveredRefreshRequired();
      props.setCollectedRefreshRequired();
    }
  };

  useEffect(() => {
    if (
      Object.keys(props.selectedBranch).length > 0 &&
      props.branches.length > 0 &&
      props.pusher &&
      props.company
    ) {
      if (props.selectedBranch.id) {
        console.debug(`[PUSHER] Unsubscribing from all branches`);
        props.branches.map((branch: any) => {
          return props.pusher.unsubscribe(`order_events_${branch.id}`);
        });
        let channel = props.pusher.subscribe(
          `order_events_${props.selectedBranch.id}`
        );
        console.debug(
          `[PUSHER] Subscribed to order events for ${props.selectedBranch.name}`
        );
        bindOrderEvents(channel);
        createNotifierService({
          channels: [`order_events_${props.selectedBranch.id}`],
          events: ["created"],
          orderStatuses: ["unconfirmed"],
        });

        props.branches.map((branch: any) => {
          return props.pusher.unsubscribe(`logistics_events_${branch.id}`);
        });
        let logisticJobChannel = props.pusher.subscribe(
          `logistics_events_${props.selectedBranch.id}`
        );
        bindLogisticJobEvents(logisticJobChannel);
      } else {
        if (props.company.admin) {
          // Admin user - All locations
          let channels = props.branches.map((branch: any) => {
            return props.pusher.subscribe(`order_events_${branch.id}`);
          });

          let logisticJobChannels = props.branches.map((branch: any) => {
            return props.pusher.subscribe(`logistics_events_${branch.id}`);
          });

          console.debug(
            `[PUSHER] Subscribed to order events for ${props.selectedBranch.name}`
          );

          channels.map((channel: any) => {
            bindOrderEvents(channel);
          });

          logisticJobChannels.map((channel: any) => {
            bindLogisticJobEvents(channel);
          });
          createNotifierService({
            channels: props.branches.map(
              (branch: any) => `order_events_${branch.id}`
            ),
            events: ["created"],
            orderStatuses: ["unconfirmed"],
          });
        } else {
          // Staff user - Multiple locations
          console.debug(`[PUSHER] Unsubscribing from all branches`);
          props.branches.map((branch: any) => {
            return props.pusher.unsubscribe(`order_events_${branch.id}`);
          });
          props.branches.map((branch: any) => {
            return props.pusher.unsubscribe(`logistics_events_${branch.id}`);
          });
          let channels = props.company.authorized_location_ids.map(
            (branch: any) => {
              return props.pusher.subscribe(`order_events_${branch}`);
            }
          );
          let logisticJobChannels = props.company.authorized_location_ids.map(
            (branch: any) => {
              return props.pusher.subscribe(`logistics_events_${branch}`);
            }
          );
          console.debug(
            `[PUSHER] Subscribed to order events for ${props.selectedBranch.name}`
          );
          createNotifierService({
            channels: props.branches.map(
              (branch: any) => `order_events_${branch.id}`
            ),
            events: ["created"],
            orderStatuses: ["unconfirmed"],
          });
          channels.map((channel: any) => {
            bindOrderEvents(channel);
          });
          logisticJobChannels.map((channel: any) => {
            bindLogisticJobEvents(channel);
          });
        }
      }
    }
    if (props.company.user_permissions?.includes("weborder.manage")) {
      setHideUnconfirmed(false);
    } else if (props.company.user_permissions?.includes("all.manage")) {
      setHideUnconfirmed(false);
    } else {
      setHideUnconfirmed(true);
    }
  }, [props.selectedBranch, props.branches, props.pusher, props.company]);

  useEffect(() => {
    if (Object.keys(props.company).length > 0 && props.pusher) {
      console.debug(`[PUSHER] Subscribing to company sync events`);
      let channel = props.pusher.subscribe(
        `company_synchronized_${props.company.company_id}`
      );
      channel.bind("company_synchronized", (data: any) => {
        console.debug(`[PUSHER] [company_synchronized]`);
        // TODO: Add sweet alert to notify user
        console.debug("Company details have been updated! Refreshing page...");
        setTimeout(() => {
          hardRefresh();
        }, 200);
      });
      console.debug(`[PUSHER] Subscribing to payment types sync events`);
      let paymentsChannel = props.pusher.subscribe(
        `payment_types_synchronized_${props.company.company_id}`
      );
      paymentsChannel.bind("payment_types_synchronized", (data: any) => {
        console.debug(`[PUSHER] [payment_types_synchronized]`);
        props.fetchPayments();
      });
      console.debug(`[PUSHER] Subscribing to location sync events`);
      let locationsChannel = props.pusher.subscribe(
        `locations_synchronized_${props.company.company_id}`
      );
      locationsChannel.bind("locations_synchronized", (data: any) => {
        console.debug(`[PUSHER] [locations_synchronized]`);
        // TODO: Add sweet alert to notify user
        console.debug("Branches have been updated! Refreshing page...");
        setTimeout(() => {
          hardRefresh();
        }, 200);
      });
      console.debug(`[PUSHER] Subscribing to riders sync events`);
      let ridersChannel = props.pusher.subscribe(
        `riders_synchronized_${props.company.company_id}`
      );
      ridersChannel.bind("riders_synchronized", (data: any) => {
        console.debug(`[PUSHER] [riders_synchronized]`);
        props
          .fetchDrivers(data.company_id)
          .catch((err: AxiosErrorResponse) => console.log(err));
      });
      console.debug(`[PUSHER] Subscribing to logistics partners sync events`);
      let logisticsChannel = props.pusher.subscribe(
        `logistics_partners_synchronized_${props.company.company_id}`
      );
      logisticsChannel.bind("logistics_partners_synchronized", (data: any) => {
        console.debug(`[PUSHER] [logistics_partners_synchronized]`);
        props
          .fetchLogisticPartners()
          .catch((err: AxiosErrorResponse) => console.log(err));
      });
      console.debug(`[PUSHER] Subscribing to print settings sync events`);
      let printSettingsChannel = props.pusher.subscribe(
        `print_settings_synchronized_${props.company.company_id}`
      );
      printSettingsChannel.bind("print_settings_synchronized", (data: any) => {
        console.debug(`[PUSHER] [print_settings_synchronized]`);
        props.fetchPrintSettings();
      });
      console.debug(`[PUSHER] Subscribing to rider proximity events`);
      let riderProximityChannel = props.pusher.subscribe(
        `riders_events_${props.company.company_id}`
      );
      riderProximityChannel.bind(
        "rider_geo_coordinates_updated",
        (data: any) => {
          console.debug(`[PUSHER] [rider_geo_coordinates_updated]`);
          props.fetchPrintSettings(data);
        }
      );
    }
  }, [props.company, props.pusher]);

  useEffect(() => {
    if (props.pusher) {
      props.pusher.connection.bind("connected", () => {
        console.debug(`[PUSHER] [PUSHER-CONNECTED]`);
      });
      props.pusher.connection.bind("unavailable", () => {
        console.debug(`[PUSHER] [PUSHER-UNAVAILABLE]`);
      });
      props.pusher.connection.bind("failed", () => {
        console.debug(`[PUSHER] [PUSHER-FAILED]`);
      });
      props.pusher.connection.bind("disconnected", () => {
        console.debug(`[PUSHER] [PUSHER-DISCONNECTED]`);
      });
      props.pusher.connection.bind("error", (err: any) => {
        if (err?.data?.code === 4004) {
          Sentry.addBreadcrumb({
            category: "pusher",
            type: "Error",
            message: "Pusher - connection limit exceeded",
            data: err,
            level: "error"
          });
          let error = new Error();
          error.name = "Pusher Error";
          error.message = "Pusher connection limit exceeded";
          Sentry.captureException(error);
        }
      });
    }
  }, [props.pusher]);

  useEffect(() => {
    if (props.currentTab) {
      if (props.currentTab === "homeDelivery.unconfirmed") {
        props.clearHomeDeliveryNotifications();
      }
      if (props.currentTab === "pickup.unconfirmed") {
        props.clearPickupNotifications();
      }
    }
  }, [props.currentTab]);

  function audioCancel() {
    if (
      (props.currentTab == "homeDelivery.unconfirmed" ||
        props.currentTab == "pickup.unconfirmed") &&
      props.notifications.pickup.count == 0 &&
      props.notifications.home_delivery.count == 0
    ) {
      cancelUnconfirmedAudio();
    }
  }

  useEffect(() => {
    if (document.hasFocus()) {
      audioCancel();
    }
    else {
      window.addEventListener("focus", audioCancel); 
    }
    return () => {
      window.removeEventListener("focus", audioCancel);
    };
  }, [JSON.stringify(props.notifications)]);

  const bindOrderEvents = (channel: any) => {
    channel.bind("marked_as_ready", (data: any) => {
      console.debug(
        `[PUSHER] [marked_as_ready] [${props.selectedBranch.name}]`,
        data
      );
      let messageDelay =
        Math.floor(new Date().getTime() / 1000) -
        Math.floor(data.occurred_at / 1000);
      Sentry.setTag("pusherMsgDelay", messageDelay);

      if (data.occurred_at && messageDelay < 5) {
        // process event only if the pusher message is delivered in under 5 seconds
        props.orderMarkedReadyEvent(data);
      } else {
        console.error(
          `[PUSHER] Delay in message delivery: ${messageDelay} seconds`
        );
      }
    });

    channel.bind("marked_pickup_as_ready", (data: any) => {
      console.debug(
        `[PUSHER] [marked_pickup_as_ready] [${props.selectedBranch.name}]`,
        data
      );

      let messageDelay =
        Math.floor(new Date().getTime() / 1000) -
        Math.floor(data.occurred_at / 1000);
      Sentry.setTag("pusherMsgDelay", messageDelay);

      if (data.occurred_at && messageDelay < 5) {
        // process event only if the pusher message is delivered in under 5 seconds
        props.orderPickUpMarkedReady(data);
      } else {
        console.error(
          `[PUSHER] Delay in message delivery: ${messageDelay} seconds`
        );
      }
    });

    channel.bind("rider_assigned", (data: any) => {
      console.debug(
        `[PUSHER] [rider_assigned] [${props.selectedBranch.name}]`,
        data
      );

      let messageDelay =
        Math.floor(new Date().getTime() / 1000) -
        Math.floor(data.occurred_at / 1000);
      Sentry.setTag("pusherMsgDelay", messageDelay);

      if (data.occurred_at && messageDelay < 5) {
        // process event only if the pusher message is delivered in under 5 seconds
        if (data.order_payload && Object.keys(data.order_payload).length > 0) {
          if (props.company.rider_app_enabled) {
            if (
              "logistics_job" in data.order_payload &&
              data.order_payload.logistics_job?.status !==
                LOGISTICS_PARTNER_ORDER_CANCELLED_STATUS
            ) {
              props.orderPartnerRiderAssigned(data);
            } else {
              props.orderCompanyRiderAssigned(data);
            }
          } else {
            props.orderRiderAssigned(data);
          }
        } else {
          props
            .fetchOrder(data.order_id)
            .catch((err: AxiosErrorResponse) => console.log(err));
        }
      } else {
        console.error(
          `[PUSHER] Delay in message delivery: ${messageDelay} seconds`
        );
      }
    });

    channel.bind("marked_as_delivered", (data: any) => {
      console.debug(
        `[PUSHER] [marked_as_delivered] [${props.selectedBranch.name}]`,
        data
      );

      let messageDelay =
        Math.floor(new Date().getTime() / 1000) -
        Math.floor(data.occurred_at / 1000);
      Sentry.setTag("pusherMsgDelay", messageDelay);

      if (data.occurred_at && messageDelay < 5) {
        // process event only if the pusher message is delivered in under 5 seconds
        props.orderMarkedDelivered(data);
      } else {
        console.error(
          `[PUSHER] Delay in message delivery: ${messageDelay} seconds`
        );
      }
    });

    channel.bind("marked_as_collected", (data: any) => {
      console.debug(
        `[PUSHER] [marked_as_collected] [${props.selectedBranch.name}]`
      );

      let messageDelay =
        Math.floor(new Date().getTime() / 1000) -
        Math.floor(data.occurred_at / 1000);
      Sentry.setTag("pusherMsgDelay", messageDelay);

      if (data.occurred_at && messageDelay < 5) {
        // process event only if the pusher message is delivered in under 5 seconds
        props.orderMarkedCollected(data);
      } else {
        console.error(
          `[PUSHER] Delay in message delivery: ${messageDelay} seconds`
        );
      }
    });

    channel.bind("marked_as_reprinted", (data: any) => {
      console.debug(
        `[PUSHER] [marked_as_reprinted] [${props.selectedBranch.name}]`
      );
      if (data.order_payload !== undefined) {
        props.orderMarkedReprinted(data);
      } else {
        props
          .fetchOrder(data.order_id)
          .catch((err: AxiosErrorResponse) => console.log(err));
      }
    });

    channel.bind("confirmed", (data: any) => {
      console.debug(`[PUSHER] [confirmed] [${props.selectedBranch.name}]`);
      props.orderConfirmed(data);
    });

    channel.bind("rejected", (data: any) => {
      console.debug(`[PUSHER] [rejected] [${props.selectedBranch.name}]`);
      props.orderRejected(data);
    });

    channel.bind("created", (data: any) => {
      console.debug(`[PUSHER] [created] [${props.selectedBranch.name}]`);
      if (data.scheduled && data.order_status !== "unconfirmed") {
        props
          .fetchScheduledOrders(useSelectedBranch(props.selectedBranch))
          .catch((err: AxiosErrorResponse) => console.log(err));
      }
      if (data.order_status === "unconfirmed") {
        if (
          props.company.user_permissions?.includes("weborder.manage") ||
          props.company.user_permissions?.includes("all.manage")
        ) {
          playUnconfirmedOrderAudio();
        }
      } else {
        playNewOrderAudio();
      }
      props.orderCreated(data);
    });

    channel.bind("updated", (data: any) => {
      console.debug(`[PUSHER] [updated] [${props.selectedBranch.name}]`);
      // props.orderUpdated(data); TODO: Confirm this behaviour
      hardRefresh();
    });

    channel.bind("voided", (data: any) => {
      console.debug(`[PUSHER] [voided] [${props.selectedBranch.name}]`);
      // props.orderVoided(data);
      hardRefresh();
    });

    channel.bind("customer_arrived", (data: any) => {
      console.debug(
        `[PUSHER] [customer_arrived] [${props.selectedBranch.name}]`
      );
      if (data.order_payload == undefined || data.order_payload == {}) {
        props
          .fetchOrder(data.order_id)
          .catch((err: AxiosErrorResponse) => console.log(err));
        props
          .fetchScheduledOrders(useSelectedBranch(props.selectedBranch))
          .catch((err: AxiosErrorResponse) => console.log(err));
      }
      props.customerArrived(data);
    });

    channel.bind("marked_as_picked_up", (data: any) => {
      console.debug(
        `[PUSHER] [marked_as_picked_up] [${props.selectedBranch.name}]`
      );
      if (data.order_payload == undefined || data.order_payload == {}) {
        props
          .fetchOrder(data.order_id)
          .catch((err: AxiosErrorResponse) => console.log(err));
        props
          .fetchScheduledOrders(useSelectedBranch(props.selectedBranch))
          .catch((err: AxiosErrorResponse) => console.log(err));
      }
      props.orderMarkedPickedUp(data);
    });

    channel.bind("rider_unassigned", (data: any) => {
      console.debug(
        `[PUSHER] [rider_unassigned] [${props.selectedBranch.name}]`
      );
      if (data.order_payload == undefined || data.order_payload == {}) {
        props
          .fetchOrder(data.order_id)
          .catch((err: AxiosErrorResponse) => console.log(err));
        props
          .fetchScheduledOrders(useSelectedBranch(props.selectedBranch))
          .catch((err: AxiosErrorResponse) => console.log(err));
      }
      props.orderRiderUnassigned(data);
    });

    channel.bind("tip_collected", (data: any) => {
      console.debug(`[PUSHER] [tip_collected] [${props.selectedBranch.name}]`);
      if (data.order_payload == undefined || data.order_payload == {}) {
        props
          .fetchOrder(data.order_id)
          .catch((err: AxiosErrorResponse) => console.log(err));
      }
      props.orderTipCollected(data);
    });
  };

  const bindLogisticJobEvents = (channel: any) => {
    channel.bind("job_updated", (data: any) => {
      console.debug(`[PUSHER] [job_updated] `, data);
      props
        .fetchOrder(data.order_id)
        .catch((err: AxiosErrorResponse) => console.log(err));
      props
        .fetchScheduledOrders(useSelectedBranch(props.selectedBranch))
        .catch((err: AxiosErrorResponse) => console.log(err));
      if (data.status == 3) {
        props.logisticJobCancelled(data);
      }
      if (data.metadata.failure_message) {
        props.logisticCancelFailed(data);
      }
    });

    channel.bind("job_cancelled", (data: any) => {
      console.debug(`[PUSHER] [job_cancelled] `, data);
      props.logisticJobCancelled(data);
      props
        .fetchOrder(data.order_id)
        .catch((err: AxiosErrorResponse) => console.log(err));
      props
        .fetchScheduledOrders(useSelectedBranch(props.selectedBranch))
        .catch((err: AxiosErrorResponse) => console.log(err));
    });

    channel.bind("job_created", (data: any) => {
      console.debug(`[PUSHER] [job_created] `, data);
      props
        .fetchOrder(data.order_id)
        .catch((err: AxiosErrorResponse) => console.log(err));
      props
        .fetchScheduledOrders(useSelectedBranch(props.selectedBranch))
        .catch((err: AxiosErrorResponse) => console.log(err));
      if (data.metadata.failure_message) {
        props.logisticCancelFailed(data);
      }
    });
  };

  return (
    <>
      {!hideUnconfirmed ? <OrderNotification /> : null}
      <div className="header">
        {props.loader.loading && <Loader message={props.loader.message} />}
        <BranchesDropdown />
        <MainNav />
        <div className="float-right">
          <DarkMode />
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state: any) => {
  let loader = state.loader;
  let selectedBranch = state.company.selected_branch;
  let branches = state.branches;
  let company = state.company.company_details;
  let payments = state.payments;
  let currentTab = state.currentTab;
  let notifications = state.notifications;

  return {
    loader,
    selectedBranch,
    branches,
    company,
    payments,
    currentTab,
    notifications,
  };
};

const mapDispatchToProps = {
  fetchCompany: companyConfigurations.fetchCompany,
  fetchBranches: branchOperations.fetchBranches,
  fetchPayments: paymentsOperations.fetchPayments,
  fetchDrivers: driverOperations.fetchDrivers,
  fetchOrder: orderOperations.fetchOrder,
  selectBranch: branchOperations.selectBranch,
  orderMarkedReadyEvent: orderEventsOperations.orderMarkedReady,
  orderPickUpMarkedReady: orderEventsOperations.orderPickUpMarkedReady,
  orderRiderAssigned: orderEventsOperations.orderRiderAssigned,
  orderMarkedDelivered: orderEventsOperations.orderMarkedDelivered,
  orderMarkedCollected: orderEventsOperations.orderMarkedCollected,
  orderCreated: orderEventsOperations.orderCreated,
  orderUpdated: orderEventsOperations.orderUpdated,
  orderVoided: orderEventsOperations.orderVoided,
  orderConfirmed: orderEventsOperations.orderConfirmed,
  orderRejected: orderEventsOperations.orderRejected,
  setDeliveredRefreshRequired:
    homeDeliveryOperations.setDeliveredRefreshRequired,
  setCollectedRefreshRequired: pickupOperations.setCollectedRefreshRequired,
  fetchScheduledOrders: scheduledOperations.fetchScheduledOrders,
  orderMarkedReprinted: orderEventsOperations.orderMarkedReprinted,
  clearHomeDeliveryNotifications:
    notificationOperations.clearHomeDeliveryUnconfirmedNotification,
  clearPickupNotifications:
    notificationOperations.clearPickupUnconfirmedNotification,
  logisticJobCancelled: logisticPartnersOperations.logisticJobCancelled,
  logisticCancelFailed: logisticPartnersOperations.logisticCancelFailed,
  fetchPrintSettings: printSettingsOperations.fetchPrintSettings,
  fetchLogisticPartners: logisticPartnersOperations.fetchLogisticPartners,
  customerArrived: orderEventsOperations.customerArrived,
  orderMarkedPickedUp: orderEventsOperations.orderMarkedPickedUp,
  fetchRiderProximity: riderOperations.fetchRiderProximity,
  orderRiderUnassigned: orderEventsOperations.orderRiderUnassigned,
  orderTipCollected: orderEventsOperations.orderTipCollected,
  orderCompanyRiderAssigned: orderEventsOperations.orderCompanyRiderAssigned,
  orderPartnerRiderAssigned: orderEventsOperations.orderPartnerRiderAssigned,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
