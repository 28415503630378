import React, { useEffect, useState } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import FilterDropdown from "../filterDropdown";
interface ITableFilterTabProps {
  filterOptions?: any;
  onFilterClick?: any;
  onSearch?: any;
  orderStatus: any;
  orderType: any;
  allowSearch?: boolean;
  riderAppEnabled?: boolean;
  selectedRider: any;
  setSelectedRider: any;
  newRiderSelection: boolean;
}

function TableFilterTab(props: ITableFilterTabProps) {
  const [filterOptions, setFilterOptions] = useState([] as any);
  const [selectedFilter, setSelectedFilter] = useState(0);

  useEffect(() => {
    setFilterOptions(props.filterOptions);
  }, [props.filterOptions]);

  const onSearchChange = (e: any) => {
    props.onSearch(e.target.value);
  };

  const onFilterClick = (option: any) => {
    setSelectedFilter(option.id);
    props.onFilterClick(option.name);
  };

  return (
    <div className="table-filter-tab">
      {!props.riderAppEnabled &&
      !props.newRiderSelection &&
      props.orderStatus == "ready" &&
      props.orderType == "homeDelivery" ? (
        <FilterDropdown
          selectedRider={props.selectedRider}
          setSelectedRider={props.setSelectedRider}
        />
      ) : null}
      {props.orderType == "homeDelivery" ? (
        <>
          <p className="label">
            <FormattedMessage id="global.filter_by" />:
          </p>
          {filterOptions.length
            ? filterOptions.map((option: any, index: any) => {
                return (
                  <div
                    key={index}
                    onClick={() => onFilterClick(option)}
                    className={
                      selectedFilter === option.id
                        ? "filter-item active"
                        : "filter-item"
                    }
                  >
                    {option.name}
                  </div>
                );
              })
            : null}
        </>
      ) : null}
      {props.allowSearch ? (
        <div className="orders-search-wrapper">
          <div className="input-group">
            <div className="input-group-prepend m-auto">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <FormattedMessage id="search">
              {(placeholder: any) => (
                <input
                  onChange={onSearchChange}
                  type="text"
                  className="orders-search"
                  placeholder={placeholder}
                  aria-label="Example text with button addon"
                  aria-describedby="button-addon1"
                />
              )}
            </FormattedMessage>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default TableFilterTab as any;
