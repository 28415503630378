import React from "react";
import type Pusher from "pusher-js";
import { PusherContext } from "../../context/pusherContext";

interface IPusherProviderProps {
  children: React.ReactNode;
  /** Pusher Instance */
  pusher: Pusher | undefined;
}

const PusherProvider = ({ children, pusher }: IPusherProviderProps) => {
  return (
    <PusherContext.Provider value={pusher}>{children}</PusherContext.Provider>
  );
};

export default PusherProvider;
